import React, { useState, useEffect, useMemo, useCallback } from "react";

import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/theme/default-light.css";
import {
  CertificationStatusENUM,
  getCertificationStatusStringValue,
  getCertificationStepStatusStringValue,
  getCertificationTypeStringValue,
} from "../../utils/enum";
import { Link } from "react-router-dom";
import { sortData, sortDataByStatus } from "../../utils/utils";
import { Tooltip } from "@material-tailwind/react";
import DownloadFile from "../certification-process/DownloadFile";
import { useSelector } from "react-redux";

const gridStyle = { minHeight: 650 };
const defaultSortInfoDashboard = {
  name: "dateOfLastAction",
  type: "string",
  dir: 1,
};

const defaultSortInfoOthers = {
  name: "companyName",
  type: "string",
  dir: -1,
};

const defaultSortInfoStep4 = {
  name: "standard",
  type: "string",
  dir: 1,
};

const ReactTable = ({
  certificationList,
  handleClickDetails,
  handleDownloadCertificate,
  handleValidateCNCApplication,
  handleValidateVVB,
  handleModalOpen,
  handleClickDelete,
  handleClickDetail,
  isCOC = false,
  isPartner = false,
  isAdmin = false,
  isAdminUser = false,
  isCarbonRetirement = false,
  setShowRemoveModal,
  setIndex,
  currentStep = null,
  certificationId,
  handleSort,
}) => {
  const defaultSortInfo =
    isPartner || isAdminUser
      ? defaultSortInfoOthers
      : isCarbonRetirement
      ? defaultSortInfoStep4
      : defaultSortInfoDashboard;
  const [setGridRef] = useState(null);
  const [dataSource, setDataSource] = useState(certificationList || []);
  const [sortInfo, setSortInfo] = useState(defaultSortInfo);

  const creditRetirementData = useSelector(
    (state) => state.getCreditRetirements.data
  );

  useEffect(() => {
    return () => {
      setDataSource(null);
    };
  }, []);

  const user = JSON.parse(localStorage.getItem("user"));

  const shouldComponentUpdate = () => true;

  const defaultColumns = [
    {
      name: "",
      header: "",
      type: "",
      minWidth: 60,
      maxWidth: 60,
      defaultFlex: 1,
      textAlign: "center",
      render: ({ data }) => (
        <Tooltip content="View details" className="bg-[#707070] p-3">
          <button
            type="button"
            //data-hs-overlay="#hs-full-screen-modal-below-xl"
            onClick={() => handleClickDetails(data.id)}
          >
            <img
              src="/assets/icons/file.svg"
              alt=""
              width={`22px`}
              height={`22px`}
              className="inline mx-auto"
            />
          </button>
        </Tooltip>
      ),
      shouldComponentUpdate,
    },
    {
      name: "",
      header: "",
      minWidth: 60,
      maxWidth: 60,
      defaultFlex: 1,
      type: "",
      textAlign: "center",
      render: ({ data }) => {
        if (
          (data.certificationStatus === CertificationStatusENUM.PENDING ||
            data.certificationStatus ===
              CertificationStatusENUM.REFUSED_BY_COC) &&
          user?.data.userRole === "Client"
        ) {
          return (
            <Link className="text-center" to={`/dashboard/step/${data.id}`}>
              <img
                src={`/assets/icons/${
                  !data.isSentToCoc && data.certificationStepStatus !== 4
                    ? "eye-pending"
                    : "eye"
                }.svg`}
                alt=""
                className="w-6 h-auto mx-auto inline text-center"
                onMouseOver={(e) =>
                  (e.currentTarget.src = "/assets/icons/eye-green.svg")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.src = `/assets/icons/${
                    !data.isSentToCoc && data.certificationStepStatus !== 4
                      ? "eye-pending"
                      : "eye"
                  }.svg`)
                }
              />
            </Link>
          );
        } else if (
          (data.certificationStatus === CertificationStatusENUM.PENDING ||
            data.certificationStatus ===
              CertificationStatusENUM.REFUSED_BY_COC) &&
          user?.data.userRole === "VAP"
        ) {
          return (
            <>
              {data.isValidateByVvb ? (
                <td className={`flex items-center justify-center`}>
                  <Link
                    className="text-center"
                    to={`/dashboard/step/${data.id}`}
                  >
                    <img
                      src={`/assets/icons/${
                        (data.isSentToCoc &&
                          data.certificationStepStatus === 1) ||
                        (!data.isSentToCoc &&
                          data.certificationStepStatus === 2)
                          ? "eye-pending"
                          : "eye"
                      }.svg`}
                      alt=""
                      className="w-6 h-auto mx-auto inline text-center"
                      onMouseOver={(e) =>
                        (e.currentTarget.src = "/assets/icons/eye-green.svg")
                      }
                      onMouseOut={(e) =>
                        (e.currentTarget.src = `/assets/icons/${
                          (data.isSentToCoc &&
                            data.certificationStepStatus === 1) ||
                          (!data.isSentToCoc &&
                            data.certificationStepStatus === 2)
                            ? "eye-pending"
                            : "eye"
                        }.svg`)
                      }
                    />
                  </Link>
                </td>
              ) : (
                <>
                  <td className={`flex`}>
                    <Tooltip
                      className="bg-[#707070] p-3"
                      content="Accept nomination"
                    >
                      <Link
                        className="text-center mt-3"
                        to="#"
                        onClick={() => handleValidateVVB(data.id)}
                      >
                        <img
                          className="mr-2 w-4 h-4 inline mx-auto"
                          src="/assets/icons/check.svg"
                          alt=""
                        />
                      </Link>
                    </Tooltip>
                    <Tooltip
                      className="bg-[#707070] p-3"
                      content="Reject nomination"
                    >
                      <Link
                        className="text-center mt-3"
                        to="#"
                        onClick={() => handleValidateVVB(data.id, true)}
                      >
                        <img
                          className="ml-2 w-4 h-4 inline"
                          src="/assets/icons/error.svg"
                          alt=""
                        />
                      </Link>
                    </Tooltip>
                  </td>
                </>
              )}
            </>
          );
        } else if (
          data.certificationStatus === CertificationStatusENUM.COMPLETED
        ) {
          return (
            <Tooltip
              className="bg-[#707070] p-3"
              content="Download certificate"
            >
              <button onClick={() => handleDownloadCertificate(data.id)}>
                <img src="/assets/icons/pdf.svg" alt="" />
              </button>
            </Tooltip>
          );
        } else {
          return null; // Ou retournez un autre contenu selon vos besoins
        }
      },
      shouldComponentUpdate,
    },
    {
      name: "certificationStatus",
      header: "Current step + Status",
      //width: 100,
      type: "string",
      defaultFlex: 1,
      render: ({ value, data }) => (
        <span>
          {value !== 3 ? (
            getCertificationStatusStringValue(value)
          ) : (
            <span>
              {getCertificationStatusStringValue(value)} -
              {getCertificationStepStatusStringValue(
                data.certificationStepStatus
              )}
            </span>
          )}
        </span>
      ),
      shouldComponentUpdate,
    },
    {
      name: "certificationCode",
      header: "Transaction #",
      //width: 50,
      type: "string",
      defaultFlex: 1,
      shouldComponentUpdate,
    },
    {
      name: "certificationType",
      header: "Certificate type",
      //width: 50,
      type: "number",
      defaultFlex: 1,
      render: ({ value }) => (
        <span>{getCertificationTypeStringValue(value)}</span>
      ),
      shouldComponentUpdate,
    },
    {
      name: "object",
      header: "Product",
      //width: 50,
      defaultFlex: 1,
      type: "string",
      shouldComponentUpdate,
    },
    {
      name: "estimatedFirstTransDate",
      header: "Estimated (First) Transaction(s) Date",
      //width: 50,
      type: "string",
      defaultFlex: 1,
      render: ({ value }) => {
        if (value !== "01/01/1970") {
          return value;
        }
      },
      shouldComponentUpdate,
    },
    {
      name: "description",
      header: "Description",
      //width: 50,
      type: "string",
      defaultFlex: 1,
      shouldComponentUpdate,
      render: ({ value }) => {
        return (
          <>
            <Tooltip
              className="bg-[#707070] p-3 max-w-[700px] tooltip-content"
              placement="left"
              content={value}
            >
              {value}
            </Tooltip>
          </>
        );
      },
    },

    {
      name: "dateOfLastAction",
      header: "Last action date",
      //width: 50,
      type: "string",
      shouldComponentUpdate,
      defaultFlex: 1,
    },
  ];

  const ColumnsCOC = [
    {
      name: "",
      header: "",
      minWidth: 70,
      maxWidth: 70,
      type: "",
      render: ({ data }) =>
        !isAdmin && (
          <Tooltip content="View details" className="bg-[#707070] p-3">
            <button
              type="button"
              //data-hs-overlay="#hs-full-screen-modal-below-xl"
              onClick={() => handleClickDetails(data.id)}
            >
              <img
                src="/assets/icons/file.svg"
                alt=""
                width={`22px`}
                height={`22px`}
              />
            </button>
          </Tooltip>
        ),
      shouldComponentUpdate,
    },
    {
      name: "",
      header: "",
      minWidth: 70,
      maxWidth: 70,
      type: "",
      render: ({ data }) => {
        if (
          isAdmin &&
          data.certificationStatus !== CertificationStatusENUM.COMPLETED
        ) {
          return (
            <button
              type="button"
              //data-hs-overlay="#hs-full-screen-modal-below-xl"
              onClick={() => handleModalOpen(data.id)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#3D4196"
                className="w-6 h-6"
              >
                <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
              </svg>
            </button>
          );
        } else {
          if (
            data.certificationStatus === CertificationStatusENUM.PENDING ||
            data.certificationStatus === CertificationStatusENUM.REFUSED_BY_COC
          ) {
            return (
              <Link className="text-center" to={`/dashboard/step/${data.id}`}>
                <img
                  src={`/assets/icons/${
                    data.isSentToCoc && data.certificationStepStatus !== 4
                      ? "eye-pending"
                      : "eye"
                  }.svg`}
                  alt=""
                  className="w-6 h-auto mx-auto inline text-center"
                  onMouseOver={(e) =>
                    (e.currentTarget.src = "/assets/icons/eye-green.svg")
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.src = `/assets/icons/${
                      data.isSentToCoc && data.certificationStepStatus !== 4
                        ? "eye-pending"
                        : "eye"
                    }.svg`)
                  }
                />
              </Link>
            );
          } else if (
            data.certificationStatus === CertificationStatusENUM.COMPLETED
          ) {
            return (
              <Tooltip
                className="bg-[#707070] p-3"
                content="Download certificate"
              >
                <button onClick={() => handleDownloadCertificate(data.id)}>
                  <img src="/assets/icons/pdf.svg" alt="" />
                </button>
              </Tooltip>
            );
          } else if (
            data.certificationStatus ===
            CertificationStatusENUM.WAITING_FOR_COC_APPROUVAL
          ) {
            return (
              <Link
                className="text-center"
                to="#"
                onClick={() => handleValidateCNCApplication(data.id)}
              >
                <img
                  className="mr-2 w-4 h-4 mx-auto"
                  src="/assets/icons/check.svg"
                  alt=""
                />
              </Link>
            );
          }
        }
      },
      shouldComponentUpdate,
    },
    {
      name: "certificationStatus",
      header: "Current step + Status",
      //width: 100,
      type: "number",
      defaultFlex: 1,
      render: ({ value, data }) => (
        <span>
          {value !== 3 ? (
            getCertificationStatusStringValue(value)
          ) : (
            <span>
              {getCertificationStatusStringValue(value)} -
              {getCertificationStepStatusStringValue(
                data.certificationStepStatus
              )}
            </span>
          )}
        </span>
      ),
      shouldComponentUpdate,
    },
    {
      name: "certificationCode",
      header: "Application Number",
      //width: 50,
      type: "string",
      defaultFlex: 1,
      shouldComponentUpdate,
    },
    {
      name: "companyName",
      header: "Company Name",
      //width: 50,
      type: "string",
      defaultFlex: 1,
      shouldComponentUpdate,
    },
    {
      name: "firstName",
      header: "User",
      defaultFlex: 1,
      //width: 50,
      type: "string",
      render: ({ value, data }) => (
        <span>
          {data.firstName} {data.lastName}
        </span>
      ),
      shouldComponentUpdate,
    },
    {
      name: "object",
      header: "Object",
      //width: 50,
      type: "string",
      defaultFlex: 1,
      shouldComponentUpdate,
    },
    {
      name: "carbonFootPrint",
      header: "Carbon Footprint",
      //width: 50,
      type: "number",
      defaultFlex: 1,

      shouldComponentUpdate,
    },
    {
      name: "issuanceDate",
      header: "Issuance Date",
      //width: 50,
      type: "string",
      shouldComponentUpdate,
    },
    {
      name: "dateOfLastAction",
      header: "Last Action Date",
      //width: 50,
      type: "string",
      defaultFlex: 1,
      shouldComponentUpdate,
    },
  ];

  const partnerColumns = [
    {
      name: "companyLogo",
      header: "Logo",
      //width: 50,
      defaultFlex: 1,
      type: "string",
      textAlign: "center",
      render: ({ value }) => {
        return (
          <img
            src={
              value
                ? `data:image/png;base64,${value}`
                : process.env.PUBLIC_URL + "/assets/logos/logo1.svg"
            }
            className="text-center mx-auto"
            style={{ maxWidth: "50px" }}
            alt="logo"
          />
        );
      },
      shouldComponentUpdate,
    },
    {
      name: "companyName",
      header: "Company Name",
      //width: 50,
      type: "string",
      defaultFlex: 1,
      shouldComponentUpdate,
    },
    {
      name: "listCompanyExpertise",
      header: "Expertise",
      //width: 50,
      type: "string",
      render: ({ value, data }) => (
        <span>{data.listCompanyExpertise?.join(", ")}</span>
      ),
      shouldComponentUpdate,
    },
    {
      name: "webSite",
      header: "Website",
      //width: 50,
      type: "string",
      defaultFlex: 1,
      render: ({ value }) => {
        return (
          <Link
            to={`${value.includes("https://") ? value : `https://${value}`}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {value}
          </Link>
        );
      },
      shouldComponentUpdate,
    },
    {
      name: "email",
      header: "Email",
      //width: 50,
      type: "string",
      defaultFlex: 1,
      shouldComponentUpdate,
    },
    {
      name: "phoneNumber",
      header: "Phone Number",
      //width: 50,
      type: "string",
      defaultFlex: 1,
      shouldComponentUpdate,
    },
    {
      name: "firstName",
      header: "Contact Name",
      //width: 50,
      type: "string",
      defaultFlex: 1,
      render: ({ value, data }) => (
        <span>
          {data.firstName} {data.lastName}
        </span>
      ),
      shouldComponentUpdate,
    },
  ];

  const adminUserColumns = [
    {
      name: "companyName",
      header: "Company Name",
      //width: 50,
      type: "string",
      defaultFlex: 1,
      shouldComponentUpdate,
    },
    {
      name: "lastName",
      header: "Last Name",
      //width: 50,
      type: "string",
      defaultFlex: 1,
      shouldComponentUpdate,
    },
    {
      name: "firstName",
      header: "First Name",
      //width: 50,
      type: "string",
      defaultFlex: 1,
      shouldComponentUpdate,
    },
    {
      name: "email",
      header: "Email",
      //width: 50,
      type: "string",
      defaultFlex: 1,
      shouldComponentUpdate,
    },
    {
      name: "phoneNumber",
      header: "Phone Number",
      //width: 50,
      type: "string",
      defaultFlex: 1,
      shouldComponentUpdate,
    },
    {
      name: "listCompanyExpertise",
      header: "User Role",
      //width: 50,
      type: "string",
      defaultFlex: 1,
      render: ({ data }) => {
        return data.listCompanyExpertise?.join(", ");
      },
      shouldComponentUpdate,
    },
    {
      render: ({ data }) => {
        return (
          <>
            <button
              type="button"
              className="text-coc-blue hover:text-blue-800"
              onClick={() => handleClickDelete(data.id)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6 text-red-600"
              >
                <path
                  fillRule="evenodd"
                  d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <button
              type="button"
              className="text-coc-blue hover:text-blue-800 ml-4"
              onClick={() => handleClickDetail(data.id)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
              </svg>
            </button>
          </>
        );
      },
    },
  ];

  const carbonRetirementColumns = useMemo(() => {
    return [
      {
        name: "standard",
        header: "Standard",
        //width: 50,
        type: "string",
        defaultFlex: 1,
        shouldComponentUpdate,
      },
      {
        name: "vintageYear",
        header: "Vintage Year",
        //width: 50,
        type: "string",
        defaultFlex: 1,
        render: ({ data, value }) => {
          return new Date(value).getFullYear();
        },
        shouldComponentUpdate,
      },
      {
        name: "quantity",
        header: "Quantity",
        //width: 50,
        type: "number",
        defaultFlex: 1,
        shouldComponentUpdate,
      },
      {
        name: "idRetirement",
        header: "ID",
        //width: 50,
        type: "string",
        defaultFlex: 1,
        shouldComponentUpdate,
      },
      {
        name: "serialNumbers",
        header: "Serial Number",
        //width: 50,
        type: "string",
        defaultFlex: 1,
        shouldComponentUpdate,
      },
      {
        name: "projectDescriptionAndLocation",
        header: "Description",
        //width: 50,
        type: "string",
        defaultFlex: 1,
        shouldComponentUpdate,
      },
      {
        name: "retirementDate",
        header: "Retirement Date",
        //width: 50,
        type: "string",
        defaultFlex: 1,
        render: ({ data, value }) => {
          return new Date(value).toLocaleDateString();
        },
        shouldComponentUpdate,
      },
      {
        name: "proofOfRetirement",
        header: "Proof of retirement",
        //width: 50,
        type: "string",
        defaultFlex: 1,
        render: ({ data, value }) => {
          const fileFromData = data.attachments?.length > 0 && data.attachments;
          const fileFromModel = data.files;
          return (
            <>
              {value !== "" && (
                <Link
                  to={`${
                    value?.includes("https://") ? value : `https://${value}`
                  }`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {value}
                </Link>
              )}

              <br />
              {fileFromModel !== null ? (
                <DownloadFile file={fileFromModel} />
              ) : fileFromData.length > 0 ? (
                <DownloadFile file={fileFromData[0]} />
              ) : (
                ""
              )}
            </>
          );
        },
        shouldComponentUpdate,
      },
      {
        render: ({ data, value }) => {
          return (
            <>
              <Tooltip content="Edit" className="bg-[#707070] p-3">
                <button
                  className={`bg-coc-green text-white px-3 py-2 text-center rounded-lg mx-auto`}
                  onClick={() => setIndex(data)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-5 h-5"
                  >
                    <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                    <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                  </svg>
                </button>
              </Tooltip>

              <Tooltip content="Delete" className="bg-[#707070] p-3">
                <button
                  className={`ml-2 bg-red-500 text-white px-3 py-2 text-center rounded-lg `}
                  onClick={() => setShowRemoveModal(data)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-5 h-5 text-white mx-auto hover:cursor-pointer"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </Tooltip>
            </>
          );
        },
      },
    ];
  }, [setIndex, setShowRemoveModal]);

  const carbonRetirementColumnsDefault = useMemo(() => {
    return [
      {
        name: "standard",
        header: "Standard",
        //width: 50,
        type: "string",
        defaultFlex: 1,
        shouldComponentUpdate,
      },
      {
        name: "vintageYear",
        header: "Vintage Year",
        //width: 50,
        type: "string",
        defaultFlex: 1,
        render: ({ data, value }) => {
          return new Date(value).getFullYear();
        },
        shouldComponentUpdate,
      },
      {
        name: "quantity",
        header: "Quantity",
        //width: 50,
        type: "number",
        defaultFlex: 1,
        shouldComponentUpdate,
      },
      {
        name: "idRetirement",
        header: "ID",
        //width: 50,
        type: "string",
        defaultFlex: 1,
        shouldComponentUpdate,
      },
      {
        name: "serialNumbers",
        header: "Serial Number",
        //width: 50,
        type: "string",
        defaultFlex: 1,
        shouldComponentUpdate,
      },
      {
        name: "projectDescriptionAndLocation",
        header: "Description",
        //width: 50,
        type: "string",
        defaultFlex: 1,
        shouldComponentUpdate,
      },
      {
        name: "retirementDate",
        header: "Retirement Date",
        //width: 50,
        type: "string",
        defaultFlex: 1,
        render: ({ data, value }) => {
          return new Date(value).toLocaleDateString();
        },
        shouldComponentUpdate,
      },
      {
        name: "proofOfRetirement",
        header: "Proof of retirement",
        //width: 50,
        type: "string",
        defaultFlex: 1,
        render: ({ data, value }) => {
          const fileFromData = data.attachments?.length > 0 && data.attachments;
          const fileFromModel = data.files;
          return (
            <>
              {value !== "" && (
                <Link
                  to={`${
                    value?.includes("https://") ? value : `https://${value}`
                  }`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {value}
                </Link>
              )}

              <br />
              {fileFromModel !== null ? (
                <DownloadFile file={fileFromModel} />
              ) : fileFromData.length > 0 ? (
                <DownloadFile file={fileFromData[0]} />
              ) : (
                ""
              )}
            </>
          );
        },
        shouldComponentUpdate,
      },
    ];
  }, []);

  const [columns, setColumns] = useState(
    isCOC
      ? ColumnsCOC
      : isPartner
      ? partnerColumns
      : isAdminUser
      ? adminUserColumns
      : isCarbonRetirement
      ? carbonRetirementColumns
      : defaultColumns
  );

  useEffect(() => {
    if (
      isCarbonRetirement &&
      creditRetirementData?.length > 0 &&
      creditRetirementData[0].certificationId === certificationId
    ) {
      if (creditRetirementData[0].isSentToCoc === true) {
        setColumns(carbonRetirementColumnsDefault);
      } else if (creditRetirementData[0].isSentToCoc === false) {
        setColumns(carbonRetirementColumns);
      }
    }
  }, [
    creditRetirementData,
    certificationId,
    isCarbonRetirement,
    carbonRetirementColumns,
    carbonRetirementColumnsDefault,
  ]);

  const sort = useCallback(
    (arr, sortInfo) => {
      arr = [].concat(arr);
      if (!sortInfo) {
        return sortData(arr, defaultSortInfo.name, defaultSortInfo.dir === -1);
      }
      return arr.sort((o1, o2) => {
        const v1 = o1[sortInfo.name];
        const v2 = o2[sortInfo.name];

        const result =
          sortInfo.type === "number" ? v1 - v2 : v1.localeCompare(v2);

        return result * sortInfo.dir;
      });
    },
    [defaultSortInfo.name, defaultSortInfo.dir]
  );

  const sortHandle = useCallback(
    (value) => {
      let newDataSource = null;
      if (
        value &&
        (value.name === "dateOfLastAction" ||
          value.name === "estimatedFirstTransDate" ||
          value.name === "issuanceDate")
      ) {
        newDataSource = sortData(
          certificationList,
          value.name,
          value.dir === -1
        );
      } else if (value && value.name === "certificationStatus") {
        newDataSource = sortDataByStatus(certificationList, value.dir === -1);
      } else if (value === null) {
        newDataSource = sortData(
          certificationList,
          defaultSortInfo.name,
          defaultSortInfo.dir === -1
        );
      } else {
        newDataSource = sortData(
          certificationList,
          value.name,
          value.dir === -1
        );
      }
      const newSortInfo = value
        ? { type: value.type, name: value.name, dir: value.dir }
        : value;

      setDataSource(newDataSource);
      setSortInfo(newSortInfo);
    },
    [certificationList, sort, defaultSortInfo.name, defaultSortInfo.dir]
  );

  useEffect(() => {
    setDataSource(
      sortData(
        certificationList,
        defaultSortInfo?.name,
        defaultSortInfo?.name === "dateOfLastAction" &&
          defaultSortInfo?.dir === -1
          ? true
          : false
      )
    );
  }, [certificationList, defaultSortInfo?.name, defaultSortInfo?.dir]);

  return (
    <div>
      <ReactDataGrid
        onReady={setGridRef}
        idProperty="id"
        style={isCarbonRetirement ? { minHeight: 300 } : gridStyle}
        dataSource={dataSource}
        showColumnMenuTool={false}
        columns={columns}
        onSortInfoChange={sortHandle}
        //defaultSortInfo={defaultSortInfo}
        sortInfo={sortInfo}
        rowHeight={70}
        headerHeight={70}
        showZebraRows={true}
        showEmptyRows={false}
        showHoverRows={true}
        showCellBorders="vertical"
      />
    </div>
  );
};

export default ReactTable;
