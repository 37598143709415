import { userConstants } from '../constants/user.constants.js';

const initialState = {registered: false};
export default function registration(state = initialState, action) {
    switch (action.type) {
        case userConstants.REGISTER_REQUEST:
            return { 
                registering: true,
                registered: false
            };
        case userConstants.REGISTER_SUCCESS:
            return {
                registered: true
            };
        case userConstants.REGISTER_FAILURE:
            return {
                registered: false
            };
        default:
            return state
    }
}