import { userConstants } from '../constants/user.constants.js';

export default function removeFile(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_REMOVE_FILE_REQUEST:
            return action.payload
        case userConstants.GET_REMOVE_FILE_SUCCESS:
            return {
                ...state,
                removeSuccess: action.payload
            }
            case userConstants.CLEAR_REMOVE_FILE:
                return {
                    ...state,
                    removeSuccess: null
                }
            
        case userConstants.GET_REMOVE_FILE_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state
    }
}