import React, { useEffect, useState } from "react";
import TextInput from "../../../components/forms/TextInput";
import RadioInput from "../../../components/forms/RadioInput";
import TextArea from "../../../components/forms/TextArea";
import LabelInput from "../../../components/forms/Label";
import InfoPopup from "../../../components/create-certification/InfoPopup";
import {
  cradleToGateInfo,
  cradleToGraveInfo,
  preTransactionInfo,
} from "../../../utils/constant";
import { fieldRequiredMessage } from "../../../errors/errors";
import Error from "../../../components/Alert/Error";
import { formatDate } from "../../../utils/utils";

const FirstStep = ({
  formData,
  setFormData,
  errors,
  errorCount,
  register,
  setValue,
  unregister,
}) => {
  const [preTransactionRequired, setPreTransactionRequired] = useState(true);
  const [cradleToGateRequired, setCradleToGateRequired] = useState(true);

  const handleChangeInput = (fieldName, value, fieldsToUnregister) => {
    setFormData({ ...formData, [fieldName]: value });

    // Mettre à jour les champs à désenregistrer
    if (fieldsToUnregister) {
      // Désenregistrer les champs
      for (const field of fieldsToUnregister) {
        unregister(field);
      }
    }
    setValue(fieldName, value);
    if (
      fieldName === "preTransactionCertification" ||
      fieldName === "postTransactionCertification"
    ) {
      setPreTransactionRequired(false);
    }

    if (fieldName === "cradleToGate" || fieldName === "cradleToGrave") {
      setCradleToGateRequired(false);
    }
  };

  useEffect(() => {
    if (
      formData?.preTransactionCertification === true ||
      formData?.preTransactionCertification === false
    ) {
      setPreTransactionRequired(false);
    }

    if (formData?.cradleToGate === true || formData?.cradleToGate === false) {
      setCradleToGateRequired(false);
    }
  }, [formData?.preTransactionCertification, formData?.cradleToGate]);

  useEffect(() => {
    setValue("serviceName", formData?.serviceName || "");
    setValue(
      "estimatedCommodityVolumePerQuantity",
      formData?.estimatedCommodityVolumePerQuantity || ""
    );
    setValue(
      "estimatedFirstTransDate",
      formData?.estimatedFirstTransDate || ""
    );
    setValue("numberOfTranscations", formData?.numberOfTranscations || "");
    setValue("origin", formData?.origin || "");
    setValue("destination", formData?.destination || "");
    // setValue(
    //   "preTransactionCertification",
    //   formData?.preTransactionCertification ? true : false
    // );
    // setValue("cradleToGate", formData?.cradleToGate ? true : false);
    setValue("transactionDescription", formData?.transactionDescription || "");
  }, [formData, setValue]);

  return (
    <div className="w-11/12 commodity">
      <div className="flex mt-3">
        <div className="col">
          {formData?.certificationType === 1 && (
            <>
              <TextInput
                name="serviceName"
                register={{ ...register("serviceName", { required: true }) }}
                label="Commodity"
                defaultValue={formData?.serviceName}
                placeholder="Commodity"
                onChange={(e) =>
                  handleChangeInput("serviceName", e.target.value)
                }
              />
              {errors.serviceName && errors.serviceName.type === "required" && (
                <Error
                  key={errorCount}
                  text={fieldRequiredMessage("Commodity")}
                />
              )}
            </>
          )}
          {formData?.certificationType === 2 && (
            <>
              <TextInput
                name="serviceName"
                register={{ ...register("serviceName", { required: true }) }}
                defaultValue={formData?.serviceName}
                label="Product"
                placeholder="Product"
                onChange={(e) =>
                  handleChangeInput("serviceName", e.target.value)
                }
              />
              {errors.serviceName && errors.serviceName.type === "required" && (
                <Error
                  key={errorCount}
                  text={fieldRequiredMessage("Product")}
                />
              )}
            </>
          )}
        </div>
      </div>
      <div className="flex mt-4">
        <div className="col pr-0">
          {formData?.certificationType === 1 && (
            <>
              <TextInput
                isNumberType={true}
                name="estimatedCommodityVolumePerQuantity"
                defaultValue={formData?.estimatedCommodityVolumePerQuantity}
                titre="Estimated commodity Volume / Quantity per transaction"
                label="Estimated commodity Volume / Quantity"
                placeholder="Volume / Quantity"
                onChange={(e) =>
                  handleChangeInput(
                    "estimatedCommodityVolumePerQuantity",
                    e.target.value
                  )
                }
                register={{
                  ...register("estimatedCommodityVolumePerQuantity", {
                    required: true,
                  }),
                }}
              />
              {errors.estimatedCommodityVolumePerQuantity &&
                errors.estimatedCommodityVolumePerQuantity.type ===
                  "required" && (
                  <Error
                    key={errorCount}
                    text={fieldRequiredMessage("Estimated Volume")}
                  />
                )}
            </>
          )}

          {formData?.certificationType === 2 && (
            <>
              <TextInput
                isNumberType={true}
                name="estimatedCommodityVolumePerQuantity"
                defaultValue={formData?.estimatedCommodityVolumePerQuantity}
                titre="Estimated product Volume / Quantity per transaction"
                label="Estimated product Volume / Quantity"
                placeholder="Volume / Quantity"
                register={{
                  ...register("estimatedCommodityVolumePerQuantity", {
                    required: true,
                  }),
                }}
                onChange={(e) =>
                  handleChangeInput(
                    "estimatedCommodityVolumePerQuantity",
                    e.target.value
                  )
                }
              />
              {errors.estimatedCommodityVolumePerQuantity &&
                errors.estimatedCommodityVolumePerQuantity.type ===
                  "required" && (
                  <Error
                    key={errorCount}
                    text={fieldRequiredMessage("Estimated Volume")}
                  />
                )}
            </>
          )}
        </div>
        <div className="col pr-0">
          <TextInput
            name="unit"
            defaultValue={formData?.unit}
            titre="Unit"
            label="Unit"
            placeholder="Unit"
            onChange={(e) => handleChangeInput("unit", e.target.value)}
            register={{
              ...register("unit", {
                required: true,
              }),
            }}
          />
          {errors.unit && errors.unit.type === "required" && (
            <Error key={errorCount} text={fieldRequiredMessage("Unit")} />
          )}
        </div>
      </div>
      <div className="flex mt-4">
        <div className="col date">
          <TextInput
            type="date"
            register={{
              ...register("estimatedFirstTransDate", { required: false }),
            }}
            onChange={(e) =>
              handleChangeInput("estimatedFirstTransDate", e.target.value)
            }
            name="estimatedFirstTransDate"
            defaultValue={
              formData?.estimatedFirstTransDate &&
              formatDate(formData?.estimatedFirstTransDate)
            }
            label="Estimated (First) transaction(s) date"
            placeholder="mm/dd/yy"
          />
          {/* {errors.estimatedFirstTransDate &&
            errors.estimatedFirstTransDate.type === "required" && (
              <Error
                key={errorCount}
                text={fieldRequiredMessage("Estimated Transaction Date")}
              />
            )} */}
        </div>

        <div className="col pr-0">
          <TextInput
            isNumberType={true}
            register={{
              ...register("numberOfTranscations", { required: true }),
            }}
            onChange={(e) =>
              handleChangeInput("numberOfTranscations", e.target.value)
            }
            name="numberOfTranscations"
            defaultValue={formData?.numberOfTranscations}
            label="Number of transaction(s)"
            placeholder="Number"
          />
          {errors.numberOfTranscations &&
            errors.numberOfTranscations.type === "required" && (
              <Error
                key={errorCount}
                text={fieldRequiredMessage("Number Of Transaction")}
              />
            )}
        </div>
      </div>
      <div className="flex mt-4">
        <div className="col">
          {formData?.certificationType === 1 && (
            <>
              <TextInput
                register={{ ...register("origin", { required: true }) }}
                onChange={(e) => handleChangeInput("origin", e.target.value)}
                name="origin"
                defaultValue={formData?.origin}
                label="Origin (Cradle)"
                placeholder="Location"
              />
              {errors.origin && errors.origin.type === "required" && (
                <Error key={errorCount} text={fieldRequiredMessage("Origin")} />
              )}
            </>
          )}
          {formData?.certificationType === 2 && (
            <>
              <TextInput
                register={{ ...register("origin", { required: true }) }}
                onChange={(e) => handleChangeInput("origin", e.target.value)}
                name="origin"
                defaultValue={formData?.origin}
                label="Origin"
                placeholder="Location"
              />
              {errors.origin && errors.origin.type === "required" && (
                <Error key={errorCount} text={fieldRequiredMessage("Origin")} />
              )}
            </>
          )}
        </div>

        <div className="col pr-0">
          {formData?.certificationType === 1 && (
            <>
              <TextInput
                register={{ ...register("destination", { required: true }) }}
                onChange={(e) =>
                  handleChangeInput("destination", e.target.value)
                }
                name="destination"
                defaultValue={formData?.destination}
                label="Destination (Gate or Grave)"
                placeholder="Destination"
              />
              {errors.destination && errors.destination.type === "required" && (
                <Error
                  key={errorCount}
                  text={fieldRequiredMessage("Destination")}
                />
              )}
            </>
          )}
          {formData?.certificationType === 2 && (
            <>
              <TextInput
                register={{ ...register("destination", { required: true }) }}
                onChange={(e) =>
                  handleChangeInput("destination", e.target.value)
                }
                name="destination"
                defaultValue={formData?.destination}
                label="Destination"
                placeholder="Destination"
              />
              {errors.destination && errors.destination.type === "required" && (
                <Error
                  key={errorCount}
                  text={fieldRequiredMessage("Destination")}
                />
              )}
            </>
          )}
        </div>
      </div>
      <div className="flex justify-between mt-3">
        <div className="mt-6 col pl-0">
          <div className="flex mb-3 ml-3">
            <LabelInput label="Certification" />
          </div>
          <div className="flex mb-3 ml-3">
            <RadioInput
              register={{
                ...register("preTransactionCertification", {
                  required: preTransactionRequired,
                }),
              }}
              onChange={(e) =>
                handleChangeInput("preTransactionCertification", true)
              }
              checked={formData.preTransactionCertification === true}
              value={true}
              name="preTransactionCertification"
              label="Pre-Transaction(s) Certification"
              id="pre"
              htmlFor="pre"
              tabIndex={0}
            />
            <InfoPopup text={preTransactionInfo} />
          </div>

          <div className="flex mb-3 ml-3">
            <RadioInput
              register={{
                ...register("postTransactionCertification", {
                  required: preTransactionRequired,
                }),
              }}
              onChange={(e) =>
                handleChangeInput("preTransactionCertification", false, [])
              }
              checked={formData.preTransactionCertification === false}
              value={false}
              name="postTransactionCertification"
              label="Post-Transaction(s) Certification"
              id="post"
              htmlFor="post"
            />
          </div>
          {errors.preTransactionCertification &&
            errors.preTransactionCertification.type === "required" &&
            errors.postTransactionCertification &&
            errors.postTransactionCertification.type === "required" && (
              <Error
                key={errorCount}
                text={fieldRequiredMessage("Transaction Certification")}
              />
            )}
        </div>

        {formData?.certificationType === 1 && (
          <div className="mt-4 px-0 col">
            <div className="flex mb-3 ml-3">
              <LabelInput label="Boundaries" />
            </div>
            <div className="flex mb-3 ml-3">
              <RadioInput
                register={{
                  ...register("cradleToGate", {
                    required: cradleToGateRequired,
                  }),
                }}
                onChange={(e) => handleChangeInput("cradleToGate", true)}
                checked={formData?.cradleToGate === true}
                value={true}
                name="cradleToGate"
                id="cradleToGate"
                label="Cradle-To-Gate"
                htmlFor="cradleToGate"
              />
              <InfoPopup
                text={cradleToGateInfo}
                className={`relative inline-block my-1 bundaries`}
              />
            </div>

            <div className="flex mb-3 ml-3">
              <RadioInput
                register={{
                  ...register("cradleToGrave", {
                    required: cradleToGateRequired,
                  }),
                }}
                onChange={(e) => handleChangeInput("cradleToGate", false)}
                checked={formData?.cradleToGate === false}
                value={false}
                name="cradleToGrave"
                label="Cradle-To-Grave"
                htmlFor="cradle1"
                id="cradle1"
              />
              <InfoPopup
                text={cradleToGraveInfo}
                className={`relative inline-block my-1 bundaries`}
              />
            </div>
            {errors.cradleToGate &&
              errors.cradleToGate.type === "required" &&
              errors.cradleToGrave &&
              errors.cradleToGrave.type === "required" && (
                <Error
                  key={errorCount}
                  text={fieldRequiredMessage("Cradle Info")}
                />
              )}
          </div>
        )}
      </div>

      <div className="col w-1/2 mt-4">
        <TextArea
          register={{
            ...register("transactionDescription", { required: true }),
          }}
          onChange={(e) =>
            handleChangeInput("transactionDescription", e.target.value)
          }
          rows={5}
          name="transactionDescription"
          defaultValue={formData?.transactionDescription}
          label="Transaction description"
          placeholder="Transaction(s) processes description: (any information  describing the transaction processes: logistic, freight, shipping, storages, refining, etc.)"
        />
        {errors.transactionDescription &&
          errors.transactionDescription.type === "required" && (
            <Error
              key={errorCount}
              text={fieldRequiredMessage("Transaction Description")}
            />
          )}
      </div>
    </div>
  );
};

export default FirstStep;
