import { combineReducers } from "redux";

import authentication from "./authentication.reducer.js";
import certification from "./certification.reducer.js";
import registration from "./registration.reducer.js";
import users from "./users.reducer.js";
import alert from "./alert.reducer.js";
import userCocReducer from "./users.coc.reducers.js";
import errorReducer from "./error.reducer.js";
import certificationStep from "./certification-step.reducer.js";
import downloadCertification from "./download.reducer.js";
import uploadCertification from "./upload.reducer.js";
import removeFile from "./remove-file.reducer.js";
import addCreditRetirements from "./add-credit-retirement.reducer.js";
import getCreditRetirements from "./get-credit-retirement.reducer.js";
import validationCNCApplication from "./validate-cnc-app.reducer.js";
import validateStep from "./validate-step.reducer.js";
import updateCreditRetirements from "./update-credit-retirement.reducer.js";
import validationCCRStep from "./validate-ccr-step.reducer.js";
import rejectStep from "./reject-step.reducer.js";
import sendCocFiles from "./send-coc-files.reducer.js";
import createCommodity from "./commodity.reducer.js";
import rejectRetirementStep from "./reject-retirement.reducer.js";
import vvb from "./vvb.reducer.js";
import partnerReducer from "./partner.reducer";
import admin from "./admin.reducer.js";
import certificationFormDataReducer from "./certificationFormData.reducer.js";
import creditRetirementFormDataReducer from "./creditRetirementFormData.reducer.js";

const rootReducer = combineReducers({
  authentication,
  registration,
  users,
  alert,
  userReducer: userCocReducer,
  errorReducer,
  certification,
  certificationStep,
  downloadCertification,
  uploadCertification,
  removeFile,
  addCreditRetirements,
  getCreditRetirements,
  validationCNCApplication,
  validateStep,
  updateCreditRetirements,
  validationCCRStep,
  rejectStep,
  sendCocFiles,
  createCommodity,
  rejectRetirementStep,
  vvb,
  partner: partnerReducer,
  admin,
  certificationFormDataReducer,
  creditRetirementFormDataReducer,
});

export default rootReducer;
