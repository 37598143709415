import Modal from "react-modal";

const AlertModal = ({ showConfirmationModal, setShowModal, textContent }) => {
  return (
    <Modal
      isOpen={showConfirmationModal}
      className="popup-modal admin-users"
      overlayClassName="modall-overlay"
      shouldCloseOnOverlayClick={false}
    >
      <div className="p-4 bg-red-100 border border-red-400 rounded-lg">
        <div className="text-red-800 font-semibold mb-2">Error Alert</div>
        {textContent}
      </div>
      <div className="flex justify-end items-center mt-4">
        <button
          type="button"
          className="py-2 px-4 bg-red-500 hover:bg-red-600 text-white rounded-md font-medium shadow-sm text-sm"
          onClick={() => setShowModal(false)}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export default AlertModal;
