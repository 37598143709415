export default function DefaultProfilePicture() {
  return (
    <>
      <img
        src="/CO_commodity_black.png"
        alt="company logo"
        className="w-[120px] auto object-cover rounded-full"
      />
    </>
  );
}
