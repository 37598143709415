import React, { useEffect, useState } from "react";
import TextInput from "../../../components/forms/TextInput";
import TextArea from "../../../components/forms/TextArea";
import Error from "../../../components/Alert/Error";
import { fieldRequiredMessage } from "../../../errors/errors";
import TimePeriodInput from "../../../components/forms/TimePeriodInput";
import InfoPopup from "../../../components/create-certification/InfoPopup";
import LabelInput from "../../../components/forms/Label";
import RadioInput from "../../../components/forms/RadioInput";
import { preTransactionInfoOtherService } from "../../../utils/constant";

const TimeFlightAdminService = ({
  formData,
  setFormData,
  errors,
  errorCount,
  register,
  setValue,
  initDates,
}) => {
  useEffect(() => {
    setValue("airCraftType", formData?.airCraftType || "");
    setValue("contractTimePeriod", formData?.contractTimePeriod || "");
    setValue("transactionDescription", formData?.transactionDescription || "");
  }, [formData, setValue]);

  useEffect(() => {
    return () => {
      initDates("Aviation Time Chart");
    };
  }, [initDates]);

  const [preTransactionRequired, setPreTransactionRequired] = useState(true);

  const handleChangeInput = (filedName, value) => {
    setFormData({ ...formData, [filedName]: value });
    setValue(filedName, value);
    if (
      filedName === "preTransactionCertification" ||
      filedName === "postTransactionCertification"
    ) {
      setPreTransactionRequired(false);
    }
  };

  useEffect(() => {
    if (
      formData?.preTransactionCertification === true ||
      formData?.preTransactionCertification === false
    ) {
      setPreTransactionRequired(false);
    }
  }, [formData?.preTransactionCertification]);

  return (
    <>
      <div className="flex mt-10">
        <div className="col pr-0 date relative -top-[9px]">
          <TimePeriodInput
            register={{
              ...register("contractTimePeriod", { required: true }),
            }}
            defaultValue={formData?.contractTimePeriod}
            label="Certification"
            placeholder="Contract Time Period"
            fieldName="contractTimePeriod"
            setTimePeriodValue={handleChangeInput}
          />
          {errors.contractTimePeriod &&
            errors.contractTimePeriod.type === "required" && (
              <Error text={fieldRequiredMessage("Certification Period")} />
            )}
        </div>
        <div className="col">
          <TextInput
            onChange={(e) => handleChangeInput("airCraftType", e.target.value)}
            register={{
              ...register("airCraftType", { required: true }),
            }}
            name="airCraftType"
            defaultValue={formData?.airCraftType}
            label="Aircraft type"
            placeholder="Aircraft type"
          />
          {errors.airCraftType && errors.airCraftType.type === "required" && (
            <Error text={fieldRequiredMessage("Aircraft Type")} />
          )}
        </div>
      </div>
      <div className="mt-4">
        <div className="flex mb-3">
          <LabelInput label="Certification" />
        </div>
        <div className="flex mb-3">
          <RadioInput
            onChange={(e) =>
              handleChangeInput("preTransactionCertification", true)
            }
            register={{
              ...register("preTransactionCertification", {
                required: preTransactionRequired,
              }),
            }}
            checked={formData?.preTransactionCertification === true}
            value={true}
            name="preTransactionCertification"
            label="Pre-Transaction(s) Certification"
            id="pre"
            htmlFor="pre"
          />
          <InfoPopup text={preTransactionInfoOtherService} />
        </div>

        <div className="flex mb-3">
          <RadioInput
            onChange={(e) =>
              handleChangeInput("preTransactionCertification", false, [])
            }
            register={{
              ...register("postTransactionCertification", {
                required: preTransactionRequired,
              }),
            }}
            checked={formData?.preTransactionCertification === false}
            value={false}
            name="postTransactionCertification"
            label="Post-Transaction(s) Certification"
            id="post"
            htmlFor="post"
          />
        </div>
        {errors.preTransactionCertification &&
          errors.preTransactionCertification.type === "required" &&
          errors.postTransactionCertification &&
          errors.postTransactionCertification.type === "required" && (
            <Error
              key={errorCount}
              text={fieldRequiredMessage("Transaction Certification")}
            />
          )}
      </div>
      <div className="col max-w-[390px] mt-4 pl-0">
        <TextArea
          onChange={(e) =>
            handleChangeInput("transactionDescription", e.target.value)
          }
          register={{
            ...register("transactionDescription", { required: true }),
          }}
          name="transactionDescription"
          defaultValue={formData?.transactionDescription}
          label="Additional information"
          placeholder="Additional information:"
        />
        {errors.transactionDescription &&
          errors.transactionDescription.type === "required" && (
            <Error text={fieldRequiredMessage("Additional Information")} />
          )}
      </div>
    </>
  );
};

export default TimeFlightAdminService;
