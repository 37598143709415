import { vvbActions } from "../../actions/vvb.action";
import RadioInput from "../forms/RadioInput";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";

export default function VVBModals({ certificationId, userList }) {
  const dispatch = useDispatch();
  const [userIdChoosen, setUserIdChoosen] = useState(null);
  const [userNominated, setUserNominated] = useState(false);
  const certificationAllData = useSelector(
    (state) => state.certificationStep.allData
  );

  useEffect(() => {
    if (certificationAllData && certificationAllData.id === certificationId) {
      if (certificationAllData?.vvbUserId) {
        setUserIdChoosen(certificationAllData?.vvbUserId);
        setUserNominated(true);
      }
    }
  }, [certificationId, certificationAllData]);

  const handleChoose = () => {
    dispatch(vvbActions.assignVvbToCertificate(certificationId, userIdChoosen));
    document.getElementById("btn-close").click();
  };

  return (
    <div
      id="hs-vvb-modal"
      className="hs-overlay focus-visible:outline-none hidden w-full h-full fixed top-36 left-1/4 bottom-1/4 z-[60] overflow-x-hidden overflow-y-auto"
    >
      <div className="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all w-6/12">
        <div className="relative flex flex-col bg-white border shadow-sm rounded-xl overflow-auto dark:bg-gray-800 dark:border-gray-700">
          <div className="absolute top-2 right-2">
            <button
              type="button"
              id="btn-close"
              className="inline-flex flex-shrink-0 rounded-full border-light-grey justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800"
              data-hs-overlay="#hs-vvb-modal"
            >
              <span className="sr-only">Close</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </button>
          </div>

          <div className="p-4 sm:p-10 overflow-y-auto vvb">
            <h1 className="text-center font-semibold text-md my-4">
              Select your VVB partner from list
            </h1>
            <div className="px-9 mt-9 grid grid-cols-2">
              {userList?.map((user) => (
                <div>
                  <RadioInput
                    name={user.companyName}
                    id={user.companyName}
                    htmlFor={user.companyName}
                    label={user.companyName}
                    onChange={() => setUserIdChoosen(user.id)}
                    checked={userIdChoosen === user.id}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="flex justify-end items-center gap-x-2 py-3 px-4 bg-gray-50 border-t dark:bg-gray-800 dark:border-gray-700">
            <button
              className="py-2.5 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-medium hover:no-underline bg-coc-green text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-coc-green focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
              onClick={handleChoose}
              disabled={userNominated}
            >
              Validate
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
