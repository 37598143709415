import {partnerConstants} from "../constants/partner.constants";

const initialState = {
    data: []
}
export default function partnerReducer(state = initialState, action) {
    switch (action.type) {
        case partnerConstants.GET_PARTNER_LIST_SUCCESS:
            return {
                ...state,
                data: action.payload
            }

        case partnerConstants.GET_PARTNER_LIST_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state
    }
}