import jwtDecode from "jwt-decode";
import { userService } from "../services/user.service";
export default function authHeader() {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem("user"));

  if (user && user.userToken) {
    const tokenDecoded = jwtDecode(user.userToken);
    if (tokenDecoded.exp) {
      const expirationDate = new Date(tokenDecoded.exp * 1000);
      if (new Date() < expirationDate) {
        return { Authorization: "Bearer " + user.userToken };
      } else {
        userService.logout();
        window.location.href = `/login`;
      }
    }
  } else {
    localStorage.removeItem("user");
    window.location.href = `/login`;
  }
}
