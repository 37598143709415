import { userConstants } from "../constants/user.constants.js";
import { certificationService } from "../services/certification.service.js";
import { alertActions } from "./alert.actions.js";

export const certificationActions = {
  getCertificationList,
  getCertificationByUserId,
  getCertificationStepByCID,
  downloadCertification,
  validateStep,
  uploadCertification,
  removeFile,
  clearFileUploadList,
  createCarbonCreditRetirement,
  getCarbonCreditRetirementById,
  validateCNCApplication,
  updateCarbonCreditRetirement,
  validateCCRStep,
  rejectStep,
  uploadFileCertificationList,
  sendToCOCFiles,
  clearRejectActions,
  clearSendStepToCOCActions,
  validateLastStep,
  rejectRetirementStep,
  clearSendCCRUpdated,
};

function getCertificationByUserId(userId) {
  return async (dispatch) => {
    const response = await certificationService.getCertificationByUserID(
      userId
    );
    if (response.data) {
      dispatch(success(response.data));
    } else {
      dispatch(failure(response));
      dispatch(alertActions.error(response));
    }
  };
  function success(certfications) {
    return {
      type: userConstants.GET_CERTIFICATION_LIST_SUCCESS,
      payload: certfications,
    };
  }
  function failure(error) {
    return {
      type: userConstants.GET_CERTIFICATION_LIST_FAILURE,
      payload: error,
    };
  }
}

function getCertificationList() {
  return async (dispatch) => {
    const response = await certificationService.getCertificationList();
    if (response) {
      dispatch(success(response));
    } else {
      dispatch(failure(response));
      dispatch(alertActions.error(response));
    }
  };
  function success(certfications) {
    return {
      type: userConstants.GET_CERTIFICATION_LIST_SUCCESS,
      payload: certfications,
    };
  }
  function failure(error) {
    return {
      type: userConstants.GET_CERTIFICATION_LIST_FAILURE,
      payload: error,
    };
  }
}

function getCertificationStepByCID(certificationId) {
  return async (dispatch) => {
    const response = await certificationService.getAllCertificationStepByCID(
      certificationId
    );
    if (response.id) {
      dispatch(getAllCertificationInfo(response));
      if (response.certificationSteps) {
        dispatch(success(response.certificationSteps));
        if (response.creditRetirement) {
          dispatch(getCCR(response.creditRetirement));
        }
      }
    } else {
      dispatch(failure(response));
      dispatch(alertActions.error(response));
    }
  };
  function getAllCertificationInfo(data) {
    return {
      type: userConstants.GET_CERTIFICATION_INFO_SUCCESS,
      payload: data,
    };
  }
  function getCCR(ccr) {
    return { type: userConstants.GET_CCR_SUCCESS, payload: ccr };
  }
  function success(certfications) {
    return {
      type: userConstants.GET_CERTIFICATION_STEPS_LIST_SUCCESS,
      payload: certfications,
    };
  }
  function failure(error) {
    return {
      type: userConstants.GET_CERTIFICATION_STEPS_LIST_FAILURE,
      payload: error,
    };
  }
}

function downloadCertification(fileId) {
  return async (dispatch) => {
    const response = await certificationService.downloadCertification(fileId);
    if (response) {
      dispatch(success(response));
    } else {
      dispatch(failure(response));
      dispatch(alertActions.error(response));
    }
  };
  function success(certfications) {
    return {
      type: userConstants.GET_DOWNLOAD_CERTIFICATION_SUCCESS,
      payload: certfications,
    };
  }
  function failure(error) {
    return {
      type: userConstants.GET_DOWNLOAD_CERTIFICATION_FAILURE,
      payload: error,
    };
  }
}

function validateStep({
  stepId,
  certificationId,
  step,
  carbonCo2Value = null,
  IdCertification = null,
}) {
  return async (dispatch) => {
    const response = await certificationService.validateStep({
      stepId,
      certificationId,
      step,
      carbonCo2Value,
      IdCertification,
    });
    if (response) {
      dispatch(success(response));
    } else {
      dispatch(failure(response));
      dispatch(alertActions.error(response));
    }
  };
  function success(response) {
    return { type: userConstants.GET_VALIDATE_STEP_SUCCESS, payload: response };
  }
  function failure(error) {
    return { type: userConstants.GET_VALIDATE_STEP_FAILURE, payload: error };
  }
}

function validateLastStep({ stepId, certificationId, step, totalCredit }) {
  return async (dispatch) => {
    const response = await certificationService.validateLastStep({
      stepId,
      certificationId,
      step,
      totalCredit,
    });
    if (response) {
      dispatch(success(response));
    } else {
      dispatch(failure(response));
      dispatch(alertActions.error(response));
    }
  };
  function success(response) {
    return { type: userConstants.GET_VALIDATE_STEP_SUCCESS, payload: response };
  }
  function failure(error) {
    return { type: userConstants.GET_VALIDATE_STEP_FAILURE, payload: error };
  }
}

function rejectStep({ stepId, certificationId, step }) {
  return async (dispatch) => {
    const response = await certificationService.rejectStep({
      stepId,
      certificationId,
      step,
    });
    if (response.data) {
      dispatch(success(response.data));
    } else {
      dispatch(failure(response.message));
      dispatch(alertActions.error(response.message));
    }
  };
  function success(response) {
    return { type: userConstants.GET_REJECT_STEP_SUCCESS, payload: response };
  }
  function failure(error) {
    return { type: userConstants.GET_REJECT_STEP_FAILURE, payload: error };
  }
}

function rejectRetirementStep({ stepIds, certificationId }) {
  return async (dispatch) => {
    const response = await certificationService.rejectRetirementStep({
      stepIds,
      certificationId,
    });
    if (response.data) {
      dispatch(success(response.data));
    } else {
      dispatch(failure(response.message));
      dispatch(alertActions.error(response.message));
    }
  };
  function success(response) {
    return {
      type: userConstants.GET_REJECT_RETIREMENT_SUCCESS,
      payload: response,
    };
  }
  function failure(error) {
    return {
      type: userConstants.GET_REJECT_RETIREMENT_FAILURE,
      payload: error,
    };
  }
}

function validateCCRStep({ stepIds, certificationId }) {
  return async (dispatch) => {
    const response = await certificationService.validateCCRStep({
      stepIds,
      certificationId,
    });
    if (response.status) {
      dispatch(success(response));
    } else {
      dispatch(failure(response.message));
      dispatch(alertActions.error(response.message));
    }
  };
  function success(response) {
    return {
      type: userConstants.GET_VALIDATE_CCR_STEP_SUCCESS,
      payload: response,
    };
  }
  function failure(error) {
    return {
      type: userConstants.GET_VALIDATE_CCR_STEP_FAILURE,
      payload: error,
    };
  }
}

function uploadCertification(uploadData) {
  return async (dispatch) => {
    const response = await certificationService.uploadCertification(uploadData);
    if (response.status === 413) {
      console.log(`413`);
      dispatch(failure("Payload Too Large"));
      dispatch(alertActions.error("Payload Too Large"));
    } else {
      if ( response.length > 0) {
        dispatch(success(response));
      } else {
        if (response) {
          dispatch(failure(response));
          dispatch(alertActions.error(response));
        } else {
          dispatch(failure("error"));
          dispatch(alertActions.error("error occured during upload"));
        }
      }
    }
  };
  function success(response) {
    return { type: userConstants.GET_UPLOAD_CERT_SUCCESS, payload: response };
  }
  function failure(error) {
    return { type: userConstants.GET_UPLOAD_CERT_FAILURE, payload: error };
  }
}

function sendToCOCFiles({
  stepId,
  certificationId,
  step,
  carbonCo2Value = null,
}) {
  return async (dispatch) => {
    const response = carbonCo2Value
      ? await certificationService.sendStepToCOC({
          stepId,
          certificationId,
          step,
          carbonCo2Value,
        })
      : await certificationService.sendStepToCOC({
          stepId,
          certificationId,
          step,
        });
    if (response.data) {
      dispatch(success(response));
    } else {
      dispatch(failure(response.message));
      dispatch(alertActions.error(response.message));
    }
  };
  function success(response) {
    return {
      type: userConstants.POST_SEND_COC_FILES_SUCCESS,
      payload: response,
    };
  }
  function failure(error) {
    return { type: userConstants.POST_SEND_COC_FILES_FAILURE, payload: error };
  }
}

function uploadFileCertificationList(fileList) {
  const filesUploaded = async (dispatch) => {
    fileList.forEach(async (fileData) => {
      const response = await certificationService.uploadCertification(fileData);
      if (response) {
        dispatch(success(response));
      } else {
        dispatch(failure(response));
        dispatch(alertActions.error(response));
      }
    });
  };
  function success(response) {
    return { type: userConstants.GET_UPLOAD_CERT_SUCCESS, payload: response };
  }
  function failure(error) {
    return { type: userConstants.GET_UPLOAD_CERT_FAILURE, payload: error };
  }

  return filesUploaded;
}

function removeFile(attachmentId) {
  return async (dispatch) => {
    const response = await certificationService.removeFile(attachmentId);
    if (response) {
      dispatch(success({ removeSuccess: true }));
    } else {
      dispatch(failure({ error: "Error" }));
      dispatch(alertActions.error("An error occured while removing file"));
    }
  };
  function success(response) {
    return { type: userConstants.GET_REMOVE_FILE_SUCCESS, payload: response };
  }
  function failure(error) {
    return { type: userConstants.GET_REMOVE_FILE_FAILURE, payload: error };
  }
}

function clearFileUploadList() {
  return { type: userConstants.GET_UPLOAD_CERT_CLEAR };
}

function createCarbonCreditRetirement(data) {
  return async (dispatch) => {
    const response = await certificationService.addCarbonCreditRetirement(data);
    if (response.length > 0) {
      dispatch(success(response));
    } else {
      dispatch(failure(response));
      dispatch(alertActions.error(response));
    }
  };
  function success(response) {
    return { type: userConstants.ADD_CCR_SUCCESS, payload: response };
  }
  function failure(error) {
    return { type: userConstants.ADD_CCR_FAILURE, payload: error };
  }
}

function getCarbonCreditRetirementById(ccrId) {
  return async (dispatch) => {
    const response = await certificationService.getCarbonCreditRetirementById(
      ccrId
    );
    if (response.data) {
      dispatch(success(response));
    } else {
      dispatch(failure(response));
      dispatch(alertActions.error(response));
    }
  };
  function success(response) {
    return { type: userConstants.GET_CCR_SUCCESS, payload: response };
  }
  function failure(error) {
    return { type: userConstants.GET_CCR_FAILURE, payload: error };
  }
}

function validateCNCApplication(certificationId) {
  return async (dispatch) => {
    dispatch(request());
    const response = await certificationService.validateCNCApplication(
      certificationId
    );
    if (response) {
      dispatch(success(response));
    } else {
      dispatch(failure(response));
      dispatch(alertActions.error(response));
    }
  };
  function request() {
    return { type: userConstants.POST_VALIDATE_CNC_REQUEST };
  }
  function success(response) {
    return { type: userConstants.POST_VALIDATE_CNC_SUCCESS, payload: response };
  }
  function failure(error) {
    return { type: userConstants.POST_VALIDATE_CNC_FAILURE, payload: error };
  }
}

function updateCarbonCreditRetirement(data, id) {
  return async (dispatch) => {
    const response = await certificationService.updateCarbonCreditRetirement(
      data,
      id
    );
    if (response.data) {
      dispatch(success(response.data));
    } else {
      dispatch(failure(response.message));
      dispatch(alertActions.error(response.message));
    }
  };
  function success(response) {
    return { type: userConstants.UPDATE_CCR_SUCCESS, payload: response };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_CCR_FAILURE, payload: error };
  }
}

function clearRejectActions() {
  return { type: userConstants.REJECT_PROCESS_CLEAR };
}

function clearSendStepToCOCActions() {
  return { type: userConstants.SEND_STEP_TO_COC_PROCESS_CLEAR };
}

function clearSendCCRUpdated() {
  return { type: userConstants.DELETE_CCR_UPDATED };
}
