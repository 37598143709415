import { userConstants } from "../constants/user.constants.js";
import { userService } from "../services/user.service.js";
import { alertActions } from "./alert.actions.js";
export const userActions = {
  login,
  logout,
  register,
  sendResetLink,
  getAll,
  delete: _delete,
  getById,
  invite,
};

function login(username, password) {
  return (dispatch) => {
    dispatch(request({ username }));

    userService.login(username, password).then(
      (user) => {
        dispatch(success(user));
        sessionStorage.setItem("token", user.userToken);
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  userService.logout();
  localStorage.clear();
  sessionStorage.removeItem("token");
  return { type: userConstants.LOGOUT };
}

function register(user) {
  return (dispatch) => {
    dispatch(request());

    userService.register(user).then(
      (user) => {
        dispatch(success(user));
        dispatch(
          alertActions.success(
            "User created successfully, your account must be validated before connexion, please check your email."
          )
        );
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.REGISTER_REQUEST };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function sendResetLink(user) {
  return (dispatch) => {
    dispatch(request());

    userService.sendResetLink(user).then(
      (user) => {
        dispatch(success(user));
        dispatch(alertActions.success("reset link sent successfully"));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.RESET_LINK_REQUEST };
  }
  function success(user) {
    return { type: userConstants.RESET_LINK_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.RESET_LINK_FAILURE, error };
  }
}

function invite(user) {
  return (dispatch) => {
    dispatch(request());

    userService.invite(user).then(
      (user) => {
        dispatch(success(user));
        dispatch(alertActions.success("Company invited successfully"));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.INVITE_REQUEST };
  }
  function success(user) {
    return { type: userConstants.INVITE_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.INVITE_FAILURE, error };
  }
}
function getAll() {
  return (dispatch) => {
    dispatch(request());

    userService.getAll().then(
      (users) => dispatch(success(users)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GETALL_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return (dispatch) => {
    dispatch(request(id));

    userService.delete(id).then(
      (user) => dispatch(success(id)),
      (error) => dispatch(failure(id, error.toString()))
    );
  };

  function request(id) {
    return { type: userConstants.DELETE_REQUEST, id };
  }
  function success(id) {
    return { type: userConstants.DELETE_SUCCESS, id };
  }
  function failure(id, error) {
    return { type: userConstants.DELETE_FAILURE, id, error };
  }
}

function getById(id) {
  return (dispatch) => {
    userService.getById(id).then(
      (user) => dispatch(success(user)),
      (error) => dispatch(failure(id, error.toString()))
    );
  };
  function success(user) {
    return { type: userConstants.GET_USER_SUCCESS, user };
  }
  function failure(id, error) {
    return { type: userConstants.GET_USER_FAILURE, id, error };
  }
}
