import { userConstants } from '../constants/user.constants.js';

const initialState = {
    data: null
  }
export default function sendCocFiles(state = initialState, action) {
    switch (action.type) {
        case userConstants.POST_SEND_COC_FILES_SUCCESS:
            return {
                ...state,
                data: action.payload
            }
        case userConstants.POST_SEND_COC_FILES_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        case userConstants.SEND_STEP_TO_COC_PROCESS_CLEAR:
            return state
        default:
            return state
    }
}