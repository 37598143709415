import { userConstants } from '../constants/user.constants.js';
import { vvbService } from '../services/vvb.service.js';
import { alertActions } from './alert.actions.js';

export const vvbActions = {
    assignVvbToCertificate,
    acceptNomination,
    refuseNomination
};

function assignVvbToCertificate(certificationId, userId) {
    return async (dispatch) => {
        const response = await vvbService.assignVVBToCertificate(certificationId, userId);
        if(response.data) {
            dispatch(success(response.data));
        } else {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
        }
    };
    function success(data) { return { type: userConstants.ASSIGN_VVB_SUCCESS, payload: data } }
    function failure(error) { return { type: userConstants.ASSIGN_VVB_FAILURE, payload: error } }
}

function acceptNomination(certificationId, reject=false) {
    return async (dispatch) => {
        const response = await vvbService.acceptNomination(certificationId, reject);
        if(response.data) {
            dispatch(success(response.data));
        } else {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
        }
    };
    function success(data) { return { type: userConstants.ACCEPT_NOMINATION_VVB_SUCCESS, payload: data } }
    function failure(error) { return { type: userConstants.ACCEPT_NOMINATION_VVB_FAILURE, payload: error } }
}

function refuseNomination(certificationId, reject=true) {
    return async (dispatch) => {
        const response = await vvbService.acceptNomination(certificationId, reject);
        if(response.data) {
            dispatch(success(response.data));
        } else {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
        }
    };
    function success(data) { return { type: userConstants.REFUSE_NOMINATION_VVB_SUCCESS, payload: data } }
    function failure(error) { return { type: userConstants.REFUSE_NOMINATION_VVB_FAILURE, payload: error } }
}