import { forwardRef, useEffect, useRef } from "react";
import LabelInput from "./Label";

export const EmailInput = forwardRef((props, ref) => {
  const emailRef = useRef();

  useEffect(() => {
    if (ref) {
      ref.current = emailRef.current;
    }
  }, [ref]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <div className="input flex flex-col">
      <p className="title font-bold text-left text-[#3D4196]">{props.title}</p>
      <LabelInput label="Email Address" />
      {/* <label className="text-[13px] text-[#232323] font-[600] capitalize" htmlFor="email">Email Address</label> */}
      <input
        className="p-0 bg-white border-b-2 border-[#dedede] border-x-0 border-t-0 focus:ring-0 focus:bg-transparent w-full focus:border-b-[#3BB34B] focus:outline-none focus:border-b-2 h-[50px]"
        id="email"
        name="email"
        placeholder="Email Address"
        type="email"
        {...props.register}
        onChange={props.onChange}
        onKeyDown={handleKeyDown}
        ref={emailRef}
        value={props.value}
        tabIndex={props.tabIndex}
      />
      {props.errors}
    </div>
  );
});

export default EmailInput;
