import TransactionDetails from "../../../components/Shared/TransactionDetails";

const FifthStep = (formData, showPreviousButton) => {
  const stepDisplayed = (
    <div className="w-11/12 my-9 flex justify-center items-center">
      <div className="">
        <TransactionDetails certificationData={formData} />
      </div>
    </div>
  );
  return stepDisplayed;
};
export default FifthStep;
