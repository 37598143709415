import React, { useEffect, useState } from "react";
import TextInput from "../../../components/forms/TextInput";
import RadioInput from "../../../components/forms/RadioInput";
import TextArea from "../../../components/forms/TextArea";
import LabelInput from "../../../components/forms/Label";
import InfoPopup from "../../../components/create-certification/InfoPopup";
import { preTransactionInfoOtherService } from "../../../utils/constant";
import Error from "../../../components/Alert/Error";
import { fieldRequiredMessage } from "../../../errors/errors";
import { formatDate } from "../../../utils/utils";
import DefaultRadioInput from "../../../components/forms/DefaultRadioInput";

const VoyageServiceAdmin = ({
  formData,
  setFormData,
  errors,
  errorCount,
  register,
  setValue,
  reset,
  unregister,
}) => {
  // useEffect(() => {
  //   reset();
  // }, [reset]);
  const [preTransactionChecked, setPreTransactionChecked] = useState();

  useEffect(() => {
    setValue("freightDescription", formData?.freightDescription || "");
    setValue("numberOfTranscations", formData?.numberOfTranscations || 0);
    setValue("shippingDate", formData?.shippingDate || "");
    setValue("origin", formData?.origin || "");
    setValue("destination", formData?.destination || "");
    setValue("fuelConsumed", formData?.fuelConsumed || "");
    setValue("typeOfFuel", formData?.typeOfFuel || "");
    setValue("vesselName", formData?.vesselName || "");
    setValue("vesselType", formData?.vesselType || "");
    setValue(
      "ballastVoyageDescription",
      formData?.ballastVoyageDescription || ""
    );
    setValue(
      "preTransactionCertification",
      formData?.preTransactionCertification ? true : false
    );
    setValue(
      "postTransactionCertification",
      formData?.preTransactionCertification ? false : true
    );
    setValue("isBallastVoyage", formData?.cradleToGate ? true : false);
    setValue("transactionDescription", formData?.transactionDescription || "");
  }, [formData, setValue]);

  const [preTransactionRequired, setPreTransactionRequired] = useState(true);
  const handleChangeInput = (fieldName, value, fieldsToUnregister) => {
    setFormData({ ...formData, [fieldName]: value });
    setValue(fieldName, value);

    // Mettre à jour les champs à désenregistrer
    if (fieldsToUnregister) {
      // Désenregistrer les champs
      for (const field of fieldsToUnregister) {
        unregister(field);
        reset(
          {
            [field]: "",
          },
          {
            keepValues: true, // Garde les valeurs des autres champs inchangées
          }
        );
      }
    }
    if (
      fieldName === "preTransactionCertification" ||
      fieldName === "postTransactionCertification"
    ) {
      setPreTransactionChecked(value);
      setPreTransactionRequired(false);
    }
  };

  useEffect(() => {
    if (
      formData?.preTransactionCertification === true ||
      formData?.preTransactionCertification === false
    ) {
      setPreTransactionRequired(false);
      setPreTransactionChecked(formData?.preTransactionCertification);
    }
  }, [formData?.preTransactionCertification]);
  return (
    <>
      {/*Second input*/}

      <div className="flex mb-3 mt-10">
        <LabelInput label="Ballast voyage(s)" />
      </div>
      <div className="flex mt-2">
        <div className="flex">
          <RadioInput
            onChange={(e) => handleChangeInput("isBallastVoyage", true)}
            register={{
              ...register("isBallastVoyage", {
                required: false,
              }),
            }}
            checked={formData?.isBallastVoyage}
            value={true}
            name="isBallastVoyage"
          />
          <label className="capitalize text-sm" htmlFor="isBallastVoyage">
            Yes
          </label>
        </div>
        <div className="flex ml-4">
          <RadioInput
            onChange={(e) =>
              handleChangeInput("isBallastVoyage", false, [
                "ballastVoyageDescription",
              ])
            }
            register={{
              ...register("notBallastVoyage", {
                required: false,
              }),
            }}
            checked={formData?.isBallastVoyage === false}
            value={false}
            name="notBallastVoyage"
          />
          <label className="capitalize text-sm" htmlFor="isBallastVoyage">
            No
          </label>
        </div>
      </div>
      {errors.isBallastVoyage &&
        errors.isBallastVoyage.type === "required" &&
        errors.notBallastVoyage &&
        errors.notBallastVoyage.type === "required" && (
          <Error
            key={errorCount}
            text={fieldRequiredMessage("Ballast voyage")}
          />
        )}

      <div className="flex mb-3">
        {JSON.parse(formData?.isBallastVoyage) === true && (
          <div className="col pl-0">
            <TextInput
              onChange={(e) =>
                handleChangeInput("ballastVoyageDescription", e.target.value)
              }
              register={{
                ...register("ballastVoyageDescription", { required: false }),
              }}
              name="ballastVoyageDescription"
              defaultValue={formData?.ballastVoyageDescription}
              label="Ballast voyage(s) description"
              placeholder="Enter ballast voyage description"
            />
            {errors.ballastVoyageDescription &&
              errors.ballastVoyageDescription.type === "required" && (
                <Error
                  key={errorCount}
                  text={fieldRequiredMessage("Ballast voyage(s) description")}
                />
              )}
          </div>
        )}

        <div
          className={
            JSON.parse(formData?.isBallastVoyage) === false
              ? "pl-0 col"
              : "col pl-0"
          }
        >
          <TextInput
            onChange={(e) => handleChangeInput("vesselType", e.target.value)}
            register={{
              ...register("vesselType", { required: false }),
            }}
            name="vesselType"
            defaultValue={formData?.vesselType}
            label="Vessel type"
            placeholder="Enter vessel type"
          />
          {errors.vesselType && errors.vesselType.type === "required" && (
            <Error
              key={errorCount}
              text={fieldRequiredMessage("Vessel type")}
            />
          )}
        </div>

        <div className="col pr-0">
          <TextInput
            onChange={(e) => handleChangeInput("vesselName", e.target.value)}
            register={{
              ...register("vesselName", { required: false }),
            }}
            name="vesselName"
            defaultValue={formData?.vesselName}
            label="if known, Vessel name and IMO number"
            placeholder="Enter Vessel name and IMO number"
          />
          {errors.vesselName && errors.vesselName.type === "required" && (
            <Error
              key={errorCount}
              text={fieldRequiredMessage("Vessel Name")}
            />
          )}
        </div>
      </div>

      {/*Third input*/}

      <div className="flex mt-3">
        <div className="col pl-0">
          <TextInput
            onChange={(e) => handleChangeInput("typeOfFuel", e.target.value)}
            register={{
              ...register("typeOfFuel", { required: false }),
            }}
            name="typeOfFuel"
            defaultValue={formData?.typeOfFuel}
            label="Fuel type"
            placeholder="Enter fuel type"
          />
          {errors.typeOfFuel && errors.typeOfFuel.type === "required" && (
            <Error key={errorCount} text={fieldRequiredMessage("Fuel type")} />
          )}
        </div>

        <div className="col">
          <TextInput
            onChange={(e) => handleChangeInput("fuelConsumed", e.target.value)}
            register={{
              ...register("fuelConsumed", { required: false }),
            }}
            name="fuelConsumed"
            defaultValue={formData?.fuelConsumed}
            label="Consumed Fuel Quantity (estimated or actual)"
            placeholder="Consumed fuel"
          />
          {errors.fuelConsumed && errors.fuelConsumed.type === "required" && (
            <Error
              key={errorCount}
              text={fieldRequiredMessage("Consumed fuel")}
            />
          )}
        </div>

        <div className="col pr-0">
          <TextInput
            onChange={(e) =>
              handleChangeInput("freightDescription", e.target.value)
            }
            register={{
              ...register("freightDescription", { required: false }),
            }}
            name="freightDescription"
            defaultValue={formData?.freightDescription}
            label="Freight description"
            placeholder="Freight description"
          />
          {errors.freightDescription &&
            errors.freightDescription.type === "required" && (
              <Error
                key={errorCount}
                text={fieldRequiredMessage("Freight Description")}
              />
            )}
        </div>
      </div>

      {/** First input */}
      {/** First input */}
      <div className="flex mb-3 mt-3">
        <div className="col pl-0">
          <TextInput
            isNumberType={true}
            label="Number of voyages to be certified"
            onChange={(e) =>
              handleChangeInput("numberOfTranscations", e.target.value)
            }
            register={{
              ...register("numberOfTranscations", { required: true }),
            }}
            name="numberOfTranscations"
            defaultValue={formData?.numberOfTranscations}
            placeholder="Number of voyages "
          />
          {errors.numberOfTranscations &&
            errors.numberOfTranscations.type === "required" && (
              <Error
                key={errorCount}
                text={fieldRequiredMessage("Number Of Voyages")}
              />
            )}
        </div>
        <div className="col date pr-0">
          <TextInput
            type="date"
            onChange={(e) => handleChangeInput("shippingDate", e.target.value)}
            register={{
              ...register("shippingDate", { required: true }),
            }}
            name="estimatedFirstTransDate"
            defaultValue={
              formData?.shippingDate && formatDate(formData?.shippingDate)
            }
            label="First voyage date"
            placeholder="mm/dd/yy"
          />
          {errors.shippingDate && errors.shippingDate.type === "required" && (
            <Error
              key={errorCount}
              text={fieldRequiredMessage("First voyage date")}
            />
          )}
        </div>
      </div>

      <div className="flex mb-3 mt-3">
        <div className="col pl-0">
          <TextInput
            onChange={(e) => handleChangeInput("origin", e.target.value)}
            register={{
              ...register("origin", { required: true }),
            }}
            name="origin"
            defaultValue={formData?.origin}
            label="Origin"
            placeholder="Location"
          />
          {errors.origin && errors.origin.type === "required" && (
            <Error key={errorCount} text={fieldRequiredMessage("Location")} />
          )}
        </div>

        <div className="col pr-0">
          <TextInput
            onChange={(e) => handleChangeInput("destination", e.target.value)}
            register={{
              ...register("destination", { required: true }),
            }}
            name="destination"
            defaultValue={formData?.destination}
            label="Destination"
            placeholder="Destination"
          />
          {errors.destination && errors.destination.type === "required" && (
            <Error
              key={errorCount}
              text={fieldRequiredMessage("Destination")}
            />
          )}
        </div>
      </div>

      {/*Fourth input*/}

      <div className="mt-4">
        <div className="flex mb-3">
          <LabelInput label="Certification" />
        </div>
        <div className="flex mb-3 nospace">
          <DefaultRadioInput
            onChange={(e) =>
              handleChangeInput("preTransactionCertification", true)
            }
            register={{
              ...register("preTransactionCertification", {
                required: preTransactionRequired,
              }),
            }}
            defaultChecked={preTransactionChecked === true}
            value={true}
            name="preTransactionCertification"
            label="Pre-Transaction(s) Certification"
            id="pre"
            htmlFor="pre"
          />
          <InfoPopup text={preTransactionInfoOtherService} />
        </div>

        <div className="flex mb-3 nospace">
          <DefaultRadioInput
            onChange={(e) =>
              handleChangeInput("preTransactionCertification", false, [])
            }
            register={{
              ...register("postTransactionCertification", {
                required: preTransactionRequired,
              }),
            }}
            defaultChecked={preTransactionChecked === false}
            value={false}
            name="postTransactionCertification"
            label="Post-Transaction(s) Certification"
            id="post"
            htmlFor="post"
          />
        </div>
      </div>
      {errors.preTransactionCertification &&
        errors.preTransactionCertification.type === "required" &&
        errors.postTransactionCertification &&
        errors.postTransactionCertification.type === "required" && (
          <Error
            key={errorCount}
            text={fieldRequiredMessage("Transaction Certification")}
          />
        )}
      <div className="col max-w-[390px] mt-4 pl-0">
        <TextArea
          onChange={(e) =>
            handleChangeInput("transactionDescription", e.target.value)
          }
          register={{
            ...register("transactionDescription", { required: true }),
          }}
          defaultValue={formData?.transactionDescription}
          name="transactionDescription"
          label="Service description"
          placeholder="Service description, additional information:"
        />
        {errors.transactionDescription &&
          errors.transactionDescription.type === "required" && (
            <Error
              key={errorCount}
              text={fieldRequiredMessage("Service Description")}
            />
          )}
      </div>
    </>
  );
};

export default VoyageServiceAdmin;
