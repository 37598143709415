import { React } from "react";
import RadioInput from "../../../components/forms/RadioInput";
import TextInput from "../../../components/forms/TextInput";
import Error from "../../../components/Alert/Error";
import { fieldRequiredMessage } from "../../../errors/errors";
import EmailInput from "../../../components/forms/EmailInput";

const SecondStep = ({
  formData,
  setFormData,
  errors,
  errorCount,
  register,
  setValue,
  unregister,
}) => {
  const handleChangeInput = (fieldName, value, fieldsToUnregister) => {
    setFormData({ ...formData, [fieldName]: value });
    setValue(fieldName, value);

    // Mettre à jour les champs à désenregistrer
    if (fieldsToUnregister) {
      // Désenregistrer les champs
      for (const field of fieldsToUnregister) {
        unregister(field);
      }
    }
  };

  return (
    <div className="w-11/12 p-4">
      <p className="">
        Is the company able to calculate the carbon footprint of the
        transaction(s) or portfolio to be certified on its own?
      </p>

      <div className="flex mt-3">
        <div className="flex mb-3">
          <RadioInput
            onChange={(e) =>
              handleChangeInput("ableToCalculateCF", true, [
                "requireServicesToCalculateCF",
                "nameOfServiceProvideCFC",
                "contactAssuranceProvider",
                "emailOfServiceProvider",
              ])
            }
            register={{ ...register("ableToCalculateCF", { required: true }) }}
            checked={formData.ableToCalculateCF}
            value={true}
            name="ableToCalculateCF"
          />
          <label className="capitalize text-sm" htmlFor="">
            Yes
          </label>
        </div>

        <div className="flex mb-3 ml-4">
          <RadioInput
            onChange={(e) =>
              handleChangeInput("ableToCalculateCF", false, [
                "hasCompanyContractedCalculation",
                "requireServicesToCalculateCF",
              ])
            }
            register={{ ...register("ableToCalculateCF", { required: true }) }}
            checked={formData.ableToCalculateCF === false}
            value={false}
            name="ableToCalculateCF"
          />
          <label className="capitalize text-sm" htmlFor="">
            No
          </label>
        </div>
      </div>
      {errors.ableToCalculateCF &&
        errors.ableToCalculateCF.type === "required" && (
          <Error key={errorCount} text={fieldRequiredMessage("This field")} />
        )}
      {JSON.parse(formData.ableToCalculateCF) === false && (
        <div>
          <p className="">
            Has the company already contracted an independent expert service
            provider to calculate and report the carbon footprint?
          </p>

          <div className="flex mt-3">
            <div className="flex mb-3">
              <RadioInput
                onChange={(e) =>
                  handleChangeInput("hasCompanyContractedCalculation", true, [
                    "requireServicesToCalculateCF",
                    "nameOfServiceProvideCFC",
                    "contactAssuranceProvider",
                    "emailOfServiceProvider",
                  ])
                }
                register={{
                  ...register("hasCompanyContractedCalculation", {
                    required: true,
                  }),
                }}
                checked={formData.hasCompanyContractedCalculation === true}
                value={true}
                name="hasCompanyContractedCalculation"
              />
              <label className="capitalize text-sm" htmlFor="">
                Yes
              </label>
            </div>

            <div className="flex mb-3 ml-4">
              <RadioInput
                onChange={(e) =>
                  handleChangeInput("hasCompanyContractedCalculation", false, [
                    "nameOfServiceProvideCFC",
                    "contactAssuranceProvider",
                    "emailOfServiceProvider",
                  ])
                }
                register={{
                  ...register("hasCompanyContractedCalculation", {
                    required: true,
                  }),
                }}
                checked={formData.hasCompanyContractedCalculation === false}
                value={false}
                name="hasCompanyContractedCalculation"
              />
              <label className="capitalize text-sm" htmlFor="">
                No
              </label>
            </div>
          </div>
          {errors.hasCompanyContractedCalculation &&
            errors.hasCompanyContractedCalculation.type === "required" && (
              <Error
                key={errorCount}
                text={fieldRequiredMessage("This field")}
              />
            )}
        </div>
      )}
      {JSON.parse(formData.hasCompanyContractedCalculation) === true &&
        JSON.parse(formData.ableToCalculateCF) === false && (
          <div>
            <p className="">
              If possible, please disclose the details of this service provider
            </p>
            <div className="flex w-full justify-between mt-3">
              <div className="col pl-0">
                <TextInput
                  name="nameOfServiceProvideCFC"
                  value={formData.nameOfServiceProvideCFC}
                  onChange={(e) =>
                    handleChangeInput("nameOfServiceProvideCFC", e.target.value)
                  }
                  register={{
                    ...register("nameOfServiceProvideCFC", { required: false }),
                  }}
                  label="Service Provider"
                  placeholder="Company"
                />
                {/* {errors.nameOfServiceProvideCFC &&
                  errors.nameOfServiceProvideCFC.type === "required" && (
                    <Error
                      key={errorCount}
                      text={fieldRequiredMessage("Company Name")}
                    />
                  )} */}
              </div>

              <div className="col">
                <TextInput
                  onChange={(e) =>
                    handleChangeInput(
                      "contactAssuranceProvider",
                      e.target.value
                    )
                  }
                  register={{
                    ...register("contactAssuranceProvider", {
                      required: false,
                    }),
                  }}
                  name="contactAssuranceProvider"
                  value={formData.contactAssuranceProvider}
                  label="Point Of contact"
                  placeholder="Name"
                />
                {/* {errors.contactAssuranceProvider &&
                  errors.contactAssuranceProvider.type === "required" && (
                    <Error
                      key={errorCount}
                      text={fieldRequiredMessage("Name of Service Provider")}
                    />
                  )} */}
              </div>

              <div className="col mt-2">
                <EmailInput
                  onChange={(e) =>
                    handleChangeInput("emailOfServiceProvider", e.target.value)
                  }
                  register={{
                    ...register("emailOfServiceProvider", {
                      required: false,
                    }),
                  }}
                  name="emailOfServiceProvider"
                  value={formData.emailOfServiceProvider}
                  label="Email-invisible"
                  placeholder="Email"
                />
                {/* {errors.emailOfServiceProvider &&
                  errors.emailOfServiceProvider.type === "required" && (
                    <Error
                      key={errorCount}
                      text={fieldRequiredMessage("Email of Service Provider")}
                    />
                  )} */}
              </div>
            </div>
          </div>
        )}

      {JSON.parse(formData.hasCompanyContractedCalculation) === false &&
        JSON.parse(formData.ableToCalculateCF) === false && (
          <div>
            <p className="">
              Does the company require services of a partner to calculate the
              carbon footprint? (COC Management can introduce independent expert
              MRV companies):
            </p>

            <div className="flex mt-3">
              <div className="flex mb-3">
                <RadioInput
                  onChange={(e) =>
                    handleChangeInput("requireServicesToCalculateCF", true, [])
                  }
                  register={{
                    ...register("requireServicesToCalculateCF", {
                      required: true,
                    }),
                  }}
                  checked={formData.requireServicesToCalculateCF === true}
                  value={true}
                  name="requireServicesToCalculateCF"
                />
                <label className="capitalize text-sm" htmlFor="">
                  Yes
                </label>
              </div>

              <div className="flex mb-3 ml-4">
                <RadioInput
                  onChange={(e) =>
                    handleChangeInput("requireServicesToCalculateCF", false, [])
                  }
                  register={{
                    ...register("requireServicesToCalculateCF", {
                      required: true,
                    }),
                  }}
                  checked={formData.requireServicesToCalculateCF === false}
                  value={false}
                  name="requireServicesToCalculateCF"
                />
                <label className="capitalize text-sm" htmlFor="">
                  No
                </label>
              </div>
            </div>
            {errors.requireServicesToCalculateCF &&
              errors.requireServicesToCalculateCF.type === "required" && (
                <Error
                  key={errorCount}
                  text={fieldRequiredMessage("This field")}
                />
              )}
          </div>
        )}
    </div>
  );
};

export default SecondStep;
