import { Tooltip } from "@material-tailwind/react";

export default function Warning({ text }) {
  return (
    <div>
      <Tooltip content={`special character authorized: !@#$%^&_*+=?`}>
        <p className="mt-2 text-sm text-gray">
          <span className="text-red-500 mr-1">**</span>
          {text}
        </p>
      </Tooltip>
    </div>
  );
}
