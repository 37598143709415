import { userConstants } from "../constants/user.constants.js";

const initialState = {
  data: null,
};
export default function admin(state = initialState, action) {
  switch (action.type) {
    case userConstants.UPDATE_USER_SUCCESS:
      return {
        ...state,
        updated_user: action.user,
      };

    case userConstants.UPDATE_USER_FAILURE:
      return {
        ...state,
        error_on_updated_user: action.error,
      };
    case userConstants.UPDATE_CERTIFICATION_SUCCESS:
      return {
        ...state,
        updated_certification: action.certification,
      };

    case userConstants.UPDATE_CERTIFICATION_FAILURE:
      return {
        ...state,
        error_on_updated_certification: action.error,
      };
    case userConstants.REMOVE_USER_SUCCESS:
      return {
        ...state,
        removed_user: action.user,
      };

    case userConstants.REMOVE_USER_FAILURE:
      return {
        ...state,
        error_on_removing_user: action.error,
      };
    case userConstants.CREATE_PARTNER_SUCCESS:
      return {
        ...state,
        partner_created: action.payload,
      };

    case userConstants.CREATE_PARTNER_FAILURE:
      return {
        ...state,
        error_on_creating_partner: action.payload,
      };
    default:
      return state;
  }
}
