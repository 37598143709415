import React, { useEffect } from "react";
import TextInput from "../../../components/forms/TextInput";
import TextArea from "../../../components/forms/TextArea";
import Error from "../../../components/Alert/Error";
import { fieldRequiredMessage } from "../../../errors/errors";
import TimePeriodInput from "../../../components/forms/TimePeriodInput";

const TimeCharteringService = ({
  formData,
  setFormData,
  errors,
  register,
  setValue,
  initDates,
}) => {
  useEffect(() => {
    setValue("contractTimePeriod", formData?.contractTimePeriod || "");
    setValue("fuelConsumed", formData?.fuelConsumed || "");
    setValue("typeOfFuel", formData?.typeOfFuel || "");
    setValue("vesselName", formData?.vesselName || "");
    setValue("vesselType", formData?.vesselType || "");
    setValue("transactionDescription", formData?.transactionDescription || "");
  }, [formData, setValue]);

  const handleChangeInput = (filedName, value) => {
    setFormData({ ...formData, [filedName]: value });
    setValue(filedName, value);
  };

  useEffect(() => {
    return () => {
      initDates("Shipping Time Chart");
    };
  }, [initDates]);
  return (
    <>
      <div className="flex mt-10 time-chartering">
        <div className="col pl-0">
          <TextInput
            onChange={(e) => handleChangeInput("vesselType", e.target.value)}
            register={{
              ...register("vesselType", { required: true }),
            }}
            name="vesselType"
            defaultValue={formData?.vesselType}
            label="Vessel type"
            placeholder="Enter vessel type"
          />
          {errors.vesselType && errors.vesselType.type === "required" && (
            <Error text={fieldRequiredMessage("Vessel Type")} />
          )}
        </div>

        <div className="col">
          <TextInput
            onChange={(e) => handleChangeInput("vesselName", e.target.value)}
            register={{
              ...register("vesselName", { required: true }),
            }}
            name="vesselName"
            defaultValue={formData?.vesselName}
            label="Name of vessel"
            placeholder="Vessel name"
          />
          {errors.vesselName && errors.vesselName.type === "required" && (
            <Error text={fieldRequiredMessage("Vessel Name")} />
          )}
        </div>

        <div className="date relative -top-[9px]">
          <TimePeriodInput
            register={{
              ...register("contractTimePeriod", { required: true }),
            }}
            defaultValue={formData?.contractTimePeriod}
            label="Contract Time period"
            placeholder="Contract Time Period"
            fieldName="contractTimePeriod"
            setTimePeriodValue={handleChangeInput}
          />
          {errors.contractTimePeriod &&
            errors.contractTimePeriod.type === "required" && (
              <Error text={fieldRequiredMessage("Contract Time Period")} />
            )}
        </div>
      </div>
      <div className="flex mt-3">
        <div className="col pl-0">
          <TextInput
            onChange={(e) => handleChangeInput("typeOfFuel", e.target.value)}
            register={{
              ...register("typeOfFuel", { required: false }),
            }}
            name="typeOfFuel"
            defaultValue={formData?.typeOfFuel}
            label="Fuel type"
            placeholder="Enter fuel type"
          />
          {errors.typeOfFuel && errors.typeOfFuel.type === "required" && (
            <Error text={fieldRequiredMessage("Fuel Type")} />
          )}
        </div>
        <div className="col pr-0">
          <TextInput
            onChange={(e) => handleChangeInput("fuelConsumed", e.target.value)}
            register={{
              ...register("fuelConsumed", { required: true }),
            }}
            name="fuelConsumed"
            defaultValue={formData?.fuelConsumed}
            label="Consumed Fuel Quantity (estimated or actual)"
            placeholder="Consumed Fuel"
          />
          {errors.fuelConsumed && errors.fuelConsumed.type === "required" && (
            <Error text={fieldRequiredMessage("Consumed Fuel")} />
          )}
        </div>
      </div>

      <div className="col max-w-[390px] mt-4 pl-0">
        <TextArea
          onChange={(e) =>
            handleChangeInput("transactionDescription", e.target.value)
          }
          register={{
            ...register("transactionDescription", { required: true }),
          }}
          name="transactionDescription"
          defaultValue={formData?.transactionDescription}
          label="Service description"
          placeholder="Service description, additional information:"
        />
        {errors.transactionDescription &&
          errors.transactionDescription.type === "required" && (
            <Error text={fieldRequiredMessage("Service Description")} />
          )}
      </div>
    </>
  );
};

export default TimeCharteringService;
