import "./Admin.css";
import CreatePartner from "../../components/Modals/CreatePartner";
import Details from "../../components/Modals/UserDetails";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../actions/user.actions";
import SearchBar from "../../components/forms/Searchbar";
import { sortData } from "../../utils/utils";
import { userService } from "../../services/user.service";
import DeleteUserModal from "../../components/Modals/DeleteModal";
import ReactTable from "../../components/Table/ReactTable";

export default function Admin() {
  const users = useSelector((state) => state.users.items);
  const dispatch = useDispatch();
  const userUpdated = useSelector((state) => state.admin.updated_user);
  const userDeleted = useSelector((state) => state.admin.removed_user);
  const isRegisteredPartner = useSelector(
    (state) => state.registration.registered
  );

  const [data, setData] = useState((users && users.data) || []);
  const [searchValue, setSearchValue] = useState();
  const [userId, setUserId] = useState();
  const [deleteId, setDeleteId] = useState();
  const [userDetail, setUserDetail] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showPartnerModal, setShowPartnerModal] = useState(false);

  useEffect(() => {
    if (users) {
      setData(users.data);
    }
  }, [users]);

  useEffect(() => {
    if (userUpdated) {
      dispatch(userActions.getAll());
    }
  }, [dispatch, userUpdated]);

  useEffect(() => {
    if (userDeleted) {
      dispatch(userActions.getAll());
    }
  }, [dispatch, userDeleted]);

  useEffect(() => {
    if (isRegisteredPartner) {
      dispatch(userActions.getAll());
    }
  }, [dispatch, isRegisteredPartner]);

  useEffect(() => {
    dispatch(userActions.getAll());
  }, [dispatch]);

  const onSearch = (e) => {
    setSearchValue(e);
  };

  const handleChangeDelete = (changes) => {
    setShowModal(false);
  };

  useEffect(() => {
    if (searchValue) {
      const dataFiltered = users?.data.filter(
        (cert) =>
          cert.firstName?.toLowerCase().includes(searchValue) ||
          cert.lastName?.toLowerCase().includes(searchValue) ||
          cert.email?.toLowerCase().includes(searchValue) ||
          cert.userRole?.toLowerCase().includes(searchValue)
      );
      setData(dataFiltered);
    } else {
      setData(sortData(users?.data, "userRole"));
    }
  }, [searchValue, users]);

  const handleClickDetail = (userId) => {
    userService
      .getById(userId)
      .then((response) => setUserDetail(response.data));

    setUserId(userId);
    setShowEditModal(true);
  };

  const handleClickDelete = (userId) => {
    setShowModal(true);
    setDeleteId(userId);
  };

  return (
    <div className="flex flex-col px-12 admin-users">
      <div className="flex justify-between items-center">
        <button
          type="button"
          onClick={() => setShowPartnerModal(true)}
          className="py-3 px-9 bg-coc-green flex text-white text-xs items-center justify-center rounded-md"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-5 h-5 text-white mr-2"
          >
            <path
              fillRule="evenodd"
              d="M12 3.75a.75.75 0 01.75.75v6.75h6.75a.75.75 0 010 1.5h-6.75v6.75a.75.75 0 01-1.5 0v-6.75H4.5a.75.75 0 010-1.5h6.75V4.5a.75.75 0 01.75-.75z"
              clipRule="evenodd"
            />
          </svg>
          Create new partner
        </button>
        <SearchBar onSearch={onSearch} isPageAdmin={true} />
      </div>

      <hr className="border-b-light-grey w-full h-2 my-4" />

      <ReactTable
        certificationList={(data && data) || []}
        isAdminUser={true}
        handleClickDelete={handleClickDelete}
        handleClickDetail={handleClickDetail}
      />

      <DeleteUserModal
        userId={deleteId}
        showConfirmationModal={showModal}
        handleChange={handleChangeDelete}
      />
      <Details
        user={userDetail}
        userId={userId}
        showConfirmationModal={showEditModal}
        setShowConfirmationModal={setShowEditModal}
      />
      <CreatePartner
        showConfirmationModal={showPartnerModal}
        setShowModal={setShowPartnerModal}
      />
    </div>
  );
}
