import TextInput from "../forms/TextInput";
import PasswordInput from "../forms/PasswordInput";
import Upload from "../forms/Buttons/UploadButton";
import LabelInput from "../forms/Label";
import CheckboxInput from "../forms/CheckboxInput";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import isValidLogoFormat, { validPassword } from "../../utils/validation";
import {
  invalidLogoFormatMessage,
  invalidPasswordMessage,
  invalidSizeFile,
  maxUploadFileSize,
  passwordsNotMatchMessage,
} from "../../errors/errors";
import Error from "../Alert/Error";
import { adminActions } from "../../actions/admin.actions";
import { useEffect } from "react";
import SubmitButton from "../forms/Buttons/SubmitButton";
import Modal from "react-modal";
import DefaultProfilePicture from "../Header/DefaultProfilePicture";

export default function Details({
  user,
  userId,
  showConfirmationModal,
  setShowConfirmationModal,
}) {
  const [updatePassword, setUpdatePassword] = useState(false);
  const [userImage, setUserImage] = useState(null);
  const [file, setFile] = useState(null);
  const [userFileName, setUserFileName] = useState(null);
  const [userFileSize, setUserFileSize] = useState(null);
  const [errorCount, setErrorCount] = useState(0);
  const [passwordTyped, setPasswordTyped] = useState(null);
  const [companyExpertises, setCompanyExpertises] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordTypedKey, setPasswordTypedKey] = useState(false);
  const dispatch = useDispatch();
  const userUpdated = useSelector((state) => state.admin.updated_user);
  const previousUserRef = useRef(null);

  const {
    handleSubmit,
    getValues,
    register,
    setValue,
    formState: { errors },
    reset,
  } = useForm();

  const handleCloseModal = () => {
    setUserImage(null);
    setUserFileName(null);
    setUpdatePassword(false);

    reset();
    setShowConfirmationModal(false);
  };

  const showEditPassword = () => {
    setUpdatePassword(true);
    setPasswordTyped(null);
    setValue("ConfirmPassword", null);
  };

  useEffect(() => {
    setFile(null);
    if (previousUserRef.current !== null) {
      // Comparer le user actuel avec le user précédent
      if (user?.email !== previousUserRef?.current?.email) {
        setValue("LastName", user?.lastName || "");
        setValue("FirstName", user?.firstName || "");
        setValue("Email", user?.email || "");
        setValue("CompanyName", user?.companyName || "");
        setValue("CompanyAddress", user?.companyAddress || "");
        setValue("CompanyExpertise", user?.companyExpertise || "");
        setValue("CompanyWebsite", user?.companyWebsite || "");
        setValue("PhoneNumber", user?.phoneNumber || "");
        setValue("Password", "");
      }
      previousUserRef.current = user;
    } else {
      // Cas du premier rendu, initialiser la référence du user précédent
      previousUserRef.current = user;
    }
    setUpdatePassword(false);
  }, [userId, setValue, user]);

  const updateInputValue = (fieldName, value, event = null) => {
    if (fieldName === "Password" && passwordTypedKey === true) {
      setPasswordTyped(value);
    }

    setValue(fieldName, value);
    setPasswordTypedKey(false);
  };

  const onSubmitForm = () => {
    setIsLoading(true);
    let userData = new FormData();
    const companyExpertises = getValues().CompanyExpertise || [];
    companyExpertises?.forEach((value, index) => {
      userData.append(`CompanyExpertise[${index}]`, value);
    });
    const role =
      companyExpertises.length > 0 && handleSettingRole(companyExpertises);
    userData.append("LastName", getValues().LastName ?? user?.lastName ?? "");

    userData.append(
      "FirstName",
      getValues().FirstName ?? user?.firstName ?? ""
    );

    userData.append("ClienType", 2);
    userData.append("Email", getValues().Email ?? user?.email ?? "");

    userData.append(
      "PhoneNumber",
      getValues().PhoneNumber ?? user?.phoneNumber ?? ""
    );

    getValues().Password && userData.append("Password", getValues().Password);
    getValues().ConfirmPassword &&
      userData.append("ConfirmPassword", getValues().ConfirmPassword);
    userData.append(
      "CompanyName",
      getValues().CompanyName ?? user?.companyName ?? ""
    );

    userData.append(
      "CompanyWebsite",
      getValues().CompanyWebsite ?? user?.companyWebsite ?? ""
    );

    userData.append(
      "CompanyAddress",
      getValues().CompanyAddress ?? user?.companyAddress ?? ""
    );

    userData.append("UserRole", role ? role : user?.userRole);
    file && userData.append("File", file);
    dispatch(adminActions.updateUser(userId, userData));
    reset();
  };

  const onChooseFile = (e) => {
    const file = e.target.files[0];
    setUserImage(URL.createObjectURL(file));
    setFile(file);
    setUserFileName(file.name);
    setUserFileSize(file.size);
  };

  const handleSettingRole = (companyExpertises) => {
    if (companyExpertises.includes("2")) {
      return "VAP";
    } else if (companyExpertises.includes("3")) {
      return "CMAP";
    } else if (companyExpertises.includes("1")) {
      return "GHG";
    } else if (companyExpertises.includes("4")) {
      return "Other";
    }
  };

  const handleChangeCompanyExpertise = (e) => {
    const value = parseInt(e.target.value);
    if (companyExpertises?.includes(value)) {
      setCompanyExpertises(
        companyExpertises?.filter((element) => element !== value)
      );
    } else {
      setCompanyExpertises([...companyExpertises, value]);
    }
  };

  useEffect(() => {
    setCompanyExpertises(user?.companyExpertise);
  }, [user?.companyExpertise]);

  useEffect(() => {
    if (userUpdated && userUpdated?.data.id === userId) {
      setIsLoading(false);
      //document.getElementById("closeButton").click();
      setShowConfirmationModal(false);
    }
  }, [userUpdated, userId, setShowConfirmationModal]);

  return (
    <Modal
      isOpen={showConfirmationModal}
      className="popup-edit-modal admin-users"
      overlayClassName="modall-overlay"
      shouldCloseOnOverlayClick={false}
    >
      <div className="max-h-screen overflow-hidden">
        <div className="flex overflow-auto h-full relative justify-center">
          <div className="absolute top-2 right-2">
            <button
              id="closeButton"
              type="button"
              className="inline-flex flex-shrink-0 rounded-full border-light-grey justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800"
              onClick={handleCloseModal}
            >
              <span className="sr-only">Close</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </button>
          </div>

          <form onSubmit={handleSubmit(onSubmitForm)}>
            <div className="p-4 sm:p-10 overflow-y-auto">
              <h3 className="text-xl font-bold text-gray-800 dark:text-gray-200 flex">
                {user?.firstName} {user?.lastName}
                <span className="text-gray-400 hover:text-gray-500 hover:cursor-pointer ml-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6"
                  >
                    <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                    <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                  </svg>
                </span>
              </h3>
              <p className="text-gray-400 font-light">
                Change information about this profile
              </p>
              <div className="profile mt-4">
                <div className="img flex">
                  {userImage ? (
                    <img
                      src={userImage}
                      alt="upload logo"
                      className="w-[120px] h-[120px] object-contain object-top rounded-full"
                    />
                  ) : user?.companyLogo ? (
                    <img
                      src={`data:image/jpeg;base64,${user?.companyLogo}`}
                      alt="company logo"
                      className="w-[120px] h-[120px] object-cover rounded-full"
                    />
                  ) : (
                    <img
                      src="/assets/images/image-not-found.jpg"
                      alt="No picture available"
                      className="w-[120px] h-[120px] object-cover rounded-full"
                    />
                  )}

                  <div className="ml-4">
                    <Upload
                      label="Change picture"
                      onChange={onChooseFile}
                      id="upload-user-image"
                      register={{ ...register("File") }}
                    >
                      <svg
                        className="w-5 h-5 mr-2 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                        />
                      </svg>
                    </Upload>
                    <p className="mt-2 text-sm text-gray">
                      {userFileName
                        ? userFileName
                        : "only .jpg or .png files are accepted"}
                    </p>
                    {userFileName && !isValidLogoFormat(userFileName) && (
                      <Error key={errorCount} text={invalidLogoFormatMessage} />
                    )}
                    {userFileSize && userFileSize > maxUploadFileSize && (
                      <Error key={errorCount} text={invalidSizeFile} />
                    )}

                    {/*<Button className="mt-2">
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="mr-2 w-5 h-5">
		<path fillRule="evenodd" d="M11.47 2.47a.75.75 0 011.06 0l4.5 4.5a.75.75 0 01-1.06 1.06l-3.22-3.22V16.5a.75.75 0 01-1.5 0V4.81L8.03 8.03a.75.75 0 01-1.06-1.06l4.5-4.5zM3 15.75a.75.75 0 01.75.75v2.25a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V16.5a.75.75 0 011.5 0v2.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V16.5a.75.75 0 01.75-.75z" clipRule="evenodd" />
		</svg>
		Change picture 
		<input type="file"/>
		</Button>*/}
                  </div>
                </div>
              </div>

              <p className="text-gray-400 mt-2 font-light text-md">
                Add your photo. The recommended size is 160x160px
              </p>
            </div>

            <div className="grid grid-cols-3 gap-x-9 px-4 py-4 border-t">
              <TextInput
                defaultValue={user?.companyName ? user?.companyName : ""}
                label="Company Name"
                placeholder="Company Name"
                register={{ ...register("CompanyName") }}
                onChange={(e) =>
                  updateInputValue("CompanyName", e.target.value, e)
                }
              />

              <TextInput
                defaultValue={user?.firstName ? user?.firstName : ""}
                label="First Name"
                placeholder="First Name"
                register={{ ...register("FirstName") }}
                onChange={(e) => updateInputValue("FirstName", e.target.value)}
              />
              <TextInput
                defaultValue={user?.lastName ? user?.lastName : ""}
                label="Last Name"
                placeholder="Last Name"
                register={{ ...register("LastName") }}
                onChange={(e) => updateInputValue("LastName", e.target.value)}
              />
            </div>

            <div className="grid grid-cols-3 gap-x-9 px-4 mb-4">
              <TextInput
                defaultValue={user?.companyWebsite ? user?.companyWebsite : ""}
                label="Website"
                placeholder="http://www.xxxxx.com"
                register={{ ...register("CompanyWebsite") }}
                onChange={(e) =>
                  updateInputValue("CompanyWebsite", e.target.value)
                }
              />
              <TextInput
                defaultValue={user?.email ? user?.email : ""}
                label="Email"
                placeholder="Email"
                register={{ ...register("Email") }}
                onChange={(e) => updateInputValue("Email", e.target.value)}
              />
              <TextInput
                defaultValue={user?.phoneNumber ? user?.phoneNumber : ""}
                label="Phone number"
                placeholder="+1 408 XXX XXXX"
                register={{ ...register("PhoneNumber") }}
                onChange={(e) =>
                  updateInputValue("PhoneNumber", e.target.value)
                }
              />
            </div>

            {user?.userRole !== "Client" && (
              <div className="checkbox-grid">
                <LabelInput
                  className="px-4 mb-3 font-semibold"
                  label="Company expertise"
                />
                <div className="checkItems grid grid-cols-1 px-4">
                  <CheckboxInput
                    id="ghg"
                    name="ghg"
                    label="MRV (Carbon Footprint Calculation)"
                    value={1}
                    checked={companyExpertises?.includes(1)}
                    register={{ ...register("CompanyExpertise") }}
                    onChange={handleChangeCompanyExpertise}
                  />

                  <CheckboxInput
                    id="assurance"
                    name="assurance"
                    label="VVB (Verification Body)"
                    value={2}
                    checked={companyExpertises?.includes(2)}
                    register={{ ...register("CompanyExpertise") }}
                    onChange={handleChangeCompanyExpertise}
                  />

                  <CheckboxInput
                    id="accessProvider"
                    name="accessProvider"
                    label="Carbon Market Access Provider"
                    value={3}
                    checked={companyExpertises?.includes(3)}
                    register={{ ...register("CompanyExpertise") }}
                    onChange={handleChangeCompanyExpertise}
                  />

                  <CheckboxInput
                    id="other"
                    name="other"
                    label="Other"
                    value={4}
                    checked={companyExpertises?.includes(4)}
                    register={{ ...register("CompanyExpertise") }}
                    onChange={handleChangeCompanyExpertise}
                  />
                </div>
              </div>
            )}

            <div
              id="password-group"
              className="grid grid-cols-2 gap-x-6 px-4 mb-4"
            >
              <div className="mt-2">
                {updatePassword === false ? (
                  <>
                    <button
                      className="text-coc-blue hover:text-coc-blue hover:underline flex items-center mt-3 text-xs"
                      onClick={showEditPassword}
                    >
                      Edit password{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="m3 w-3 h-3 ml-2 text-coc-blue"
                      >
                        <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
                      </svg>
                    </button>
                  </>
                ) : (
                  <>
                    <div className="hidden">
                      <PasswordInput
                        label="Current Password"
                        name="currentPassword"
                      />
                    </div>
                    <div>
                      <PasswordInput
                        label="New Password"
                        name="newP"
                        id="newP"
                        placeholder="Enter new password"
                        register={{
                          ...register("Password", {
                            pattern: validPassword,
                          }),
                        }}
                        onKeyUp={() => setPasswordTypedKey(true)}
                        onChange={(e) =>
                          updateInputValue("Password", e.target.value, e)
                        }
                      />
                      {errors.Password &&
                        errors.Password.type === "pattern" && (
                          <Error
                            key={errorCount}
                            text={invalidPasswordMessage}
                          />
                        )}
                    </div>
                    <div className="my-3">
                      <PasswordInput
                        label="Confirm the password"
                        placeholder="Confirm the password"
                        name="confirmPassword"
                        register={{
                          ...register("ConfirmPassword", {
                            validate: (val) => val === passwordTyped,
                          }),
                        }}
                        onChange={(e) =>
                          updateInputValue("ConfirmPassword", e.target.value)
                        }
                      />
                      {errors.ConfirmPassword &&
                        errors.ConfirmPassword.type === "validate" && (
                          <Error
                            key={errorCount}
                            text={`${passwordsNotMatchMessage}, typedPassword ${passwordTyped} | confirmPassword ${
                              getValues().ConfirmPassword
                            }`}
                          />
                        )}
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="flex justify-end items-center gap-x-2 py-3 px-4 bg-gray-50 border-t">
              <button
                type="button"
                className="py-2.5 px-4 inline-flex justify-center bg-gray-400 items-center gap-2 rounded-md border font-medium text-white shadow-sm align-middle hover:bg-gray-300 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm"
                data-hs-overlay="#hs-user-details"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
              <SubmitButton
                className="py-2.5 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-medium bg-coc-green text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-coc-green focus:ring-offset-2 transition-all text-sm"
                type="submit"
                buttonText="Save changes"
                onClick={() => setErrorCount(errorCount + 1)}
                loading={isLoading}
              />
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
