import { userConstants } from '../constants/user.constants.js';

const initialState = {
    arrayCertification: [],
    allData: null
  }
export default function certificationStep(state = initialState, action) {
    switch (action.type) {
        case userConstants.GET_CERTIFICATION_STEPS_LIST_REQUEST:
            return action.payload
        case userConstants.GET_CERTIFICATION_INFO_SUCCESS:
            return {
                ...state, 
                allData: action.payload
            }
        case userConstants.GET_CERTIFICATION_STEPS_LIST_SUCCESS:
            return {
                ...state,
                arrayCertification: action.payload
            }
        case userConstants.GET_CCR_SUCCESS:
            return {
                ...state,
                ccr: action.payload
            }
        case userConstants.GET_CERTIFICATION_STEPS_LIST_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state
    }
}