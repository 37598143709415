import { useState } from "react";
import LabelInput from "./Label";
import { useEffect } from "react";

export default function TextInput(props) {
  const [inputValue, setInputValue] = useState(
    props.defaultValue || props.value || ""
  );

  useEffect(() => {
    if (props.defaultValue) {
      if (props.defaultValue !== "1970-01-01") {
        setInputValue(props.defaultValue);
      }
    } else if (props.value) {
      setInputValue(props.value);
    } else {
      setInputValue("");
    }
  }, [props.defaultValue, props.value]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
    if (props.onChange) {
      props.onChange(e);
    }
  };
  const [naChecked, setNaChecked] = useState(false);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }

    const keyValue = e.key;

    if (
      props.isNumberType &&
      !/^[0-9\s]+$/.test(keyValue) &&
      !["Backspace", "Delete"].includes(keyValue) &&
      keyValue !== "Tab"
    ) {
      e.preventDefault();
    }

    // Additional check for date input
    if (props.type === "date" && e.key === "Tab") {
      e.preventDefault();
      if (e.shiftKey) {
        // Si "Shift" est enfoncé, faites la navigation vers l'élément précédent
        const formElements = document.querySelectorAll(
          "input, select, textarea, button"
        );
        const currentIndex = Array.from(formElements).findIndex(
          (el) => el === e.target
        );
        const previousElement = formElements[currentIndex - 1];
        if (previousElement) {
          previousElement.focus();
        }
      } else {
        // Sinon, faites la navigation vers l'élément suivant (comportement par défaut)
        const formElements = document.querySelectorAll(
          "input, select, textarea, button"
        );
        const currentIndex = Array.from(formElements).findIndex(
          (el) => el === e.target
        );
        const nextElement = formElements[currentIndex + 1];
        if (nextElement) {
          nextElement.focus();
        }
      }
    }
  };

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    const value = checked ? "N/A" : "";
    setNaChecked(checked);
    props.onChange({ target: { value } });
  };

  return (
    <div className="input flex flex-col relative">
      <p className="font-bold text-left text-[#3D4196] text-lg mb-2">
        {props.title}
      </p>
      <LabelInput label={props.label} htmlFor={props.for} />
      <input
        className={`${
          props.className ||
          "p-0 border-b-2 border-[#dedede] border-x-0 border-t-0 focus:ring-0 w-full focus:border-b-[#3BB34B] focus:outline-none focus:border-b-2 h-[50px]"
        } ${naChecked ? "pr-16" : "pr-3"}`}
        name={props.name}
        id={props.id}
        type={props.type}
        placeholder={props.placeholder}
        {...props.register}
        onKeyDown={handleKeyDown}
        value={inputValue !== "1970-01-01" && inputValue}
        onChange={handleChange}
        autoComplete="nope"
        onClick={props.onClick}
        ref={props.ref}
        disabled={props.disabled}
        tabIndex={props.tabIndex}
      />
      {props.allowNA && (
        <label
          className={`absolute top-1/2 right-3 transform -translate-y-1/2 ${
            naChecked ? "text-[#3BB34B]" : "text-gray-500"
          } cursor-pointer`}
        >
          <input
            type="checkbox"
            checked={naChecked}
            onChange={handleCheckboxChange}
            className="hidden"
          />
          N/A
        </label>
      )}
    </div>
  );
}
