    const certificationFormDataReducer = (state = {}, action) => {
    switch (action.type) {
      case "SET_FORM_DATA":
        return {
          ...state,
          formData: action.payload,
        };
    case "CLEAR_FORM_DATA":
        return {
            ...state,
            formData: null
        };
      default:
        return state;
    }
  };
  
  export default certificationFormDataReducer;