import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { userActions } from "../../actions";
import { useNavigate } from "react-router-dom";

export default function ResetPassword() {
  const [data, setData] = useState({
    email: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (data) {
      dispatch(userActions.sendResetLink(data.email));
      navigate("/confirmation");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="container mx-auto p-4 mt-9 flex">
      <form onSubmit={handleSubmit} className="max-w-md mx-auto mt-12">
        <div className="mb-4">
          <label
            htmlFor="email"
            className="bottom-[1px] relative text-sm font-semibold "
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            onChange={handleChange}
            className="border-b-2 border-[#dedede] border-x-0 border-t-0 focus:ring-0 w-full focus:border-b-[#3BB34B] focus:outline-none focus:border-b-2 h-[50px] pr-14 pl-0"
            placeholder="Enter your email"
            required
          />
        </div>
        <button
          type="submit"
          className="mt-9 login flex justify-center items-center text-md rounded-lg capitalize border-0 bg-[#3BB34B] hover:bg-[#329940] text-white w-full py-3"
        >
          Send Reset Link
        </button>
      </form>
    </div>
  );
}
