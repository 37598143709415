import { userConstants } from '../constants/user.constants.js';

const initialState = {
    data: null
  }
export default function updateCreditRetirements(state = initialState, action) {
    switch (action.type) {
        case userConstants.UPDATE_CCR_SUCCESS:
            return {
                ...state,
                data: action.payload
            }
            
        case userConstants.UPDATE_CCR_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        case userConstants.DELETE_CCR_UPDATED:
            return state
        default:
            return state
    }
}