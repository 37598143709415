import { useCallback, useEffect, useState } from "react";
import "./Dashboard.css";
import { certificationActions } from "../../actions/certification.action";
import { useDispatch, useSelector } from "react-redux";
import { certificationService } from "../../services/certification.service";
import { sortData, sortDataByStatus } from "../../utils/utils";
import SearchBar from "../../components/forms/Searchbar";
import DashboardClient from "./DashboardClient";
import DashboardCOC from "./DashboardCOC";
import DashboardVVB from "./DashboardVVB";
import {
  getCertificationStatusStringValue,
  getCertificationTypeStringValue,
} from "../../utils/enum";

export default function Dashboard({ isAdmin }) {
  const [certificationList, setCertificationList] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const user = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const certifications = useSelector(
    (state) => state.certification.arrayCertification
  );
  const acceptNomination = useSelector((state) => state.vvb.data_on_accept);
  const rejectNomination = useSelector((state) => state.vvb.data_on_refuse);

  const applicationFormSubmitted = useSelector(
    (state) => state.createCommodity.data
  );

  const [sortOrder, setSortOrder] = useState("asc");
  const [sortKey, setSortKey] = useState("certificationCode");
  const [searchValue, setSearchValue] = useState();

  const handleSort = useCallback(
    (key) => {
      setCertificationList((prevList) => {
        let sortedData;
        if (key === "certificationStatus") {
          sortedData = sortDataByStatus([...prevList]);
        } else {
          sortedData = sortData([...prevList], key);
        }
        const newSortOrder = sortOrder === "asc" ? "desc" : "asc"; // Inversion de l'ordre de tri
        setSortKey(key);
        setSortOrder(newSortOrder);
        localStorage.setItem("sortedKey", key);
        return newSortOrder === "asc" ? sortedData : sortedData.reverse();
      });
    },
    [sortOrder]
  );

  useEffect(() => {
    dispatch({ type: "CLEAR_RETIREMENT_DATA" });
  }, [dispatch]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (searchValue === "") {
      setCertificationList(sortData(certifications, sortKey, true));
    } else {
      const certificationFiltered = certifications?.filter(
        (cert) =>
          ((user?.data.userRole === "COC" || user?.data.userRole === "ADMIN") &&
            cert?.firstName
              ?.toLowerCase()
              .includes(searchValue?.toLowerCase())) ||
          ((user?.data.userRole === "COC" || user?.data.userRole === "ADMIN") &&
            cert?.lastName
              ?.toLowerCase()
              .includes(searchValue?.toLowerCase())) ||
          cert?.certificationCode
            ?.toLowerCase()
            .includes(searchValue?.toLowerCase()) ||
          cert?.object?.toLowerCase().includes(searchValue?.toLowerCase()) ||
          cert?.description
            ?.toLowerCase()
            .includes(searchValue?.toLowerCase()) ||
          (user?.data.userRole !== "COC" &&
            cert?.phone?.includes(searchValue)) ||
          (user?.data.userRole !== "COC" &&
            cert?.email?.toLowerCase().includes(searchValue?.toLowerCase())) ||
          cert?.issuanceDate?.includes(searchValue) ||
          cert?.dateOfLastAction?.includes(searchValue) ||
          ((user?.data.userRole === "COC" || user?.data.userRole === "ADMIN") &&
            cert?.carbonFootPrint?.includes(searchValue)) ||
          cert?.companyName
            ?.toLowerCase()
            .includes(searchValue?.toLowerCase()) ||
          (cert?.certificationType &&
            getCertificationTypeStringValue(cert?.certificationType)
              ?.toLowerCase()
              ?.includes(searchValue?.toLowerCase())) ||
          (cert?.certificationStatus &&
            getCertificationStatusStringValue(cert?.certificationStatus)
              ?.toLowerCase()
              ?.includes(searchValue?.toLowerCase())) ||
          (cert?.estimatedFirstTransDate &&
            cert?.estimatedFirstTransDate
              ?.toLowerCase()
              ?.includes(searchValue?.toLowerCase())) ||
          (cert?.issuanceDate &&
            cert?.issuanceDate
              ?.toLowerCase()
              ?.includes(searchValue?.toLowerCase())) ||
          (cert?.dateOfLastAction &&
            cert?.dateOfLastAction
              ?.toLowerCase()
              ?.includes(searchValue?.toLowerCase()))
      );
      setCertificationList(certificationFiltered);
    }
  }, [searchValue, certifications, sortKey, user?.data.userRole]);

  useEffect(() => {
    if (
      user?.data.userRole === "COC" ||
      user?.data.userRole === "VAP" ||
      isAdmin
    ) {
      setIsLoadingData(true);
      dispatch(certificationActions.getCertificationList());
    } else if (user?.data.userRole !== "COC" || applicationFormSubmitted) {
      setIsLoadingData(true);
      dispatch(certificationActions.getCertificationByUserId(user?.data.id));
    }
  }, [
    dispatch,
    user?.data.userRole,
    user?.data.id,
    applicationFormSubmitted,
    isAdmin,
  ]);

  useEffect(() => {
    if (acceptNomination || rejectNomination) {
      dispatch(certificationActions.getCertificationList());
    }
  }, [acceptNomination, rejectNomination, dispatch]);

  useEffect(() => {
    if (certifications) {
      setIsLoadingData(false);
      setCertificationList(sortData(certifications, sortKey, true));
    }
  }, [certifications, sortKey, user?.data.userRole, user?.data.id]);

  const handleDownloadCertificate = async (certificationId) => {
    const response = await certificationService.getAllCertificationStepByCID(
      certificationId
    );
    if (response.certificationSteps) {
      const latestStep = response.certificationSteps.filter(
        (cert) => cert.processStep === 4
      );
      dispatch(
        certificationActions.downloadCertification(
          latestStep[0]?.attachments[0]?.id
        )
      );
    }
  };

  const onSearch = (e) => {
    setSearchValue(e);
  };

  const dashboard =
    user?.data.userRole === "COC" || isAdmin ? (
      isAdmin ? (
        <DashboardCOC
          certificationList={certificationList}
          handleSort={handleSort}
          handleDownloadCertificate={handleDownloadCertificate}
          sortOrder={sortOrder}
          sortKey={sortKey}
          isAdmin={true}
          isLoadingData={isLoadingData}
        />
      ) : (
        <DashboardCOC
          certificationList={certificationList}
          handleSort={handleSort}
          handleDownloadCertificate={handleDownloadCertificate}
          sortOrder={sortOrder}
          sortKey={sortKey}
          isLoadingData={isLoadingData}
        />
      )
    ) : user?.data.userRole === "VAP" ? (
      <DashboardVVB
        certificationList={certificationList}
        handleSort={handleSort}
        handleDownloadCertificate={handleDownloadCertificate}
        sortOrder={sortOrder}
        sortKey={sortKey}
        isLoadingData={isLoadingData}
      />
    ) : (
      <DashboardClient
        certificationList={certificationList}
        handleSort={handleSort}
        handleDownloadCertificate={handleDownloadCertificate}
        sortOrder={sortOrder}
        sortKey={sortKey}
        isLoadingData={isLoadingData}
      />
    );

  return (
    <>
      <div className="dashboard w-full px-4">
        <div className="container mx-auto px-4">
          <SearchBar onSearch={onSearch} />
        </div>

        <div className="mt-4">{dashboard}</div>
      </div>
    </>
  );
}
