import "../Form.css";
import { useState } from "react";
import SecondStep from "../CommonStep/SecondStep";
import ThirdStep from "../CommonStep/ThirdStep";
import FirstStep from "../CommonStep/FirstStep";
import FourthStep from "../CommonStep/FourthStep";
import FifthStep from "../CommonStep/FifthStep";
import { useDispatch } from "react-redux";
import { PreviousButton } from "../../../components/forms/Buttons/PreviousButton";
import { NextButton } from "../../../components/forms/Buttons/NextButton";
import { useForm } from "react-hook-form";
import { fieldTypeOfDate, fieldTypeOfNumber } from "../../../utils/constant";
import StepCompleted from "../CommonStep/StepCompleted";
import { demandcertificationService } from "../../../services/demandcertification.service";
import Error from "../../../components/Alert/Error";
import { useNavigate } from "react-router-dom";

export default function ProductForm(props) {
  const { formData, setFormData } = props;
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  const items = ["1", "2", "3", "4", "5"];
  const [title, nextTitle] = useState(1);
  const [showing, setShowing] = useState(0);
  const [errorCount, setErrorCount] = useState(0);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    unregister,
    getValues,
  } = useForm();

  function showStep() {
    switch (showing) {
      case 0:
        return (
          <FirstStep
            formData={formData}
            setFormData={setFormData}
            errorCount={errorCount}
            errors={errors}
            register={register}
            setValue={setValue}
            unregister={unregister}
            getValues={getValues}
          />
        );
      case 1:
        return (
          <SecondStep
            formData={formData}
            setFormData={setFormData}
            errorCount={errorCount}
            errors={errors}
            register={register}
            setValue={setValue}
            unregister={unregister}
          />
        );
      case 2:
        return (
          <ThirdStep
            formData={formData}
            setFormData={setFormData}
            errorCount={errorCount}
            errors={errors}
            register={register}
            setValue={setValue}
            unregister={unregister}
          />
        );
      case 3:
        return (
          <FourthStep
            formData={formData}
            setFormData={setFormData}
            errorCount={errorCount}
            errors={errors}
            register={register}
            setValue={setValue}
          />
        );
      case 4:
        return <FifthStep formData={formData} />;
      case 5:
        return <StepCompleted />;
      default:
        return null;
    }
  }

  function showSubtitle() {
    switch (step) {
      case 1:
        return <span>Transaction(s) to be certified</span>;
      case 2:
        return <span>Carbon footprint calculation</span>;
      case 3:
        return <span>Assurance provider</span>;
      case 4:
        return <span>Carbon offset</span>;
      case 5:
        return <span>Recapitulation</span>;
      default:
        return null;
    }
  }
  // const createCommodity = () => {
  //   return { type: certificationConstants.CREATE_COMMODITY };
  // };
  // useEffect(() => {
  //   dispatch(createCommodity());
  // }, [dispatch]);
  const handleSubmitData = (data) => {
    data.estimatedFirstTransDate =
      data.estimatedFirstTransDate !== null
        ? new Date(data.estimatedFirstTransDate)
        : new Date("1970-01-01");
    data.shippingDate =
      data.shippingDate !== null
        ? new Date(data.shippingDate)
        : new Date("1970-01-01");
    // Set null fields to false
    const updatedFormData = { ...data };
    for (const field in updatedFormData) {
      if (
        updatedFormData[field] === null &&
        !fieldTypeOfNumber.includes(field) &&
        !fieldTypeOfDate.includes(field)
      ) {
        updatedFormData[field] = false;
      }

      if (
        (fieldTypeOfNumber.includes(field) &&
          updatedFormData[field] === "N/A") ||
        (fieldTypeOfNumber.includes(field) &&
          updatedFormData[field] === null) ||
        (fieldTypeOfNumber.includes(field) && updatedFormData[field] === "")
      ) {
        updatedFormData[field] = 0;
      }
    }

    // Handle form submission with updatedFormData
    setLoading(true);
    demandcertificationService
      .createCommodity(updatedFormData)
      .then((res) => {
        if (res.id) {
          setLoading(false);
          navigate("/dashboard");
          dispatch({ type: "CLEAR_FORM_DATA" });
        } else {
          setLoading(false);
          setError("an error occured.");
        }
      })
      .catch((error) => {
        setLoading(false);
        setError("an error occured.");
      });
    //navigate("/dashboard");
  };

  const handleNextButton = (data) => {
    step < items.length && setStep((prev) => prev + 1);
    if (step < 5 && title > 0) {
      nextTitle((prev) => prev + 1);
    }
    if (step !== 5) {
      setShowing((prev) => prev + 1);
    }
    if (step === 5) {
      handleSubmitData(formData);
    }
  };

  const resetFieldOnStep = () => {
    const values = getValues();
    let fieldsToUnregister = [];
    if (step === 2) {
      fieldsToUnregister = [
        "nameOfServiceProvideCFC",
        "contactAssuranceProvider",
        "emailOfServiceProvider",
      ];
    }
    if (step === 3) {
      fieldsToUnregister = [
        "anotherNameOfAssuranceProvider",
        "anotherContactOfAssuranceProvider",
        "anotherEmailOfAssuranceProvider",
      ];
    }
    // Désenregistrer les champs
    for (const field of fieldsToUnregister) {
      if (!values[field]) {
        unregister(field);
      }
    }
  };

  const handlePreviousButton = () => {
    resetFieldOnStep();
    setShowing((prev) => prev - 1);
    !(title === items.length && title > 1 && step < 5) &&
      setStep((prev) => prev - 1);
    if (step > 0) {
      nextTitle((prev) => prev - 1);
    }
  };

  const onSubmit = (data) => {
    if (Object.keys(errors).length === 0) {
      handleNextButton();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mx-12 commodity-container">
        {/*Title and header bar*/}
        <h1 className="font-bold text-2xl text-coc-blue text-center">
          Application Form : Carbon Offset Product
        </h1>

        <div className="ml-4 mt-9">
          <h2 className="font-bold text-2xl relative before:block before:absolute before:w-2 before:h-2 before:rounded-full before:bg-amber-400 before:top-3 before:-left-5">
            Step {step === 6 ? 5 : step}/5
          </h2>
          <p className="text-grey text-md">{showSubtitle()}</p>
        </div>

        <hr className="bg-light-grey border-dashed w-full mt-4 h-[2px] border-none" />
        {/*Stepper*/}
        <div className="flex justify-between">
          <div className="stepper">
            <div className="form-steps relative">
              {items.map((item, i) => (
                <p
                  key={i}
                  className={`item z-10 ${step === i + 1 && "active"} ${
                    i + 1 < step && "complete"
                  }`}
                >
                  {item}
                </p>
              ))}
              <div className="line absolute w-[2px] h-full bg-light-grey left-5 -top-5 z-0"></div>
            </div>
          </div>
          {showStep()}
        </div>
        <div className="flex justify-between ml-32 text-white text-xs items-center mb-4">
          {/*Previous button*/}
          {step !== 1 && step <= 5 && (
            <PreviousButton type={`button`} onClick={handlePreviousButton} />
          )}
          {/*Next button*/}
          {step <= 5 && (
            <NextButton
              type={`submit`}
              onClick={() => setErrorCount(errorCount + 1)}
              step={step}
              loading={loading}
            />
          )}
        </div>
        {error && (
          <div className="ml-5 text-center">
            <Error key={errorCount} text={error} />
          </div>
        )}
      </div>
    </form>
  );
}
