import axios from 'axios';

export const GET_POSTS = "GET_POSTS";
export const GET_ALL_POSTS = "GET_ALL_POSTS";
export const ADD_POST = "ADD_POST";
export const LIKE_POST = "LIKE_POST";
export const UNLIKE_POST = "UNLIKE_POST";
export const UPDATE_POST = "UPDATE_POST";
export const DELETE_POST = "DELETE_POST";
export const GET_USER_ERRORS = "GET_USER_ERRORS";


// trends
export const GET_TRENDS = "GET_TRENDS";

// errors
export const GET_POST_ERRORS = "GET_POST_ERRORS";
export const getPosts = (num) => {
    return (dispatch) => {
        return axios
            .get(`${import.meta.env.REACT_APP_API_URL}api/post/`)
            .then((res) => {
                const postArray = res.data.slice(0, num);
                dispatch({type: GET_POSTS, payload: postArray});
            })
            .catch((err) => console.log(err));
    }
}
export const addCocUser = (data) => {
    return (dispatch) => {
        return axios
            .post(`${import.meta.env.REACT_APP_API_URL}api/users/register`, data)
            .then((res) => {
                if (res.data.errors) {
                    dispatch({ type: GET_POST_ERRORS, payload: res.data.errors });
                } else {
                    dispatch({ type: GET_POST_ERRORS, payload: "" });
                }
            });
    };
};