import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { certificationActions } from "../../../actions/certification.action";
import UploadFiles from "../../../components/certification-process/UploadFiles";
import Lottie from "lottie-react";
import checkedAnimation from "../../DemandForm/checked.json";
import UserActionButtons from "../../../components/certification-process/UserActionButtons";

export default function FootPrintCalculation({ certificationId }) {
  const certificationSteps = useSelector(
    (state) => state.certificationStep.arrayCertification
  );
  const user = JSON.parse(localStorage.getItem("user"));
  const [certificationStep, setCertificationStep] = useState();
  const dispatch = useDispatch();
  const isFileRemoved = useSelector((state) => state.removeFile.removeSuccess);
  const isFileUploaded = useSelector((state) => state.uploadCertification.file);
  const validateStep = useSelector((state) => state.validateStep.data);
  const rejectStep = useSelector((state) => state.rejectStep.data);
  const sentToCocFiles = useSelector((state) => state.sendCocFiles.data);
  const currentStep = 1;

  useEffect(() => {
    if (
      sentToCocFiles?.certificationId !== null &&
      sentToCocFiles?.certificationId === certificationId
    ) {
      dispatch(certificationActions.getCertificationStepByCID(certificationId));
    }
  }, [sentToCocFiles, certificationId, dispatch]);

  useEffect(() => {
    if (isFileRemoved || isFileUploaded || validateStep || rejectStep) {
      dispatch(certificationActions.getCertificationStepByCID(certificationId));
    }
  }, [
    isFileRemoved,
    dispatch,
    certificationId,
    isFileUploaded,
    validateStep,
    rejectStep,
  ]);

  useEffect(() => {
    if (certificationSteps.length > 0) {
      const footprintCalculationStep = certificationSteps.filter(
        (certif) => certif.processStep === currentStep
      );
      if (footprintCalculationStep.length > 0) {
        setCertificationStep(footprintCalculationStep[0]);
      } else {
        setCertificationStep(certificationSteps[0]);
      }
    }
  }, [
    certificationSteps,
    currentStep,
    user?.data.userRole,
    certificationStep?.isSentToCOC,
  ]);

  return (
    <div className="mx-auto mt-4 shadow-lg rounded-2xl pt-4">
      <h1 className="uppercase font-semibold text-lg text-center">
        Carbon Footprint calculation
      </h1>
      <div className="flex justify-between pb-4">
        <div className="w-1/3 mx-4 pt-4">
          <p>
            Please upload carbon footprint calculation justification for COC
            review and sharing to your Verification Body.
          </p>
        </div>
        <div className="mx-auto w-1/3 relative mt-4">
          <UploadFiles
            title={`Applicant`}
            filesUploaded={[]}
            certificationId={certificationId}
            currentStep={1}
            roles={"Applicant"}
          />
        </div>
        <div className="w-1/3 relative mt-4 mx-3">
          <UploadFiles
            title={`COC`}
            filesUploaded={[]}
            certificationId={certificationId}
            currentStep={1}
            roles={"COC"}
          />
        </div>
        <div className="mx-auto w-1/3">
          <div className="flex flex-col items-center justify-center">
            <div
              className="flex flex-col items-center justify-center"
              hidden={
                !certificationStep?.isValidated ||
                certificationStep?.processStep !== 1
              }
            >
              <Lottie
                /*loop={1}*/ style={{ width: "200px", height: "160px" }}
                animationData={checkedAnimation}
              />
            </div>
            <div
              className="flex flex-col items-center justify-center"
              hidden={
                !(
                  (certificationStep?.isSentToCOC &&
                    !certificationStep?.isValidated &&
                    user?.data?.userRole !== "COC") ||
                  (user?.data.userRole === "COC" &&
                    !certificationStep?.isSentToCOC &&
                    !certificationStep?.isValidated)
                )
              }
            >
              {user?.data.userRole === "Client" && (
                <>
                  <div className="py-4">
                    <img
                      width={`150px`}
                      height={`150px`}
                      src="/assets/images/clock-pending-time.svg"
                      alt="pending"
                    />
                  </div>

                  <p className="text-[#f2af29] font-medium">
                    Your carbon footprint calculation has been communicated to
                    COC and the Verification Body for verification
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <UserActionButtons
          certificationId={certificationId}
          currentStep={currentStep}
          buttonText={`Send to COC and Verification Body`}
        />
      </div>
    </div>
  );
}
