import LabelInput from "./Label";

export default function PhoneInput(props) {
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
    const keyValue = e.key;

    // Vérifier si la valeur saisie est un chiffre (0-9) ou une touche de suppression (Backspace ou Delete)
    if (
      !/^[0-9\s]+$/.test(keyValue) &&
      !["Backspace", "Delete"].includes(keyValue) &&
      keyValue !== "Tab"
    ) {
      e.preventDefault();
    }
  };

  return (
    <div className="input flex flex-col">
      <LabelInput label={props.label || "Direct Phone Number"} />
      {/* <label className="text-[13px] text-[#232323] font-[600] capitalize" htmlFor="phone">Direct phone number</label> */}
      <input
        className="p-0 border-b-2 border-[#dedede] border-x-0 border-t-0 focus:ring-0 w-full focus:border-b-[#3BB34B] focus:outline-none focus:border-b-2 h-[50px]"
        type="tel"
        id="phone"
        placeholder="0012 345 678910 "
        name="phoneNumber"
        {...props.register}
        onChange={props.onChange}
        onKeyDown={handleKeyDown}
        autoComplete="nope"
        tabIndex={props.tabIndex}
      />
      {props.errors}
    </div>
  );
}
