import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { certificationActions } from "../../actions/certification.action";

export default function DownloadFile({ file }) {
  const dispatch = useDispatch();

  const handleDownload = (fileId) => {
    dispatch(certificationActions.downloadCertification(fileId));
  };

  return (
    <>
      <Link to="#" onClick={() => handleDownload(file.id)}>
        {file?.name ? file?.name : file?.fileName}
      </Link>
    </>
  );
}
