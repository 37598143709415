import { useCallback, useEffect, useState } from "react";
import LabelInput from "./Label";
import { formatDate } from "../../utils/utils";
import Error from "../Alert/Error";

export default function TimePeriodInput(props) {
  const [naChecked, setNaChecked] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [timePeriodError, setTimePeriodError] = useState();
  const [hasChanged, setHasChanged] = useState(false);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }

    if (e.key === "Tab") {
      e.preventDefault();

      const formElements = document.querySelectorAll(
        "input, select, textarea, button"
      );
      const currentIndex = Array.from(formElements).findIndex(
        (el) => el === e.target
      );
      const nextElement = formElements[currentIndex + 1];
      if (nextElement) {
        nextElement.focus();
      }
    }
  };

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    const value = checked ? "N/A" : "";
    setNaChecked(checked);
    props.onChange({ target: { value } });
    setHasChanged(true);
  };

  const getFromDate = useCallback(
    (fullValue) => {
      if (fullValue.split(" to ")[0] !== "1970-01-01") {
        return fromDate ? fromDate : formatDate(fullValue.split(" to ")[0]);
      }
    },
    [fromDate]
  );

  const getToDate = useCallback(
    (fullValue) => {
      if (fullValue.split(" to ")[1] !== "1970-01-01") {
        return toDate ? toDate : formatDate(fullValue.split(" to ")[1]);
      }
    },
    [toDate]
  );

  const handleChangePeriod = useCallback(() => {
    if (
      fromDate === undefined ||
      fromDate === "" ||
      toDate === undefined ||
      toDate === ""
    ) {
      props.setTimePeriodValue(props.fieldName, "undefined");
    }
    if (props.defaultValue) {
      if (!toDate) setToDate(getToDate(props.defaultValue));
      if (!fromDate) setFromDate(getFromDate(props.defaultValue));
    }
    if (fromDate && toDate) {
      if (new Date(fromDate) > new Date(toDate)) {
        setTimePeriodError(
          "The end date should not be more recent than the start date"
        );
      } else {
        const periodValue = `${fromDate} to ${toDate}`;
        props.setTimePeriodValue(props.fieldName, periodValue);
      }
    }
    setHasChanged(false);
  }, [fromDate, toDate, props, getToDate, getFromDate]);

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
    setHasChanged(true); // Marquez que quelque chose a changé
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
    setHasChanged(true); // Marquez que quelque chose a changé
  };

  useEffect(() => {
    if (hasChanged) {
      handleChangePeriod();
      setHasChanged(false); // Réinitialise le drapeau après avoir géré le changement
    }
  }, [hasChanged, handleChangePeriod, fromDate, toDate]);

  return (
    <div className="input flex flex-col relative">
      <p className="font-bold text-left text-[#3D4196] text-lg mb-2">
        {props.title}
      </p>
      <LabelInput label={props.label} htmlFor={props.for} />
      <div className="flex">
        <p className="py-3 mr-3">From: </p>
        <input
          className={`p-0 border-b-2 border-[#dedede] border-x-0 border-t-0 focus:ring-0 w-full focus:border-b-[#3BB34B] focus:outline-none focus:border-b-2 h-[50px] ${
            naChecked ? "pr-16" : "pr-3"
          }`}
          name="fromDate"
          id="fromDate"
          type="date"
          placeholder={props.placeholder}
          {...props.register}
          onChange={handleFromDateChange}
          onKeyDown={handleKeyDown}
          defaultValue={
            naChecked
              ? "N/A"
              : props.defaultValue &&
                props.defaultValue !== "1970-01-01 to 1970-01-01"
              ? getFromDate(props.defaultValue)
              : fromDate
          }
          autoComplete="nope"
          onClick={props.onClick}
          ref={props.ref}
          disabled={props.disabled}
        />
        <p className="py-3 ml-3 mr-3">to: </p>
        <input
          className={`p-0 border-b-2 border-[#dedede] border-x-0 border-t-0 focus:ring-0 w-full focus:border-b-[#3BB34B] focus:outline-none focus:border-b-2 h-[50px] ${
            naChecked ? "pr-16" : "pr-3"
          }`}
          name="toDate"
          id="toDate"
          type="date"
          placeholder={props.placeholder}
          {...props.register}
          onChange={handleToDateChange}
          onKeyDown={handleKeyDown}
          defaultValue={
            naChecked
              ? "N/A"
              : props.defaultValue &&
                props.defaultValue !== "1970-01-01 to 1970-01-01"
              ? getToDate(props.defaultValue)
              : toDate
          }
          autoComplete="nope"
          onClick={props.onClick}
          ref={props.ref}
          disabled={props.disabled}
        />
      </div>
      {timePeriodError && <Error text={timePeriodError} />}
      {props.allowNA && (
        <label
          className={`absolute top-1/2 right-3 transform -translate-y-1/2 ${
            naChecked ? "text-[#3BB34B]" : "text-gray-500"
          } cursor-pointer`}
        >
          <input
            type="checkbox"
            checked={naChecked}
            onChange={handleCheckboxChange}
            className="hidden"
          />
          N/A
        </label>
      )}
    </div>
  );
}
