export default function UploadButton({ children, ...props }) {
  return (
    <div className="hover:cusor-pointer">
      <input
        className="py-3 mx-4 flex bg-[#dc2626] items-center justify-center hidden"
        id={props.id}
        type="file"
        hidden
        register={props.register}
        onChange={props.onChange}
      />
      <label
        className="text-xs bg-coc-blue rounded-md px-4 py-3 text-white flex items-center justify-center hover:cusor-pointer"
        htmlFor={props.id}
      >
        {children} {props.label}
      </label>
    </div>
  );
}
