import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { certificationActions } from "../../../actions/certification.action";
import Lottie from "lottie-react";
import pendingAnimation from "./pending.json";
import checkedAnimation from "../../DemandForm/checked.json";
import UploadCertification from "../../../components/certification-process/UploadCertification";
import Error from "../../../components/Alert/Error";
import SubmitButton from "../../../components/forms/Buttons/SubmitButton";
import TextInput from "../../../components/forms/TextInput";

export default function CNCCertification({ certificationId }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const certificationSteps = useSelector(
    (state) => state.certificationStep.arrayCertification
  );

  const [certificationStep, setCertificationStep] = useState();
  const [idCertification, setIdCertification] = useState(null);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isFileRemoved = useSelector((state) => state.removeFile.removeSuccess);
  const isFileUploaded = useSelector((state) => state.uploadCertification.file);
  const validateStep = useSelector((state) => state.validateStep.data);

  useEffect(() => {
    if (isFileRemoved || isFileUploaded || validateStep) {
      if (validateStep && validateStep.certificationId === certificationId) {
        setLoading(false);
      }
      dispatch(certificationActions.getCertificationStepByCID(certificationId));
    }
  }, [isFileRemoved, dispatch, certificationId, isFileUploaded, validateStep]);

  useEffect(() => {
    if (certificationSteps.length > 0) {
      const footprintVerificationStep = certificationSteps.filter(
        (certif) => certif.processStep === 4
      );
      if (footprintVerificationStep.length > 0) {
        setCertificationStep(footprintVerificationStep[0]);
      } else {
        setCertificationStep(certificationSteps[0]);
      }
    }
  }, [certificationSteps]);

  const handleValidateStep = () => {
    if (idCertification) {
      setLoading(true);
      dispatch(
        certificationActions.validateStep({
          stepId: certificationStep.attachments[0].certificationStepId,
          certificationId: certificationStep.attachments[0].certificationId,
          step: 4,
          IdCertification: idCertification,
        })
      );
    } else {
      setShowError(true);
    }
  };

  const handleChangeIdCertification = (e) => {
    setShowError(false);
    setIdCertification(e.target.value);
  };

  return (
    <div className="flex flex-col justify-center shadow-lg mt-4 h-auto pb-12 rounded-2xl">
      <h1 className="uppercase text-center font-semibold text-lg mt-4 ">
        COC Certification
      </h1>

      <div className="flex flex-col justify-between ">
        {user?.data.userRole === "COC" && (
          <div className="w-1/3 mx-4">
            <p>
              COC user, your certificate delivery is now expected by your client
            </p>
          </div>
        )}

        <div className="mx-auto w-1/3 relative">
          {user?.data.userRole === "COC" ? (
            <>
              <div className="w-full mx-auto mb-3">
                {/*<TextInput title="Total Carbon" onChange={(e)=>setTotalCredit(e.target.value)}/> tCO2*/}
                <div>
                  <TextInput
                    placeholder="Please enter the Certificate ID"
                    onChange={handleChangeIdCertification}
                    className="pl-0 w-full bg-white mb-2 border-x-0 border-t-0 border-b-2 focus:outline-none focus:ring-0"
                    type="text"
                    name=""
                    id=""
                  />
                </div>

                {showError && (
                  <Error text="Please enter the certificate ID before validation." />
                )}
              </div>
              <UploadCertification
                //filesUploaded={files}
                filesUploaded={[]}
                certificationId={certificationId}
                currentStep={4}
              />
            </>
          ) : (
            <>
              <div
                className="flex flex-col items-center justify-center"
                hidden={
                  !certificationStep?.isValidated ||
                  certificationStep?.processStep !== 4
                }
              >
                <Lottie
                  loop={1}
                  style={{ width: "150px", height: "150px" }}
                  animationData={checkedAnimation}
                />
              </div>
              <div
                className="flex flex-col items-center justify-center"
                hidden={
                  (certificationStep?.processStep === 4) &
                  certificationStep?.isValidated
                }
              >
                <Lottie
                  loop={1}
                  style={{ width: "150px", height: "150px" }}
                  animationData={pendingAnimation}
                />
                <p className="text-[#f2af29] font-medium">
                  Dear user, your request is being processed
                </p>
              </div>
            </>
          )}
        </div>
        {user?.data.userRole === "COC" &&
          certificationStep?.processStep === 4 &&
          certificationStep?.attachments.length > 0 && (
            <div>
              <div
                className={`${
                  (certificationStep?.isValidated ||
                    user?.data.userRole !== "COC") &&
                  "hidden"
                }`}
              >
                <SubmitButton
                  disabled={certificationStep?.isValidated}
                  className={`px-12 py-3 bg-coc-green mx-auto mt-12 text-white flex inline-block text-center text-sm items-center hover:bg-[#329940] rounded-lg w-[200px] justify-center`}
                  onClick={handleValidateStep}
                  text={`Validate`}
                  loading={loading}
                />
              </div>
            </div>
          )}
      </div>
    </div>
  );
}
