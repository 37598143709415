import handleResponse from "../utils/handleresponse";
import authHeader from "../utils/auth-header";

export const demandcertificationService = {
  createCommodity,
};

function createCommodity(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/certificate/create`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}
