export default function RadioInput(props) {
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const isChecked = props.checked === null ? null : props.checked;

  return (
    <div className="choice mb-5 flex items-center">
      <input
        className="float-left text-[#3BB34B] focus:ring-0 hover:cursor-pointer mr-2"
        id={props.id}
        name={props.name}
        type="radio"
        {...props.register}
        value={props.value}
        onChange={props.onChange}
        checked={isChecked}
        onKeyDown={handleKeyDown}
      />
      <label
        className={
          isChecked
            ? "font-semibold relative text-sm focus:text-blue"
            : "relative text-sm"
        }
        htmlFor={props.htmlFor}
      >
        {props.label}
      </label>
    </div>
  );
}
