import { useState } from "react";
import { formatLabel } from "../../utils/utils";

export default function Error({ text }) {
  const [closed, setClosed] = useState(false);

  return (
    <div
      className="bg-white max-w-[300px] shadow-custom text-red-900 px-4 py-3 rounded relative mt-2"
      role="alert"
      hidden={closed}
    >
      <span className="absolute top-0 bottom-0 left-0 ml-4 py-3">
        <img className=" h-5 w-5" src="/assets/icons/error.svg" alt="" />
      </span>
      <span className="block sm:inline text-sm ml-9">{formatLabel(text)}</span>
      <span className="absolute top-2 right-2 hover:cursor-pointer text-grey">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="w-4 h-4"
          onClick={() => setClosed(true)}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </span>
    </div>
  );
}
