import {DELETE_POST, GET_POSTS, UPDATE_POST} from "../actions/users.actions.js";

const initialState = {};

export default function userCocReducer(state = initialState, action){
    switch (action.type) {
        case GET_POSTS:
            return action.payload

        case DELETE_POST:
            return state.filter((post) => post._id !== action.payload.postId);

        case UPDATE_POST:
            return state.map((post) => {
                if(post._id === action.payload.postId){
                    return {
                        ...post,
                        message: action.payload.message,
                    }
                }else return post;
            })
        default:
            return state;
    }
}
