import { userConstants } from "../constants/user.constants.js";

const initialState = {
  data: {}
};
export default function vvb(state = initialState, action) {
  switch (action.type) {
    case userConstants.ASSIGN_VVB_SUCCESS:
      return {
        ...state,
        data_on_nominate: action.payload,
      };

    case userConstants.ASSIGN_VVB_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case userConstants.ACCEPT_NOMINATION_VVB_SUCCESS:
      return {
        ...state,
        data_on_accept: action.payload,
      };

    case userConstants.ACCEPT_NOMINATION_VVB_FAILURE:
      return {
        ...state,
        error_on_accept: action.payload,
      };

    case userConstants.REFUSE_NOMINATION_VVB_SUCCESS:
      return {
        ...state,
        data_on_refuse: action.payload,
      };

    case userConstants.REFUSE_NOMINATION_VVB_FAILURE:
      return {
        ...state,
        error_on_refuse: action.payload,
      };
    default:
      return state;
  }
}
