import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { certificationActions } from "../../../actions/certification.action";
import { useEffect, useState } from "react";
import { formatDate, getSumOf } from "../../../utils/utils";
import "./Carbon.css";
import Lottie from "lottie-react";
import checkedAnimation from "../../DemandForm/checked.json";
import Button from "../../../components/forms/Buttons/Button";
import Error from "../../../components/Alert/Error";
import { fieldRequiredMessage } from "../../../errors/errors";
import TextInput from "../../../components/forms/TextInput";
import { certificationService } from "../../../services/certification.service";
import loading from "./upload-files-loader.json";
import { useNavigate } from "react-router-dom";
import AlertModal from "../../../components/Modals/AlertModal";
import ReactTable from "../../../components/Table/ReactTable";
import RetirementForm from "./RetirementForm";

export default function CarbonRetirements({
  transactionId,
  certificationId,
  certificationCode,
}) {
  const user = JSON.parse(localStorage.getItem("user"));
  const {
    setValue,
    formState: { errors },
  } = useForm();
  const certificationSteps = useSelector(
    (state) => state.certificationStep.arrayCertification
  );

  const addCreditRetirement = useSelector(
    (state) => state.addCreditRetirements.data
  );

  const getLastSequence = (str) => {
    const segments = str?.split("-");
    const lastSequence = segments?.pop();

    return lastSequence;
  };

  const certifications = useSelector(
    (state) => state.certification.arrayCertification
  );

  const ccrData = useSelector((state) => state.certificationStep.ccr);
  const [currentStep, setCurrentStep] = useState();
  const [errorCount, setErrorCount] = useState(0);
  const [currentCertification, setCurrentCertification] = useState();
  const [transactionToBeRetired, setTransactionToBeRetired] = useState("0");
  const [isLineSaved, setIsLineSaved] = useState(false);
  const [addLine, setAddLine] = useState(false);
  const [formData, setFormData] = useState(
    ccrData?.length > 0 && ccrData[0]?.certificationId === certificationId
      ? ccrData
      : []
  );
  //const [currentIndex, setCurrentIndex] = useState(0);
  const [ccrRefusedByCoc, setCcrRefusedByCoc] = useState(false);
  const [showErrorToBeRetired, setShowError] = useState(false);
  const [showErrorValueRetired, setShowErrorValueRetired] = useState(false);
  const [showErrorTotalQuantity, setShowErrorTotalQuantity] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validateLoading, setValidateLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [transactionCarbonFootPrint, setTransactionCarbonFootPrint] =
    useState();
  const [rowToRemove, setRowToRemove] = useState();
  const [index, setIndex] = useState();
  const [keyAddLine, setKeyAddLine] = useState(0);

  const navigate = useNavigate();

  let ccrDataUpdated = useSelector(
    (state) => state.updateCreditRetirements.data
  );
  const validationCCRStep = useSelector(
    (state) => state.validationCCRStep.data
  );
  const rejectRetirementStep = useSelector(
    (state) => state.rejectRetirementStep.data
  );
  const certificationAllData = useSelector(
    (state) => state.certificationStep.allData
  );

  const creditRetirementData = useSelector(
    (state) => state.creditRetirementFormDataReducer.formData
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const footPrintValue = currentCertification?.carbonFootPrint
      ? currentCertification?.carbonFootPrint
      : certificationAllData?.carbonCo2Value
      ? certificationAllData?.carbonCo2Value
      : certificationAllData?.totalCO2Footprint;
    console.log(
      `currentCertification footprint: ${currentCertification?.carbonFootPrint}`
    );
    console.log(
      `certificationAllData?.carbonCo2Value: ${certificationAllData?.carbonCo2Value}`
    );
    console.log(
      `certificationAllData?.totalCO2Footprint: ${certificationAllData?.totalCO2Footprint}`
    );
    setTransactionCarbonFootPrint(footPrintValue);
  }, [
    currentCertification?.carbonFootPrint,
    certificationAllData?.carbonCo2Value,
    certificationAllData?.totalCO2Footprint,
  ]);

  useEffect(() => {
    if (
      creditRetirementData?.length > 0 &&
      creditRetirementData[0].certificationId === certificationId
    ) {
      setFormData(creditRetirementData);
    }
  }, [creditRetirementData, certificationId]);

  useEffect(() => {
    if (user?.data.userRole === "VAP") {
      dispatch(certificationActions.getCertificationList());
    }
    if (user?.data.userRole !== "VAP") {
      dispatch(certificationActions.getCertificationByUserId(user?.data.id));
    }
    if (certificationSteps.length > 0 && ccrData?.length === 0) {
      const ccrStep = certificationSteps.filter(
        (certif) =>
          certif.processStep === 2 && certif.certificationId === certificationId
      );
      if (ccrStep.length > 0) {
        setCurrentStep(ccrStep[0]);
        dispatch(
          certificationActions.getCarbonCreditRetirementById(ccrStep[0].id)
        );
      }
    }

    if (
      ccrData?.length > 0 &&
      ccrData[0]?.certificationId === certificationId
    ) {
      const quantities = ccrData.map((ccr) => ccr.quantity);

      setTransactionToBeRetired(
        transactionToBeRetired !== "0"
          ? transactionToBeRetired
          : getSumOf(quantities)
      );

      setIsLoading(false);
      const ccrRefused = ccrData?.filter(
        (ccr) => ccr.currentCertificationStatusName === "RefusedByCoc"
      );
      if (ccrRefused.length > 0) {
        setCcrRefusedByCoc(true);
      }

      setCurrentStep(ccrData[0]);
    }
  }, [
    certificationSteps,
    dispatch,
    ccrData,
    setValue,
    certificationId,
    transactionId,
    user?.data.id,
    user?.data.userRole,
    currentStep,
    //formData,
    transactionToBeRetired,
    transactionCarbonFootPrint,
  ]);

  useEffect(() => {
    if (
      ccrDataUpdated?.id &&
      ccrDataUpdated.certificationId === certificationId
    ) {
      const data = ccrDataUpdated;
      setTransactionToBeRetired(data.transactionToBeRetired);
      setValue("transactionId", transactionId);
      setValue("transactionCarbonFootPrint", data.transactionCarbonFootPrint);
      setValue("transactionToBeRetired", data.transactionToBeRetired);
      setValue("standard", data.standard);
      setValue("quantity", data.quantity);
      setValue("idRetirement", data.idRetirement);
      setValue("serialNumbers", data.serialNumbers);
      setValue(
        "projectDescriptionAndLocation",
        data.projectDescriptionAndLocation
      );
      setValue("retirementDate", formatDate(data.retirementDate));
      setValue("proofOfRetirement", data.proofOfRetirement);
      setValue("certificationId", certificationId);
      setValue("vintageYear", new Date(data.vintageYear).getFullYear());
    }

    if (certifications) {
      if (user?.data.userRole === "VAP") {
        const VAPcertifications = certifications.filter(
          (certif) => certif.vvbUserId === user?.data.id
        );
        setCurrentCertification(VAPcertifications);
      }
      const certification = certifications.filter(
        (data) =>
          getLastSequence(data.certificationCode) ===
          getLastSequence(certificationCode)
      );
      setCurrentCertification(certification[0]);
    }
  }, [
    certificationId,
    transactionId,
    setValue,
    ccrDataUpdated,
    certifications,
    user?.data.id,
    user?.data.userRole,
    certificationCode,
  ]);

  useEffect(() => {
    if (
      validationCCRStep ||
      ccrDataUpdated ||
      rejectRetirementStep ||
      addCreditRetirement
    ) {
      setValidateLoading(false);
      setRejectLoading(false);
      setIsLoading(false);
      dispatch(certificationActions.getCertificationStepByCID(certificationId));
    }
  }, [
    validationCCRStep,
    certificationId,
    dispatch,
    ccrDataUpdated,
    rejectRetirementStep,
    addCreditRetirement,
  ]);

  useEffect(() => {
    return () => {
      setFormData(null);
    };
  }, []);

  const validateCarbonToBeRetired = (value) => {
    if (parseInt(value) < parseInt(transactionCarbonFootPrint)) {
      setShowErrorValueRetired(true);
      return false;
    }
    return true;
  };
  const changeInputValue = (e) => {
    //setErrorCount(errorCount + 1);
    //setShowErrorValueRetired(false);
    setShowError(false);

    const isValidated = validateCarbonToBeRetired(e.target.value);
    if (isValidated) {
      setValue("transactionToBeRetired", e.target.value);
      setTransactionToBeRetired(e.target.value);
      setShowErrorValueRetired(false);
    } else {
      setShowErrorValueRetired(true);
    }
  };

  const setRetirementData = (formData) => {
    const quantityToBeRetired = transactionToBeRetired; //||
    // ((ccrDataUpdated?.certificationId === certificationId ||
    //   (ccrData?.length > 0 &&
    //     ccrData[0]?.certificationId === certificationId)) &&
    //   (ccrDataUpdated?.transactionToBeRetired ||
    //     (ccrData?.length > 0 && ccrData[0]?.transactionToBeRetired)));
    let quantityValues = 0;

    const retirementData = new FormData();
    formData.forEach((form, index) => {
      if (form !== null) {
        form.id && retirementData.append(`retirements[${index}].Id`, form.id);
        form.attachments?.length > 0 &&
          retirementData.append(
            `retirements[${index}].AttachmentId`,
            form.attachments[0].id
          );
        retirementData.append(
          `retirements[${index}].CertificationId`,
          certificationId
        );
        form.proofOfRetirement &&
          retirementData.append(
            `retirements[${index}].ProofOfRetirement`,
            form.proofOfRetirement
          );
        retirementData.append(
          `retirements[${index}].RetirementDate`,
          new Date(form.retirementDate).toISOString()
        );
        retirementData.append(
          `retirements[${index}].ProjectDescriptionAndLocation`,
          form.projectDescriptionAndLocation
        );
        retirementData.append(`retirements[${index}].CurrentStatus`, 3);
        retirementData.append(`retirements[${index}].ProcessStep`, 3);
        retirementData.append(
          `retirements[${index}].IdRetirement`,
          form.idRetirement
        );
        retirementData.append(
          `retirements[${index}].Quantity`,
          parseInt(form.quantity)
        );
        retirementData.append(
          `retirements[${index}].SerialNumbers`,
          form.serialNumbers
        );
        retirementData.append(
          `retirements[${index}].VintageYear`,
          new Date(form.vintageYear).toISOString()
        );
        retirementData.append(`retirements[${index}].Standard`, form.standard);
        retirementData.append(`retirements[${index}].IsSentToCoc`, true);
        retirementData.append(`retirements[${index}].IsValidated`, false);

        retirementData.append(
          `retirements[${index}].TransactionCarbonFootPrint`,
          transactionCarbonFootPrint
        );
        retirementData.append(
          `retirements[${index}].TransactionId`,
          transactionId || form?.transactionId
        );
        if (form.files !== null) {
          retirementData.append(`retirements[${index}].Files`, form.files);
        }

        //add quantity values to array to check theirs total values
        quantityValues = quantityValues + parseInt(form?.quantity);
        retirementData.append(
          `retirements[${index}].TransactionToBeRetired`,
          quantityValues.toString()
        );
      }
    });
    const isValidated = validateCarbonToBeRetired(quantityToBeRetired);
    if (isValidated) {
      setShowErrorValueRetired(false);
      return retirementData;
    } else {
      setShowErrorValueRetired(true);
      return null;
    }
  };

  const addOrUpdateRetirement = async (retirementData, byCOC) => {
    if (ccrRefusedByCoc) {
      // const updatedFormData = formData.map((form) => {
      //   return { ...form, isSentToCoc: true };
      // });
      if (byCOC === true) {
        const response =
          await certificationService.updateCarbonCreditRetirement(
            retirementData,
            certificationId
          );
        if (response.data) {
          if (response.data?.length > 0) {
            const stepIds = [];
            for (const ccr of response.data) {
              stepIds.push(ccr.id);
            }
            setIsLineSaved(false);
            dispatch(
              certificationActions.validateCCRStep({
                stepIds: stepIds,
                certificationId: certificationId,
              })
            );
          }
        }
      } else {
        dispatch(
          certificationActions.updateCarbonCreditRetirement(
            retirementData,
            certificationId
          )
        );
        setIsLineSaved(false);
      }
    } else {
      if (transactionToBeRetired) {
        if (byCOC === true) {
          const response = await certificationService.addCarbonCreditRetirement(
            retirementData
          );

          if (response) {
            if (response?.length > 0) {
              const stepIds = [];
              for (const ccr of response) {
                stepIds.push(ccr.id);
              }
              dispatch(
                certificationActions.validateCCRStep({
                  stepIds: stepIds,
                  certificationId: certificationId,
                })
              );
              setIsLineSaved(false);
            }
          }
        } else {
          dispatch(
            certificationActions.createCarbonCreditRetirement(retirementData)
          );
          setIsLineSaved(false);
        }
      } else {
        setIsLoading(false);
        setShowError(true);
      }
    }
  };

  const onSubmit = async (byCOC = false) => {
    //create formData
    const retirementData = setRetirementData(formData);
    if (retirementData) {
      setIsLoading(true);
      addOrUpdateRetirement(retirementData, byCOC);
      //clear formData
      dispatch({ type: "CLEAR_RETIREMENT_DATA" });
    }
  };

  const onHandleSubmit = () => {
    onSubmit();
  };

  const handleBackToDashboard = () => {
    navigate("/dashboard");
  };

  const handleValidateStep = () => {
    setErrorCount(errorCount + 1);
    if (ccrData?.length > 0 && ccrData[0].certificationId === certificationId) {
      if (ccrRefusedByCoc && !ccrData[0].isSentToCoc) {
        onSubmit(true);
      } else {
        setValidateLoading(true);
        //setIsLoading(true);
        const ccrIds = [];
        for (const ccr of ccrData) {
          ccrIds.push(ccr.id);
        }
        dispatch(
          certificationActions.validateCCRStep({
            stepIds: ccrIds,
            certificationId: certificationId,
          })
        );
      }
    } else {
      onSubmit(true);
    }
  };

  const handleRejectStep = () => {
    setRejectLoading(true);
    const stepIds = [];
    for (const ccr of ccrData) {
      stepIds.push(ccr.id);
    }
    dispatch(
      certificationActions.rejectRetirementStep({
        stepIds: stepIds,
        certificationId,
      })
    );
  };

  const conditionToEnableSendToCOC =
    (currentStep?.processStep === 3 && currentStep?.isSentToCoc === false) ||
    (ccrData?.length > 0 &&
      ccrData[0]?.certificationId === certificationId &&
      ccrData[0]?.isSentToCoc === false) ||
    isLineSaved === true;

  const conditionToDisplayBackToDashboard =
    (currentStep?.processStep === 3 && currentStep?.isSentToCoc === true) ||
    (ccrData?.length > 0 &&
      ccrData[0]?.certificationId === certificationId &&
      ccrData[0]?.isSentToCoc === true);

  const handleEditRow = (rowData) => {
    setIndex(rowData);
    setAddLine(true);
  };

  const handleAddNewLine = () => {
    setKeyAddLine(keyAddLine + 1);
    setIndex(null);
    setAddLine(true);
  };

  const handleRemoveRow = (rowData) => {
    setRowToRemove(rowData);
    //setIsLineSaved(true);
  };

  return (
    <div className="shadow-lg rounded-2xl py-4 mt-4">
      {isLoading && (
        <div className="upload-overlay">
          <Lottie
            animationData={loading}
            style={{ width: "250px", height: "250px" }}
          />
        </div>
      )}
      <h1 className="uppercase font-semibold text-lg text-center">
        Carbon credits retirement
      </h1>
      <div className="flex w-full justify-between items-center">
        <p className="w-1/2 flex flex-col col">
          <span className="capitalize">
            Transaction carbon footprint : {transactionCarbonFootPrint} tCO2e
          </span>
        </p>
        <div className="w-1/2 flex flex-col col">
          <span className="capitalize">Retired Carbon Credits :</span>{" "}
          <div className="">
            <>
              <TextInput
                isNumberType={true}
                placeholder="Transaction"
                className="pl-0 w-full bg-white mb-2 border-x-0 border-t-0 border-b-2 focus:outline-none focus:ring-0"
                type="text"
                name="transactionToBeRetired"
                // register={{
                //   ...register("transactionToBeRetired", { required: true }),
                // }}
                disabled={true}
                onChange={changeInputValue}
                value={transactionToBeRetired}
              />
              {(errors.transactionToBeRetired &&
                errors.transactionToBeRetired.type === "required") ||
                (showErrorToBeRetired && (
                  <Error
                    key={errorCount}
                    text={fieldRequiredMessage("Transaction to be retired")}
                  />
                ))}
              {showErrorValueRetired && (
                <Error
                  key={errorCount}
                  text={`Transaction to be retired must be more than transaction carbon footprint value`}
                />
              )}
            </>
          </div>
        </div>

        <div className="w-1/2 flex flex-col col">
          <div
            className="flex flex-col items-center justify-center"
            hidden={!currentStep?.isValidated || currentStep?.processStep !== 3}
          >
            <Lottie
              loop={2}
              style={{ width: "150px", height: "150px" }}
              animationData={checkedAnimation}
            />
          </div>

          <div
            className="flex flex-col items-center justify-center"
            hidden={
              !(
                (currentStep?.isSentToCoc &&
                  !currentStep?.isValidated &&
                  user?.data?.userRole !== "COC") ||
                (user?.data.userRole === "COC" &&
                  user?.data.userRole === "VAP" &&
                  !currentStep?.isSentToCoc &&
                  !currentStep?.isValidated)
              )
            }
          >
            <div className="py-4">
              <img
                width={`150px`}
                height={`150px`}
                src="/assets/images/clock-pending-time.svg"
                alt="pending"
              />
            </div>
            <p className="text-[#f2af29] font-medium">
              Proof of offsetting has been communicated to COC for certification
              issuance
            </p>
          </div>
        </div>
      </div>
      <div className="button-wrapper">
        <div
          className={`mb-3 flex justify-content-end ${
            currentStep?.isSentToCoc === true && "hidden"
          }`}
        >
          {/* <BlueButton className='buttonaddline' Event={handleAddNewLine}>Add Line +</BlueButton> */}
          <button
            className="bg-coc-green text-white px-3 py-2 text-center rounded-lg addlineBtn"
            onClick={handleAddNewLine}
          >
            Add Line +
          </button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "1rem",
            fontSize: "14px",
          }}
        >
          {user?.data.userRole !== "COC" &&
            user?.data.userRole !== "VAP" &&
            conditionToEnableSendToCOC &&
            formData?.length > 0 && (
              <Button
                //className={`bg-coc-green text-white px-12 py-2 rounded-lg`}
                type="button"
                onClick={onHandleSubmit}
                text={`Send to COC for Validation`}
                backgroundColor={`coc-blue`}
                hoverBgColor={`bg-[#2b2f8d]`}
                className="sendBtn"
              />
            )}
        </div>
      </div>

      <div className=" table-wrapper relative overfow-x-auto h-auto max-h-[648px] shadow-sm mb-4 overflow-y-auto rounded-lg carbon mx-4">
        <ReactTable
          certificationList={formData}
          isCarbonRetirement={true}
          setShowRemoveModal={handleRemoveRow}
          setIndex={handleEditRow}
          currentStep={currentStep}
          certificationId={certificationId}
        />
      </div>
      {/* <Table columns={columns} data={data} /> */}
      <div className="flex items-center justify-center mb-9 py-4 ml-2">
        {user?.data.userRole !== "COC" &&
          user?.data.userRole !== "VAP" &&
          conditionToDisplayBackToDashboard &&
          !conditionToEnableSendToCOC && (
            <Button
              //className={`bg-coc-green text-white px-12 py-2 rounded-lg`}
              type="button"
              onClick={handleBackToDashboard}
              ///disabled={conditionToDisableSendToCOC}
              text={`Back to Dashboard`}
              backgroundColor={`coc-blue`}
            />
          )}
      </div>

      {user?.data.userRole === "COC" && (
        <div className={`flex justify-center`}>
          <div className="w-1/4 flex justify-between">
            <div className="mr-3">
              <Button
                disabled={
                  currentStep?.isValidated ||
                  !currentStep?.isSentToCoc ||
                  (ccrData.length > 0 && !ccrData[0].isSentToCoc)
                }
                className={`px-12 py-3 bg-coc-blue text-white capitalize flex text-xs items-center hover:bg-coc-blue-600 rounded-lg`}
                onClick={handleRejectStep}
                backgroundColor={`coc-blue`}
                hoverBgColor={`bg-coc-blue-600`}
                text={`reject`}
                loading={rejectLoading}
              />
            </div>
            <Button
              disabled={
                currentStep?.isSentToCoc
                  ? currentStep?.isValidated
                  : isLineSaved === false ||
                    (isLineSaved === true && formData?.length === 0)
              }
              //className={`px-12 py-3 bg-coc-green text-white capitalize flex text-xs items-center hover:bg-[#329940] rounded-lg`}
              onClick={handleValidateStep}
              backgroundColor={`coc-green`}
              hoverBgColor={`bg-[#329940]`}
              text={`validate`}
              loading={validateLoading}
            />
          </div>
        </div>
      )}
      <AlertModal
        showConfirmationModal={showErrorTotalQuantity}
        setShowModal={setShowErrorTotalQuantity}
        textContent={`The sum of credits must be equal or higher than the declared quantity of retired credits.`}
      />
      <RetirementForm
        isModalOpen={addLine}
        closeModal={setAddLine}
        certificationId={certificationId}
        transactionId={transactionId}
        transactionCarbonFootPrint={transactionCarbonFootPrint}
        transactionToBeRetired={transactionToBeRetired}
        codeCertification={certificationCode}
        formData={formData}
        setIsLineSaved={setIsLineSaved}
        index={index}
        rowToRemove={rowToRemove}
        setFormData={setFormData}
        key={keyAddLine}
        setTransactionToBeRetired={setTransactionToBeRetired}
        setRowToRemove={setRowToRemove}
      />
    </div>
  );
}
