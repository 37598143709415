import React from "react";

const PdfViewer = () => {
  const pdfUrl =
    process.env.PUBLIC_URL + "/Carbon-Offset-Certification-Protocol.pdf";

  return (
    <div>
      <embed src={pdfUrl} type="application/pdf" width="100%" height="600px" />
    </div>
  );
};

export default PdfViewer;
