import TextInput from "../forms/TextInput";
import PasswordInput from "../forms/PasswordInput";
import Upload from "../forms/Buttons/UploadButton";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../actions";
import { useForm } from "react-hook-form";
import isValidLogoFormat, { validPassword } from "../../utils/validation";
import {
  fieldRequiredMessage,
  invalidLogoFormatMessage,
  invalidPasswordMessage,
  invalidPhoneNumberMessage,
  invalidSizeFile,
  maxUploadFileSize,
  passwordsNotMatchMessage,
  phoneNumberPattern,
} from "../../errors/errors";
import Error from "../Alert/Error";
import SubmitButton from "../forms/Buttons/SubmitButton";
import Modal from "react-modal";

export default function CreatePartner({ showConfirmationModal, setShowModal }) {
  const [partnerFile, setPartnerFile] = useState();
  const [fileName, setFileName] = useState();
  const [fileSize, setFileSize] = useState();
  const [loading, setLoading] = useState();
  const [errorCount, setErrorCount] = useState(0);
  const [passwordTyped, setPasswordTyped] = useState("");
  const [image, setImage] = useState(null);
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);
  const isRegistered = useSelector((state) => state.registration.registered);
  const {
    handleSubmit,
    getValues,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const onChoosePartnerFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
    }
    setPartnerFile(file);
    setFileName(file.name);
    setFileSize(file.size);
  };

  const updateInputValue = (fieldName, value) => {
    if (fieldName === "Password") setPasswordTyped(value);
    setValue(fieldName, value);
  };

  const onSubmitForm = async () => {
    setLoading(true);
    let userData = new FormData();
    // const companyExpertises = getValues().CompanyExpertise;
    // companyExpertises.forEach((value, index) => {
    //   userData.append(`CompanyExpertise[${index}]`, value);
    // });
    const role = "CMAP";
    userData.append("LastName", getValues().LastName);
    userData.append("FirstName", getValues().FirstName);
    userData.append("ClienType", 2);
    userData.append("Email", getValues().Email);
    userData.append("PhoneNumber", getValues().PhoneNumber);
    userData.append("Password", getValues().Password);
    userData.append("ConfirmPassword", getValues().ConfirmPassword);
    userData.append("CompanyName", getValues().CompanyName);
    userData.append("CompanyWebsite", getValues().CompanyWebsite);
    userData.append("CompanyAddress", getValues().CompanyAddress);
    userData.append("UserRole", role);
    userData.append("CompanyExpertise", [3]);
    partnerFile && userData.append("File", partnerFile);
    dispatch(userActions.register(userData));
  };

  const handleCloseModal = () => {
    setImage(null);
    setFileName(null);
    setShowModal(false);
    reset();
  };

  useEffect(() => {
    setImage(null);
    //(isRegistered || alert) && setLoading(false);
    if (isRegistered) {
      setLoading(false);
      setShowModal(false);
      reset();
    }
  }, [isRegistered, alert, reset, setShowModal]);

  return (
    <Modal
      isOpen={showConfirmationModal}
      className="popup-partner-modal admin-users"
      overlayClassName="modall-overlay"
      shouldCloseOnOverlayClick={false}
    >
      <div className="max-h-screen overflow-hidden">
        <div className="flex overflow-auto h-full relative">
          <div className="absolute top-2 right-2">
            <button
              id="closeButton"
              type="button"
              className="inline-flex flex-shrink-0 rounded-full border-light-grey justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm"
              data-hs-overlay="#hs-user-partner"
              onClick={handleCloseModal}
            >
              <span className="sr-only">Close</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </button>
          </div>
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <div className="p-4 sm:p-10 overflow-y-auto">
              <h3 className="font-semibold text-center text-2xl text-gray-800 my-4">
                Create new partner
              </h3>

              <div className="profile mt-9">
                <div className="img flex">
                  <div className="border-dashed w-1/4 h-[120px] border-[0.5px] border-gray-400 overflow-hidden flex items-center justify-center">
                    {image ? (
                      <img alt="preview" src={image} />
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-12 h-12 text-gray-400"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    )}
                  </div>

                  <div className="ml-4">
                    {/* <button
                        type="button"
                        className="login flex justify-center items-center text-mg border-0 bg-coc-blue hover:bg-coc-blue text-white w-full py-3 rounded-lg"
                      >
                        <img
                          src="/assets/icons/upload.svg"
                          alt=""
                          className="upload w-[18px] mr-2"
                        />
                        Upload file
                      </button>
                      <input
                        className="absolute right-0 bottom-0 top-0 left-0 opacity-0"
                        type="file"
                        name="File"
                        id="File"
                        {...register("File")}
                        onChange={onChooseFile}
                      /> */}
                    <Upload
                      label="Upload image"
                      onChange={onChoosePartnerFile}
                      id="upload-partner"
                    >
                      <svg
                        className="w-5 h-5 mr-2 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                        />
                      </svg>
                    </Upload>
                    {/* <p className="mt-2 text-sm text-gray">
                        {fileName
                          ? fileName
                          : "only .jpg or .png files are accepted"}
                      </p> */}
                    {fileName && !isValidLogoFormat(fileName) && (
                      <Error key={errorCount} text={invalidLogoFormatMessage} />
                    )}
                    {fileSize && fileSize > maxUploadFileSize && (
                      <Error key={errorCount} text={invalidSizeFile} />
                    )}

                    {/*<Button className="mt-2">
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="mr-2 w-5 h-5">
		<path fillRule="evenodd" d="M11.47 2.47a.75.75 0 011.06 0l4.5 4.5a.75.75 0 01-1.06 1.06l-3.22-3.22V16.5a.75.75 0 01-1.5 0V4.81L8.03 8.03a.75.75 0 01-1.06-1.06l4.5-4.5zM3 15.75a.75.75 0 01.75.75v2.25a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V16.5a.75.75 0 011.5 0v2.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V16.5a.75.75 0 01.75-.75z" clipRule="evenodd" />
		</svg>
		Change picture 
		<input type="file"/>
		</Button>*/}
                  </div>
                </div>
              </div>

              <p className="text-gray-400 mt-3 font-light text-md">
                Add your photo. The recommended size is 160x160px
              </p>
            </div>

            <div className="grid grid-cols-3 gap-x-9 px-4 py-4 border-t">
              <div>
                <TextInput
                  label="Company Name"
                  placeholder="Company Name"
                  register={{
                    ...register("CompanyName", { required: true }),
                  }}
                  onChange={(e) =>
                    updateInputValue("CompanyName", e.target.value)
                  }
                />
                {errors.CompanyName &&
                  errors.CompanyName.type === "required" && (
                    <Error
                      key={errorCount}
                      text={fieldRequiredMessage("Company Name")}
                    />
                  )}
              </div>
              <div>
                <TextInput
                  label="Company Address"
                  placeholder="Company Address"
                  register={{
                    ...register("CompanyAddress", { required: true }),
                  }}
                  onChange={(e) =>
                    updateInputValue("CompanyAddress", e.target.value)
                  }
                />
                {errors.CompanyAddress &&
                  errors.CompanyAddress.type === "required" && (
                    <Error
                      key={errorCount}
                      text={fieldRequiredMessage("Company Address")}
                    />
                  )}
              </div>
              <div>
                <TextInput
                  label="Company Website"
                  placeholder="http://exemple.com"
                  register={{
                    ...register("CompanyWebsite", { required: true }),
                  }}
                  onChange={(e) =>
                    updateInputValue("CompanyWebsite", e.target.value)
                  }
                />
                {errors.CompanyWebsite &&
                  errors.CompanyWebsite.type === "required" && (
                    <Error
                      key={errorCount}
                      text={fieldRequiredMessage("Company Website")}
                    />
                  )}
              </div>
            </div>

            <div className="grid grid-cols-3 gap-x-9 px-4 mb-4">
              <div>
                <TextInput
                  label="First Name"
                  placeholder="Enter first name"
                  register={{ ...register("FirstName", { required: true }) }}
                  onChange={(e) =>
                    updateInputValue("FirstName", e.target.value)
                  }
                />
                {errors.FirstName && errors.FirstName.type === "required" && (
                  <Error
                    key={errorCount}
                    text={fieldRequiredMessage("First Name")}
                  />
                )}
              </div>
              <div>
                <TextInput
                  label="Last Name"
                  placeholder="Enter Last Name"
                  register={{ ...register("LastName", { required: true }) }}
                  onChange={(e) => updateInputValue("LastName", e.target.value)}
                />
                {errors.LastName && errors.LastName.type === "required" && (
                  <Error
                    key={errorCount}
                    text={fieldRequiredMessage("Last Name")}
                  />
                )}
              </div>
              <div>
                <TextInput
                  label="Email"
                  placeholder="email@exemple.com"
                  register={{ ...register("Email", { required: true }) }}
                  onChange={(e) => updateInputValue("Email", e.target.value)}
                />
                {errors.Email && errors.Email.type === "required" && (
                  <Error
                    key={errorCount}
                    text={fieldRequiredMessage("Email")}
                  />
                )}
              </div>
            </div>

            <div className="grid grid-cols-3 gap-x-9 px-4 mb-4">
              <div>
                <TextInput
                  isNumberType={true}
                  label="Phone number"
                  placeholder="00 408 XXX XXXX"
                  register={{
                    ...register("PhoneNumber", {
                      required: true,
                      pattern: phoneNumberPattern,
                    }),
                  }}
                  onChange={(e) =>
                    updateInputValue("PhoneNumber", e.target.value)
                  }
                />
                {errors.PhoneNumber &&
                  errors.PhoneNumber.type === "pattern" && (
                    <Error key={errorCount} text={invalidPhoneNumberMessage} />
                  )}
                {errors.PhoneNumber &&
                  errors.PhoneNumber.type === "required" && (
                    <Error
                      key={errorCount}
                      text={fieldRequiredMessage("Phone Number")}
                    />
                  )}
              </div>
              <div className="mt-2">
                <PasswordInput
                  label="Enter Password"
                  placeholder="Password"
                  register={{
                    ...register("Password", {
                      required: true,
                      pattern: validPassword,
                    }),
                  }}
                  onChange={(e) => updateInputValue("Password", e.target.value)}
                />
                {errors.Password && errors.Password.type === "required" && (
                  <Error
                    key={errorCount}
                    text={fieldRequiredMessage("Password")}
                  />
                )}
                {errors.Password && errors.Password.type === "pattern" && (
                  <Error key={errorCount} text={invalidPasswordMessage} />
                )}
              </div>

              <div className="mt-2">
                <PasswordInput
                  label="Confirm the Password"
                  placeholder="Confirm the password"
                  register={{
                    ...register("ConfirmPassword", {
                      required: true,
                      validate: (val) => val === passwordTyped,
                    }),
                  }}
                  onChange={(e) =>
                    updateInputValue("ConfirmPassword", e.target.value)
                  }
                />
                {errors.ConfirmPassword &&
                  errors.ConfirmPassword.type === "required" && (
                    <Error
                      key={errorCount}
                      text={fieldRequiredMessage("Password Confirmation")}
                    />
                  )}
                {errors.ConfirmPassword &&
                  errors.ConfirmPassword.type === "validate" && (
                    <Error key={errorCount} text={passwordsNotMatchMessage} />
                  )}
              </div>
            </div>

            <div className="flex justify-end items-center gap-x-2 py-3 px-4 bg-gray-50 border-t">
              <button
                type="button"
                className="py-2.5 px-4 inline-flex justify-center bg-gray-400 items-center gap-2 rounded-md border font-medium text-white shadow-sm align-middle hover:bg-gray-300 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm"
                //data-hs-overlay="#hs-user-details"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <SubmitButton
                className="py-2.5 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-medium bg-coc-green text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-coc-green focus:ring-offset-2 transition-all text-sm"
                loading={loading}
                onClick={() => setErrorCount(errorCount + 1)}
              />
              {alert.message && alert.type === "alert-danger" && (
                <Error key={errorCount} text={alert.message} />
              )}
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
