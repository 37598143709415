import { userConstants } from '../constants/user.constants.js';

export default function uploadCertification(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_UPLOAD_CERT_REQUEST:
            return action.payload
        case userConstants.GET_UPLOAD_CERT_SUCCESS:
            return {
                ...state,
                file: action.payload
            }
            
        case userConstants.GET_UPLOAD_CERT_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        case userConstants.GET_UPLOAD_CERT_CLEAR:
            return {
                state
            }
        default:
            return state
    }
}