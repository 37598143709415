import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import Login from "../pages/Login/Login";
import ServiceProvider from "../pages/Registration/ServiceProvider";
import ClientRegister from "../pages/Registration/ClientRegister";
import CreateAccount from "../pages/Registration/CreateAccount";
import Dashboard from "../pages/Dashboard/Dashboard";
import Monitoring from "../pages/Monitoring/Monitoring";
import { alertActions } from "../actions";
import Header from "../components/Header/Header";
import Admin from "../pages/Admin/Admin";
import PrivateRoute from "../components/PrivateRoute";
import CertificationForm from "../pages/DemandForm/CertificationForm";
import CocProtocol from "../pages/Protocol/CocProtocol";
import { useEffect } from "react";
import Partner from "../pages/Partners/Partner";
import Modal from "react-modal";
import ResetPassword from "../pages/ResetPwd/ResetPassword";
import Confirmation from "../pages/ResetPwd/Confirmation";
import TableResize from "./TableResize";
import AdminRoute from "../components/AdminRoute";
import RedirectedPage from "../components/RedirectPage";

function App() {
  useEffect(() => {
    import("core-js");
    import("preline");
    Modal.setAppElement("#root"); // Remplacez '#root' par l'identifiant de votre élément racine dans le DOM
  }, []);
  const authenticated =
    sessionStorage.getItem("token") || localStorage.getItem("user");
  const isAuth = authenticated !== null && authenticated !== "";
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <div className="App mt-24 mb-9 min-h-screen pb-[50px]">
      <div className="z-10">
        <Router>
          <Header isAuth={isAuth} />
          <Routes>
            <Route
              path="/dashboard"
              element={
                <PrivateRoute isAuthenticated={isAuth}>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route path="/table" element={<TableResize />} />
            <Route
              path="/dashboard/step/:certificationId/*"
              element={
                <PrivateRoute isAuthenticated={isAuth}>
                  <Monitoring />
                </PrivateRoute>
              }
            />
            <Route
              path="/certification"
              element={
                <PrivateRoute isAuthenticated={isAuth}>
                  <CertificationForm />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/"
              element={
                isAuth && user?.data.userRole === "ADMIN" ? (
                  <PrivateRoute isAuthenticated={isAuth}>
                    <Dashboard isAdmin={true} />
                  </PrivateRoute>
                ) : isAuth && user?.data.userRole !== "ADMIN" ? (
                  <PrivateRoute isAuthenticated={isAuth}>
                    <Dashboard />
                  </PrivateRoute>
                ) : (
                  <Login />
                )
              }
            />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/service" element={<ServiceProvider />} />
            <Route path="/client" element={<ClientRegister />} />
            <Route path="/create" element={<CreateAccount />} />
            <Route path="/protocol" element={<CocProtocol />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/confirmation" element={<Confirmation />} />
            <Route
              path="/partners"
              element={
                <PrivateRoute isAuthenticated={isAuth}>
                  <Partner />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/users"
              element={
                <AdminRoute isAuthenticated={isAuth}>
                  <Admin />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/certifications"
              element={
                <AdminRoute isAuthenticated={isAuth}>
                  <Dashboard isAdmin={true} />
                </AdminRoute>
              }
            />
            <Route
              path="/certificate/:attachmentId"
              element={<RedirectedPage />}
            />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

function mapState(state) {
  const { alert } = state;
  return { alert };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
};

const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };
