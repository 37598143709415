import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

const RedirectedPage = () => {
  const { attachmentId } = useParams();
  const simulateLinkClick = () => {
    const link = document.getElementById("downloadLink");
    if (link) {
      link.click();
    }
  };

  useEffect(() => {
    if (attachmentId) {
      simulateLinkClick();
    }
  }, [attachmentId]);

  if (attachmentId) {
    return (
      <>
        <Link
          to={`${process.env.REACT_APP_API_URL}/downloadbyid?attachmentId=${attachmentId}`}
          id="downloadLink"
          style={{ display: "none" }} // Masquez le lien réel
        >
          {`${process.env.REACT_APP_API_URL}/downloadbyid?attachmentId=${attachmentId}`}
        </Link>
        <span className="text-center">Certificate downloading...</span>
      </>
    );
  } else {
    return <span>You have to set the attachment ID in the URL.</span>;
  }
};

export default RedirectedPage;
