import { alertActions } from './alert.actions.js';
import {partnerConstants} from "../constants/partner.constants";
import {partnerService} from "../services/partner.service";


export const partnerActions = {
    getPartnerList
};
function getPartnerList() {
    return async (dispatch) => {
        const response = await partnerService.getPartnerList();
        if(response) {
            dispatch(success(response));
        } else {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
        }
    };
    function success(partners) { return { type: partnerConstants.GET_PARTNER_LIST_SUCCESS, payload: partners } }
    function failure(error) { return { type: partnerConstants.GET_PARTNER_LIST_FAILURE, payload: error } }
}