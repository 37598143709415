import React, { useState } from "react";

const SearchBar = ({ onSearch, isPageAdmin = false }) => {
  const [searchValue, setSearchValue] = useState("");

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
    onSearch(event.target.value);
  };

  const searchBar = isPageAdmin ? (
    <div className="searchbox w-1/4 mb-4 relative flex items-center max-w-md p-2 bg-white rounded-full shadow-lg hover:border-green-500">
      <input
        type="text"
        value={searchValue}
        onChange={handleInputChange}
        placeholder="Search user by name, email ..."
        className="border-none w-full focus:ring-0 pl-14"
      />
     <svg className="h-5 w-5 text-coc-blue mx-3 absolute top-[18px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
    </div>
  ) : (
    <div className="flex items-center max-w-md mx-auto p-2 bg-white rounded-full shadow-lg hover:border-green-500">
      <svg
        className="h-5 w-5 text-coc-blue mx-3"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx="11" cy="11" r="8" />
        <line x1="21" y1="21" x2="16.65" y2="16.65" />
      </svg>
      <input
        type="text"
        value={searchValue}
        onChange={handleInputChange}
        placeholder="Search..."
        className="outline-none bg-transparent w-full text-gray-700 placeholder-gray-500 border-none focus:ring-0"
      />
    </div>
  );

  return searchBar;
};

export default SearchBar;
