import { Link, NavLink } from "react-router-dom";
import "./Header.css";
import HeaderConnected from "../Header_connected/HeaderConnected";
import HeaderAdmin from "./HeaderAdmin";

export default function Header({ isAuth }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const handleNavLinkClick = (e) => {
    if (!isAuth) {
      e.preventDefault();
      window.open(
        "https://carbonoffsetcertification.com/certification",
        "_blank"
      );
    }
  };
  if (isAuth && user?.data.userRole !== "ADMIN" && user)
    return <HeaderConnected isAuth={isAuth} />;
  if (user?.data.userRole === "ADMIN") return <HeaderAdmin isAuth={isAuth} />;
  return (
    <header className="z-40">
      <span className="logo">
        <Link to={isAuth ? "/dashboard" : "/"}>
          <img src="/assets/images/logo-white.png" alt="" />
        </Link>
      </span>
      <ul className="menu">
        <li>
          <NavLink
            className={({ isActive }) => (isActive ? "active" : "none")}
            to="/protocol"
            onClick={handleNavLinkClick}
            target={!isAuth ? "_blank" : ""}
          >
            COC Protocol
          </NavLink>
        </li>
      </ul>
      {/* <ul className="flex justify-end">
        <li>
          <Link to="/login">
            <Button
              className={`bg-coc-green text-white px-12 py-2 rounded-lg`}
              text={`Log in`}
              backgroundColor={`green`}
              hoverBgColor={`bg-[#329940]`}
            />
          </Link>
        </li>
      </ul> */}
    </header>
  );
}
