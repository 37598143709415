import "./Monitoring.css";
import { useState, useEffect } from "react";
import FootPrintCalculation from "./DataMonitoring/FootPrintCalculation";
import CarbonRetirements from "./DataMonitoring/CarbonRetirements";
import {
  Link,
  Outlet,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import { steps } from "../../utils/steps";
import { useDispatch, useSelector } from "react-redux";
import { certificationActions } from "../../actions/certification.action";
import FootPrintVerification from "./DataMonitoring/FootPrintVerification";
import CNCCertification from "./DataMonitoring/CNCCertification";
import DetailTransactionModal from "../../components/Modals/DetailTransactionModal";
import VVBModal from "../../components/Modals/VVBModals";
import { userActions } from "../../actions";
import { Tooltip } from "@material-tailwind/react";

export default function Monitoring() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [selectedElement, setSelectedElement] = useState(null);
  const [currentStep, setCurrentStep] = useState();
  const [transactionID, setTransactionID] = useState();
  const [pendingStep, setPendingStep] = useState();
  const [VVBUsers, setVVBUsers] = useState();
  const handleSelectedElement = (elementId) => {
    setSelectedElement(elementId);
  };
  const { certificationId } = useParams();
  const certificationSteps = useSelector(
    (state) => state.certificationStep.arrayCertification
  );
  const ccrData = useSelector((state) => state.certificationStep.ccr);
  const dispatch = useDispatch();
  const validateStep = useSelector((state) => state.validateStep.data);
  const certificationAllData = useSelector(
    (state) => state.certificationStep.allData
  );
  const vvbNominated = useSelector((state) => state.vvb.data_on_nominate);
  const allUsers = useSelector((state) => state.users.items);
  const [certificationStep, setCertificationStep] = useState();
  const [shouldRedirect, setShouldRedirect] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.data.userRole === "COC") {
      dispatch(userActions.getAll());
    }
    if (validateStep || vvbNominated) {
      dispatch(certificationActions.getCertificationStepByCID(certificationId));
    }
  }, [
    dispatch,
    validateStep,
    certificationId,
    user?.data.userRole,
    vvbNominated,
  ]);

  useEffect(() => {
    //call API to get certification by its code
    dispatch(certificationActions.getCertificationStepByCID(certificationId));
  }, [certificationId, dispatch]);

  useEffect(() => {
    if (certificationSteps.length > 0) {
      setTransactionID(certificationSteps[0]?.transactionId);
      const actualStep = certificationSteps.reduce((prev, current) => {
        if (current.processStep > prev.processStep) return current;
        else return prev;
      });
      if (
        ccrData?.length > 0 &&
        ccrData[0].id &&
        ccrData[0].certificationId === certificationId
      ) {
        if (ccrData[0]?.processStep > actualStep.processStep) {
          setCurrentStep(ccrData[0]);
        } else setCurrentStep(actualStep);
      } else setCurrentStep(actualStep);
    }
  }, [certificationSteps, ccrData, currentStep, certificationId]);

  useEffect(() => {
    if (certificationSteps.length > 0) {
      const footprintCalculationStep = certificationSteps.filter(
        (certif) => certif.processStep === 1
      );
      if (footprintCalculationStep.length > 0) {
        setCertificationStep(footprintCalculationStep[0]);
      } else {
        setCertificationStep(certificationSteps[0]);
      }
    }
    if (user?.data.userRole === "COC" && allUsers) {
      const userVVB = allUsers?.data.filter(
        (user) =>
          user.userType === "VAP" || user.listCompanyExpertise?.includes("VVB")
      );
      setVVBUsers(userVVB);
    }
  }, [certificationSteps, certificationStep, user?.data.userRole, allUsers]);

  useEffect(() => {
    steps.map((step, i) => {
      return (
        ((step.id === currentStep?.processStep + 1 &&
          currentStep?.isValidated) ||
          (step.id === currentStep?.processStep &&
            !currentStep?.isValidated)) &&
        setPendingStep(step)
      );
    });
    if (shouldRedirect) {
      navigate(`${pendingStep?.url}`);
    }
  }, [currentStep, navigate, shouldRedirect, pendingStep]);

  return (
    <div className="px-7">
      <div className="flex justify-between">
        <Link
          className="text-coc-blue font-medium block mb-3"
          to={{ pathname: "/dashboard" }}
        >
          <img
            className="inline-block mr-3"
            src="/assets/icons/back.svg"
            alt=""
          />
          Go back
        </Link>

        <div className="mb-3">
          <DetailTransactionModal
            transactionId={certificationStep && certificationStep.transactionId}
            certificationsData={certificationAllData}
          />
        </div>
      </div>

      <div className="shadow-lg rounded-lg px-4 pt-3 w-screen sm:w-full">
        <h1 className="uppercase font-semibold text-lg text-center mb-4">
          Certification process
        </h1>

        <div className="flex items-center h-3/6 mb-6 w-full">
          <div className="flex justify-between w-full mx-auto">
            {steps.map((step, i) => (
              <div
                onClick={() => handleSelectedElement(step.id)}
                key={i}
                className={`step-item 

      ${step.id === selectedElement && "selected-element"}
      ${
        ((step.id === currentStep?.processStep + 1 &&
          currentStep?.isValidated) ||
          (step.id === currentStep?.processStep &&
            !currentStep?.isValidated)) &&
        ((selectedElement === null && "pending selected-element") || "pending")
      }
      ${
        ((step.id === currentStep?.processStep && currentStep?.isValidated) ||
          step.id < currentStep?.processStep) &&
        "active"
      }
      `}
              >
                {step.id !== 0 ? (
                  <Link
                    className={`hover:no-underline hover:text-white`}
                    to={
                      step.id === currentStep?.processStep ||
                      (step.id === currentStep?.processStep + 1 &&
                        currentStep?.isValidated) ||
                      step.id < currentStep?.processStep
                        ? `${step.url}`
                        : "#"
                    }
                    onClick={() => setShouldRedirect(false)}
                  >
                    <span className="step">{step.id + 1}</span>
                  </Link>
                ) : (
                  <span className="step">{step.id + 1}</span>
                )}

                <p className="text-coc-blue h-10 pt-2 font-medium max-w-50 max-w-[200px]">
                  {step.name}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className="relative flex justify-start text-sm container mx-auto">
          <div className="subtitle first sm:max-md:static md:absolute left-20">
            <p className="text-grey-900 font-medium">
              Partner <br /> nomination
            </p>
          </div>
          <div className="line relative line1 text-center relative h-24">
            <div className="flex">
              <p className="text-grey-900">MRV Service provider</p>{" "}
              <img
                className="inline-block ml-2 absolute -right-6 w-3 h-3 top-1 hidden"
                src="/assets/icons/check.svg"
                alt=""
              />
            </div>
            <span className="text-grey">Optional</span>
          </div>
          <div className={`line relative line2 text-center relative h-24`}>
            <div className="flex">
              {user?.data.userRole === "COC" &&
              !certificationAllData?.isValidateByVvb ? (
                <>
                  <button data-hs-overlay="#hs-vvb-modal">
                    <p
                      className={`text-${
                        certificationAllData?.vvbUserId &&
                        !certificationAllData?.isValidateByVvb
                          ? "orange"
                          : certificationAllData?.vvbUserId &&
                            certificationAllData?.isValidateByVvb
                          ? "coc-green"
                          : "grey-900"
                      }`}
                    >
                      Verification Body
                    </p>{" "}
                  </button>
                  <img
                    className="inline-block ml-2 absolute -right-6 w-3 h-3 top-1 hidden"
                    src="/assets/icons/check.svg"
                    alt=""
                  />
                </>
              ) : (
                <>
                  <p
                    className={`text-${
                      certificationAllData?.vvbUserId &&
                      !certificationAllData?.isValidateByVvb
                        ? "orange"
                        : certificationAllData?.vvbUserId &&
                          certificationAllData?.isValidateByVvb
                        ? "coc-green"
                        : "grey-900"
                    }`}
                  >
                    Verification Body
                  </p>{" "}
                  <img
                    className="inline-block ml-2 absolute -right-6 w-3 h-3 top-1 hidden"
                    src="/assets/icons/check.svg"
                    alt=""
                  />
                </>
              )}
            </div>
            <span
              className={`text-${
                certificationAllData?.vvbUserId &&
                certificationAllData?.isValidateByVvb
                  ? "coc-green"
                  : certificationAllData?.vvbUserId &&
                    !certificationAllData?.isValidateByVvb
                  ? "orange"
                  : "black"
              }`}
            >
              {certificationAllData?.vvbUserId &&
              certificationAllData?.isValidateByVvb
                ? "Nominated"
                : certificationAllData?.vvbUserId &&
                  !certificationAllData?.isValidateByVvb
                ? "Waiting for VVB aproval"
                : "Compulsary"}
            </span>
            {!(
              certificationAllData?.vvbUserId &&
              certificationAllData?.isValidateByVvb
            ) && (
              <Tooltip
                className="bg-[#707070] p-4"
                content="COC team will nominate the Verification Body as per company’s choice"
              >
                <svg
                  id="vvb-icon"
                  data-hs-overlay={`${
                    user?.data.userRole === "COC" &&
                    !certificationAllData?.isValidateByVvb &&
                    "#hs-vvb-modal"
                  }`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-8 h-8 text-orange mx-auto hover:cursor-pointer"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                    clipRule="evenodd"
                  />
                </svg>
              </Tooltip>
            )}
          </div>
          <div className="line relative line3 text-center relative h-24">
            <div className="flex">
              <p className="text-grey-900">Carbon credits provider</p>{" "}
              <img
                className="inline-block ml-2 absolute -right-6 w-3 h-3 top-1 hidden"
                src="/assets/icons/check.svg"
                alt=""
              />
            </div>
            <span className="text-grey">Optional</span>
          </div>
        </div>
      </div>

      <div>
        <Outlet />
      </div>
      <Routes>
        <Route
          path={`/footprint-calculation`}
          element={<FootPrintCalculation certificationId={certificationId} />}
        />
        <Route
          path={`/footprint-verification`}
          element={<FootPrintVerification certificationId={certificationId} />}
        />
        <Route
          path={`/carbon-retirement`}
          element={
            <CarbonRetirements
              transactionId={transactionID}
              certificationId={certificationId}
              certificationCode={certificationStep?.codeCertification}
            />
          }
        />
        <Route
          path={`/cnc-certification`}
          element={<CNCCertification certificationId={certificationId} />}
        />
      </Routes>
      <VVBModal certificationId={certificationId} userList={VVBUsers} />
    </div>
  );
}
