import React, { useEffect, useState } from "react";
import TextInput from "../../../components/forms/TextInput";
import TextArea from "../../../components/forms/TextArea";
import LabelInput from "../../../components/forms/Label";
import InfoPopup from "../../../components/create-certification/InfoPopup";
import { preTransactionInfoOtherService } from "../../../utils/constant";
import Error from "../../../components/Alert/Error";
import { fieldRequiredMessage } from "../../../errors/errors";
import { formatDate } from "../../../utils/utils";
import DefaultRadioInput from "../../../components/forms/DefaultRadioInput";

const FlightServiceAdmin = ({
  formData,
  setFormData,
  errors,
  errorCount,
  register,
  setValue,
  reset,
  unregister,
}) => {
  // useEffect(() => {
  //   reset();
  // }, [reset]);
  const [preTransactionChecked, setPreTransactionChecked] = useState();

  useEffect(() => {
    setValue("numberOfTranscations", formData?.numberOfTranscations || 0);
    setValue("airCraftType", formData?.airCraftType || "");
    setValue("shippingDate", formData?.shippingDate || "");
    setValue("origin", formData?.origin || "");
    setValue("arrival", formData?.arrival || "");
    setValue(
      "preTransactionCertification",
      formData?.preTransactionCertification ? true : false
    );
    setValue(
      "postTransactionCertification",
      formData?.preTransactionCertification ? false : true
    );
    setValue("transactionDescription", formData?.transactionDescription || "");
  }, [formData, setValue]);

  const [preTransactionRequired, setPreTransactionRequired] = useState(true);
  const handleChangeInput = (fieldName, value, fieldsToUnregister) => {
    setFormData({ ...formData, [fieldName]: value });
    setValue(fieldName, value);

    // Mettre à jour les champs à désenregistrer
    if (fieldsToUnregister) {
      // Désenregistrer les champs
      for (const field of fieldsToUnregister) {
        unregister(field);
        reset(
          {
            [field]: "",
          },
          {
            keepValues: true, // Garde les valeurs des autres champs inchangées
          }
        );
      }
    }
    if (
      fieldName === "preTransactionCertification" ||
      fieldName === "postTransactionCertification"
    ) {
      setPreTransactionChecked(value);
      setPreTransactionRequired(false);
    }
  };

  useEffect(() => {
    if (
      formData?.preTransactionCertification === true ||
      formData?.preTransactionCertification === false
    ) {
      setPreTransactionChecked(formData?.preTransactionCertification);
      setPreTransactionRequired(false);
    }
  }, [formData?.preTransactionCertification]);
  return (
    <>
      <div className="flex mb-3 mt-10 flight">
        <div className="col pl-0">
          <TextInput
            label="Estimated number of flights to be certified"
            onChange={(e) =>
              handleChangeInput("numberOfTranscations", e.target.value)
            }
            register={{
              ...register("numberOfTranscations", { required: true }),
            }}
            name="numberOfTranscations"
            defaultValue={formData?.numberOfTranscations}
            placeholder="Estimated number of flights to be certified"
          />
          {errors.numberOfTranscations &&
            errors.numberOfTranscations.type === "required" && (
              <Error
                key={errorCount}
                text={fieldRequiredMessage("Number Of Flights")}
              />
            )}
        </div>
        <div className="col">
          <TextInput
            type="date"
            onChange={(e) => handleChangeInput("shippingDate", e.target.value)}
            register={{
              ...register("shippingDate", { required: true }),
            }}
            name="estimatedFirstTransDate"
            defaultValue={
              formData?.shippingDate && formatDate(formData?.shippingDate)
            }
            label="First flight(s) dates"
            placeholder="mm/dd/yy"
          />
          {errors.shippingDate && errors.shippingDate.type === "required" && (
            <Error
              key={errorCount}
              text={fieldRequiredMessage("First flight(s) dates")}
            />
          )}
        </div>

        <div className="col pr-0">
          <TextInput
            onChange={(e) => handleChangeInput("origin", e.target.value)}
            register={{
              ...register("origin", { required: true }),
            }}
            name="origin"
            defaultValue={formData?.origin}
            label="Origin"
            placeholder="Location"
          />
          {errors.origin && errors.origin.type === "required" && (
            <Error key={errorCount} text={fieldRequiredMessage("Location")} />
          )}
        </div>
      </div>

      {/*Second input*/}

      <div className="flex mt-3">
        <div className="col pl-0">
          <TextInput
            onChange={(e) => handleChangeInput("arrival", e.target.value)}
            register={{
              ...register("arrival", { required: true }),
            }}
            name="arrival"
            defaultValue={formData?.arrival}
            label="Arrival"
            placeholder="Arrival"
          />
          {errors.arrival && errors.arrival.type === "required" && (
            <Error key={errorCount} text={fieldRequiredMessage("Arrival")} />
          )}
        </div>

        <div className="col">
          <TextInput
            onChange={(e) => handleChangeInput("airCraftType", e.target.value)}
            register={{
              ...register("airCraftType", { required: true }),
            }}
            name="airCraftType"
            defaultValue={formData?.airCraftType}
            label="Aircraft type"
            placeholder="Aircraft type"
          />
          {errors.airCraftType && errors.airCraftType.type === "required" && (
            <Error
              key={errorCount}
              text={fieldRequiredMessage("Aircraft Type")}
            />
          )}
        </div>
      </div>

      {/*Fourth input*/}

      <div className="mt-4">
        <div className="flex mb-3">
          <LabelInput label="Certification" />
        </div>
        <div className="flex mb-3 nospace">
          <DefaultRadioInput
            onChange={(e) =>
              handleChangeInput("preTransactionCertification", true)
            }
            register={{
              ...register("preTransactionCertification", {
                required: preTransactionRequired,
              }),
            }}
            defaultChecked={preTransactionChecked === true}
            value={true}
            name="preTransactionCertification"
            label="Pre-Transaction(s) Certification"
            id="pre"
            htmlFor="pre"
          />
          <InfoPopup text={preTransactionInfoOtherService} />
        </div>

        <div className="flex mb-3 nospace">
          <DefaultRadioInput
            onChange={(e) =>
              handleChangeInput("preTransactionCertification", false, [])
            }
            register={{
              ...register("postTransactionCertification", {
                required: preTransactionRequired,
              }),
            }}
            defaultChecked={preTransactionChecked === false}
            value={false}
            name="postTransactionCertification"
            label="Post-Transaction(s) Certification"
            id="post"
            htmlFor="post"
          />
        </div>
      </div>
      {errors.preTransactionCertification &&
        errors.preTransactionCertification.type === "required" &&
        errors.postTransactionCertification &&
        errors.postTransactionCertification.type === "required" && (
          <Error
            key={errorCount}
            text={fieldRequiredMessage("Transaction Certification")}
          />
        )}
      <div className="col max-w-[390px] mt-4 pl-0">
        <TextArea
          onChange={(e) =>
            handleChangeInput("transactionDescription", e.target.value)
          }
          register={{
            ...register("transactionDescription", { required: true }),
          }}
          defaultValue={formData?.transactionDescription}
          name="transactionDescription"
          label="Additional information"
          placeholder="Additional information"
        />
        {errors.transactionDescription &&
          errors.transactionDescription.type === "required" && (
            <Error
              key={errorCount}
              text={fieldRequiredMessage("Additional information")}
            />
          )}
      </div>
    </>
  );
};

export default FlightServiceAdmin;
