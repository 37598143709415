import TextInput from "../../components/forms/TextInput";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  fieldRequiredMessage,
  invalidLogoFormatMessage,
  invalidMaxLengthDescription,
  invalidPasswordMessage,
  invalidPhoneNumberMessage,
  invalidSizeFile,
  invalidWebsiteURLMessage,
  maxUploadFileSize,
  passwordRequirements,
  passwordsNotMatchMessage,
  phoneNumberPattern,
} from "../../errors/errors";
import Error from "../../components/Alert/Error";
import EmailInput from "../../components/forms/EmailInput";
import PhoneInput from "../../components/forms/PhoneInput";
import PasswordInput from "../../components/forms/PasswordInput";
import { useSelector, useDispatch } from "react-redux";
import { alertActions, userActions } from "../../actions";
import { useNavigate } from "react-router-dom";
import isValidLogoFormat, {
  validPassword,
  validWebsite,
} from "../../utils/validation";
import Warning from "../../components/Alert/Warning";
import TextArea from "../../components/forms/TextArea";
import SubmitButton from "../../components/forms/Buttons/SubmitButton";

export default function ClientRegister() {
  const [passwordTyped, setPasswordTyped] = useState("");
  const [fileName, setFileName] = useState(null);
  const [fileSize, setFileSize] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const {
    handleSubmit,
    getValues,
    register,
    setValue,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);
  const isRegistered = useSelector((state) => state.registration.registered);
  const navigate = useNavigate();

  useEffect(() => {
    if (isRegistered) {
      navigate("/");
    }
  }, [isRegistered, alert, navigate]);

  useEffect(() => {
    dispatch(alertActions.clear());
  }, [dispatch]);

  useEffect(() => {
    if (alert.message) setLoading(false);
  }, [alert]);

  const updateInputValue = (fieldName, value) => {
    if (fieldName === "Password") setPasswordTyped(value);
    setValue(fieldName, value);
  };

  const onSubmitForm = async () => {
    setLoading(true);
    let userData = new FormData();
    userData.append("LastName", getValues().LastName);
    userData.append("FirstName", getValues().FirstName);
    userData.append("ClienType", getValues().ClientType);
    userData.append("Email", getValues().Email);
    userData.append("PhoneNumber", getValues().PhoneNumber);
    userData.append("Password", getValues().Password);
    userData.append("ConfirmPassword", getValues().ConfirmPassword);
    userData.append("CompanyName", getValues().CompanyName);
    userData.append("CompanyWebsite", getValues().CompanyWebsite);
    userData.append("CompanyAddress", getValues().CompanyAddress);
    userData.append("ExpertiseDescription", getValues().CompanyDescription);
    userData.append("UserRole", "Client");
    userData.append("File", file);

    dispatch(userActions.register(userData));
  };

  const onChooseFile = (e) => {
    const file = e.target.files[0];
    setFile(file);
    setFileName(file.name);
    setFileSize(file.size);
  };

  return (
    <>
      <h1 className="text-[#3D4196] font-bold text-center text-lg">
        Applicant for certification
      </h1>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <input type="number" hidden value="1" {...register("ClientType")} />
        <div className="create mx-8 px-36 py-9 shadow-2xl mt-6 grid grid-cols-3 mb-9 rounded-md">
          <div className="w-full">
            <h2 className="title font-bold mb-3 text-[#3D4196] text-lg">
              Upload your company logo
            </h2>
            <div className="upload relative overflow-hidden w-3/4 mb-4 hover:cursor-pointer">
              <button className="hover:cursor-pointer login flex justify-center items-center text-mg border-0 bg-[#3BB34B] rounded-lg hover:bg-[#329940] text-white w-full py-3">
                <img
                  src="/assets/icons/upload.svg"
                  alt=""
                  className="upload w-[18px] mr-2"
                />
                Upload file
              </button>
              <input
                className="hover:cursor-pointer absolute right-0 bottom-0 top-0 left-0 opacity-0"
                type="file"
                name="File"
                id="File"
                {...register("File")}
                onChange={onChooseFile}
              />
              <p className="mt-2 text-sm text-gray">
                {fileName ? fileName : "only .jpg or .png files are accepted"}
              </p>
              {fileName && !isValidLogoFormat(fileName) && (
                <Error key={errorCount} text={invalidLogoFormatMessage} />
              )}
              {fileSize && fileSize > maxUploadFileSize && (
                <Error key={errorCount} text={invalidSizeFile} />
              )}
            </div>

            <div className="login-input w-full mb-4">
              <TextInput
                type="text"
                placeholder="Company Name"
                label="Company Name"
                name="companyName"
                id="companyName"
                title="COMPANY DETAILS"
                register={{ ...register("CompanyName", { required: true }) }}
                onChange={(e) =>
                  updateInputValue("CompanyName", e.target.value)
                }
              />
              {errors.CompanyName && errors.CompanyName.type === "required" && (
                <Error
                  key={errorCount}
                  text={fieldRequiredMessage("Company Name")}
                />
              )}
            </div>

            <div className="login-input w-full mb-4">
              <TextInput
                type="text"
                placeholder="www.xxxxxxxxx.domain"
                label="Company Website"
                name="CompanyWebsite"
                id="CompanyWebsite"
                register={{
                  ...register("CompanyWebsite", {
                    required: true,
                    pattern: validWebsite,
                  }),
                }}
                onChange={(e) =>
                  updateInputValue("CompanyWebsite", e.target.value)
                }
              />
              {errors.CompanyWebsite &&
                errors.CompanyWebsite.type === "pattern" && (
                  <Error key={errorCount} text={invalidWebsiteURLMessage} />
                )}
              {errors.CompanyWebsite &&
                errors.CompanyWebsite.type === "required" && (
                  <Error
                    key={errorCount}
                    text={fieldRequiredMessage("Company Website")}
                  />
                )}
            </div>

            <div className="login-input w-full mb-4">
              <TextArea
                placeholder="Company Address"
                label="Company Address"
                name="companyAddress"
                id="companyAddress"
                register={{ ...register("CompanyAddress", { required: true }) }}
                onChange={(e) =>
                  updateInputValue("CompanyAddress", e.target.value)
                }
              />
              {errors.CompanyAddress &&
                errors.CompanyAddress.type === "required" && (
                  <Error
                    key={errorCount}
                    text={fieldRequiredMessage("Company Address")}
                  />
                )}
            </div>
            <div className="login-input w-full my-2">
              <TextArea
                name="CompanyDescription"
                id="CompanyDescription"
                register={{
                  ...register("CompanyDescription", {
                    maxLength: 200,
                    required: true,
                  }),
                }}
                onChange={(e) =>
                  updateInputValue("CompanyDescription", e.target.value)
                }
                placeholder="200 characters max"
                label="Company description"
              />
              {errors.CompanyDescription &&
                errors.CompanyDescription.type === "required" && (
                  <Error
                    key={errorCount}
                    text={fieldRequiredMessage("Company Description")}
                  />
                )}
              {errors.CompanyDescription &&
                errors.CompanyDescription.type === "maxLength" && (
                  <Error key={errorCount} text={invalidMaxLengthDescription} />
                )}
            </div>
          </div>

          <div className="empty"></div>

          <div className="w-full right flex flex-col justify-between">
            <div className="login-input w-full mb-4">
              <TextInput
                type="text"
                title="CONTACT DETAILS"
                placeholder="Your First Name"
                label="First Name"
                name="firstName"
                id="firstName"
                register={{
                  ...register("FirstName", { required: true, maxLength: 30 }),
                }}
                onChange={(e) => updateInputValue("FirstName", e.target.value)}
              />
              {errors.FirstName && errors.FirstName.type === "required" && (
                <Error
                  key={errorCount}
                  text={fieldRequiredMessage("First Name")}
                />
              )}
            </div>

            <div className="login-input w-full mb-4">
              <TextInput
                type="text"
                placeholder="Your Last Name"
                label="Last name"
                name="lastName"
                id="lastName"
                register={{
                  ...register("LastName", { required: true, maxLength: 30 }),
                }}
                onChange={(e) => updateInputValue("LastName", e.target.value)}
              />
              {errors.LastName && errors.LastName.type === "required" && (
                <Error
                  key={errorCount}
                  text={fieldRequiredMessage("Last Name")}
                />
              )}
            </div>

            <div className="login-input w-full mb-4">
              <PhoneInput
                register={{
                  ...register("PhoneNumber", {
                    pattern: phoneNumberPattern,
                    required: true,
                  }),
                }}
                errors={
                  (errors.PhoneNumber &&
                    errors.PhoneNumber.type === "pattern" && (
                      <Error
                        key={errorCount}
                        text={invalidPhoneNumberMessage}
                      />
                    )) ||
                  (errors.PhoneNumber &&
                    errors.PhoneNumber.type === "required" && (
                      <Error
                        key={errorCount}
                        text={fieldRequiredMessage("Phone Number")}
                      />
                    ))
                }
                onChange={(e) =>
                  updateInputValue("PhoneNumber", e.target.value)
                }
              />
            </div>

            <div className="wrapper">
              <div className="login-input w-full mb-4">
                <EmailInput
                  register={{ ...register("Email", { required: true }) }}
                  onChange={(e) => setValue("Email", e.target.value)}
                  errors={
                    errors.Email &&
                    errors.Email.type === "required" && (
                      <Error
                        key={errorCount}
                        text={fieldRequiredMessage("Email Address")}
                      />
                    )
                  }
                />
              </div>

              <div className="mb-4">
                <div className="login-input w-full relative">
                  <PasswordInput
                    id="pass1"
                    name="pass1"
                    label="Password"
                    register={{
                      ...register("Password", {
                        required: true,
                        pattern: validPassword,
                      }),
                    }}
                    onChange={(e) =>
                      updateInputValue("Password", e.target.value)
                    }
                  />
                </div>
                {errors.Password && errors.Password.type === "required" && (
                  <Error
                    key={errorCount}
                    text={fieldRequiredMessage("Password")}
                  />
                )}
                {errors.Password && errors.Password.type === "pattern" && (
                  <Error key={errorCount} text={invalidPasswordMessage} />
                )}
              </div>

              <div className="mb-4">
                <div className="login-input w-full relative">
                  <PasswordInput
                    id="pass2"
                    name="pass2"
                    label="Password verification"
                    register={{
                      ...register("ConfirmPassword", {
                        required: true,
                        validate: (val) => val === passwordTyped,
                      }),
                    }}
                    onChange={(e) =>
                      updateInputValue("ConfirmPassword", e.target.value)
                    }
                  />
                </div>
                {errors.ConfirmPassword &&
                  errors.ConfirmPassword.type === "required" && (
                    <Error
                      key={errorCount}
                      text={fieldRequiredMessage("Password Verification")}
                    />
                  )}
                {errors.ConfirmPassword &&
                  errors.ConfirmPassword.type === "validate" && (
                    <Error key={errorCount} text={passwordsNotMatchMessage} />
                  )}

                <Warning text={passwordRequirements} />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div>
              {/* <button className="bg-[#3BB34B] mt-4 text-white px-4 py-3 hover:bg-[#329940] text-center">
              Submit
            </button> */}
            </div>
          </div>
          <div className="flex items-center justify-center">
            <SubmitButton
              loading={loading}
              onClick={() => setErrorCount(errorCount + 1)}
            />
            {alert.message && alert.type === "alert-danger" && (
              <Error key={errorCount} text={alert.message} />
            )}
          </div>
        </div>
      </form>
    </>
  );
}
