import "../Form.css";
import { useState } from "react";
import ServiceFirstStep from "./ServiceFirstStep";
import SecondStep from "../CommonStep/SecondStep";
import ThirdStep from "../CommonStep/ThirdStep";
import FourthStep from "../CommonStep/FourthStep";
import FifthStep from "../CommonStep/FifthStep";
import { useDispatch } from "react-redux";
import { PreviousButton } from "../../../components/forms/Buttons/PreviousButton";
import { NextButton } from "../../../components/forms/Buttons/NextButton";
import { useForm } from "react-hook-form";
import { fieldTypeOfDate, fieldTypeOfNumber } from "../../../utils/constant";
import { getServiceTypeValue } from "../../../utils/enum";
import StepCompleted from "../CommonStep/StepCompleted";
import { useNavigate } from "react-router-dom";
import { demandcertificationService } from "../../../services/demandcertification.service";
import Error from "../../../components/Alert/Error";

const ServiceForm = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const { formData, setFormData } = props;
  const [currentStepItem, setStep] = useState(1);
  const items = ["1", "2", "3", "4", "5"];
  const [title, nextTitle] = useState(1);
  const [formType, setFormType] = useState();
  const [serviceType, setServiceType] = useState();
  const [showing, setShowing] = useState(0);
  const [errorCount, setErrorCount] = useState(0);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    reset,
    unregister,
    getValues,
  } = useForm({ shouldUnregister: true });

  // const createCommodity = () => {
  //   return { type: certificationConstants.CREATE_COMMODITY };
  // };
  // useEffect(() => {
  //   dispatch(createCommodity());
  // }, [dispatch]);
  const handleSubmitData = (data) => {
    data.estimatedFirstTransDate =
      data.estimatedFirstTransDate !== null ||
      data.estimatedFirstTransDate !== "" ||
      data.estimatedFirstTransDate !== undefined
        ? new Date(data.estimatedFirstTransDate)
        : null;
    data.shippingDate =
      data.shippingDate !== null ||
      data.shippingDate !== "" ||
      data.shippingDate !== undefined
        ? new Date(data.shippingDate)
        : null;
    data.CocUserId = user?.data.id;
    data.certificationType = 3;
    data.serviceType = getServiceTypeValue(formType);
    // Set null fields to false
    const updatedFormData = { ...data };
    for (const field in updatedFormData) {
      if (
        updatedFormData[field] === null &&
        !fieldTypeOfNumber.includes(field) &&
        !fieldTypeOfDate.includes(field)
      ) {
        updatedFormData[field] = false;
      }

      if (
        (fieldTypeOfNumber.includes(field) &&
          updatedFormData[field] === "N/A") ||
        (fieldTypeOfNumber.includes(field) &&
          updatedFormData[field] === null) ||
        (fieldTypeOfNumber.includes(field) && updatedFormData[field] === "")
      ) {
        updatedFormData[field] = 0;
      }
    }
    setLoading(true);
    // Handle form submission with updatedFormData
    demandcertificationService
      .createCommodity(updatedFormData)
      .then((res) => {
        if (res.id) {
          setLoading(false);
          navigate("/dashboard");
          dispatch({ type: "CLEAR_FORM_DATA" });
        } else {
          setLoading(false);
          setError("an error occured.");
        }
      })
      .catch((error) => {
        setLoading(false);
        setError("an error occured.");
      });
    //navigate("/dashboard");
  };

  const saveFirstStepData = (data) => {
    // Set null fields to false
    const updatedFormData = { ...data };
    const orderFieldServiceVoyage = [
      "numberOfTranscations",
      "shippingDate",
      "origin",
      "isBallastVoyage",
      "ballastVoyageDescription",
      "vesselType",
      "vesselName",
      "typeOfFuel",
      "fuelConsumed",
      "freightDescription",
      "preTransactionCertification",
      "transactionDescription",
    ];

    const orderFieldServiceTimeChartering = [
      "vesselType",
      "vesselName",
      "contractTimePeriod",
      "typeOfFuel",
      "fuelConsumed",
      "transactionDescription",
    ];

    const orderFieldServiceFlight = [
      "numberOfTranscations",
      "shippingDate",
      "origin",
      "arrival",
      "airCraftType",
      "preTransactionCertification",
      "transactionDescription",
    ];

    const orderFieldServiceTimeFlight = [
      "contractTimePeriod",
      "airCraftType",
      "preTransactionCertification",
      "transactionDescription",
    ];

    for (const field in updatedFormData) {
      if (formType === "Voyage") {
        for (const voyageField of orderFieldServiceVoyage) {
          if (field === voyageField && updatedFormData[field] === "") {
            updatedFormData[field] = "N/A";
          }
        }
      }

      if (formType === "Time") {
        for (const timeField of orderFieldServiceTimeChartering) {
          if (field === timeField && updatedFormData[field] === "") {
            updatedFormData[field] = "N/A";
          }
        }
      }

      if (formType === "Flight") {
        for (const flightField of orderFieldServiceFlight) {
          if (field === flightField && updatedFormData[field] === "") {
            updatedFormData[field] = "N/A";
          }
        }
      }

      if (formType === "TimeFlight") {
        for (const timeFlightField of orderFieldServiceTimeFlight) {
          if (field === timeFlightField && updatedFormData[field] === "") {
            updatedFormData[field] = "N/A";
          }
        }
      }
    }
    setFormData(updatedFormData);
  };

  const handleFormTypeChoosed = (formTypeChoosen) => {
    setFormType(formTypeChoosen);
  };
  const handleServiceTypeChoosed = (formTypeChoosen) => {
    setServiceType(formTypeChoosen);
    setFormType(null);
  };

  function showStep() {
    switch (showing) {
      case 0:
        return (
          <ServiceFirstStep
            formData={formData}
            setFormData={setFormData}
            onChangeFormType={handleFormTypeChoosed}
            formTyped={formType}
            errorCount={errorCount}
            errors={errors}
            register={register}
            setValue={setValue}
            reset={reset}
            unregister={unregister}
            onChangeServiceType={handleServiceTypeChoosed}
            serviceTyped={serviceType}
          />
        );
      case 1:
        return (
          <SecondStep
            formData={formData}
            setFormData={setFormData}
            errorCount={errorCount}
            errors={errors}
            register={register}
            setValue={setValue}
            unregister={unregister}
          />
        );
      case 2:
        return (
          <ThirdStep
            formData={formData}
            setFormData={setFormData}
            errorCount={errorCount}
            errors={errors}
            register={register}
            setValue={setValue}
            unregister={unregister}
          />
        );
      case 3:
        return (
          <FourthStep
            formData={formData}
            setFormData={setFormData}
            errorCount={errorCount}
            errors={errors}
            register={register}
            setValue={setValue}
          />
        );
      case 4:
        return <FifthStep formData={formData} />;
      case 5:
        return <StepCompleted />;
      default:
        return null;
    }
  }

  function showSubtitle() {
    switch (currentStepItem) {
      case 1:
        return <span>Service description</span>;
      case 2:
        return <span>Carbon Footprint calculation</span>;
      case 3:
        return <span>Assurance provider</span>;
      case 4:
        return <span>Carbon Offset</span>;
      case 5:
        return <span>Recapitulation</span>;
      default:
        return null;
    }
  }

  const handleNextButton = (data) => {
    currentStepItem < items.length && setStep((prev) => prev + 1);
    if (currentStepItem < 4 && title > 0) {
      nextTitle((prev) => prev + 1);
    }
    if (currentStepItem === 1) {
      saveFirstStepData(formData);
    }

    if (currentStepItem !== 5) {
      setShowing((prev) => prev + 1);
    }
    if (currentStepItem === 5) {
      handleSubmitData(formData);
    }
  };

  const resetFieldOnStep = () => {
    const values = getValues();
    let fieldsToUnregister = [];
    if (currentStepItem === 2) {
      fieldsToUnregister = [
        "nameOfServiceProvideCFC",
        "contactAssuranceProvider",
        "emailOfServiceProvider",
      ];
    }
    if (currentStepItem === 3) {
      fieldsToUnregister = [
        "anotherNameOfAssuranceProvider",
        "anotherContactOfAssuranceProvider",
        "anotherEmailOfAssuranceProvider",
      ];
    }
    // Désenregistrer les champs
    for (const field of fieldsToUnregister) {
      if (!values[field]) {
        unregister(field);
      }
    }
  };

  const handlePreviousButton = () => {
    resetFieldOnStep();
    setShowing((prev) => prev - 1);
    !(title === items.length && title > 1 && currentStepItem < 5) &&
      setStep((prev) => prev - 1);
    if (currentStepItem > 0) {
      nextTitle((prev) => prev - 1);
    }
  };

  const onSubmit = (data) => {
    if (Object.keys(errors).length === 0) {
      handleNextButton();
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mx-12 service">
        {/*Title and header bar*/}
        <h1 className="font-bold text-2xl text-coc-blue text-center">
          Application Form : Carbon Offset Service
        </h1>
        <div className="ml-4 mt-9">
          <h2 className="font-bold text-2xl relative before:block before:absolute before:w-2 before:h-2 before:rounded-full before:bg-amber-400 before:top-3 before:-left-5">
            Step {currentStepItem}/5
          </h2>
          <p className="text-grey text-md font-medium">{showSubtitle()}</p>
        </div>

        <hr className="bg-light-grey w-full mt-4 h-[2px] border-none" />
        {/*Stepper*/}
        <div className="flex justify-between">
          <div className="stepper">
            <div className="form-steps relative">
              {items.map((item, i) => (
                <p
                  key={i}
                  className={`item z-10 ${
                    currentStepItem === i + 1 && "active"
                  } ${i + 1 < currentStepItem && "complete"}`}
                >
                  {item}
                </p>
              ))}
              <div className="line absolute w-[2px] h-full bg-light-grey left-5 -top-5 z-0"></div>
            </div>
          </div>
          {showStep()}
        </div>
        <div className="flex justify-between ml-32 text-white text-xs items-center mb-4 rounded-lg">
          {/*Previous button*/}
          {currentStepItem !== 1 && currentStepItem <= 5 && (
            <PreviousButton type={`button`} onClick={handlePreviousButton} />
          )}

          {/*Next button*/}
          {(serviceType === "Other" || (serviceType && formType)) &&
            currentStepItem <= 5 && (
              <NextButton
                type={`submit`}
                onClick={() => setErrorCount(errorCount + 1)}
                step={currentStepItem}
                loading={loading}
              />
            )}
        </div>
        {error && (
          <div className="ml-5 text-center">
            <Error key={errorCount} text={error} />
          </div>
        )}
      </div>
    </form>
  );
};

export default ServiceForm;
