import { useDispatch, useSelector } from "react-redux";
import { certificationActions } from "../../actions/certification.action";
import { useEffect, useState } from "react";
import { CertificationStatusENUM } from "../../utils/enum";
import Button from "../forms/Buttons/Button";
import { useNavigate } from "react-router-dom";
import Error from "../Alert/Error";

export default function UserActionButtons({
  certificationId,
  currentStep,
  setShowError,
  totalCredit,
  buttonText,
}) {
  const user = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const certificationSteps = useSelector(
    (state) => state.certificationStep.arrayCertification
  );
  const certificationAllData = useSelector(
    (state) => state.certificationStep.allData
  );
  const sentToCocFiles = useSelector((state) => state.sendCocFiles.data);
  const navigate = useNavigate();

  const [certificationStep, setCertificationStep] = useState();
  const [sendCocFilesLoading, setSendCocFilesLoading] = useState(false);
  const [validateLoading, setValidateLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [showValidationError, setShowValidationError] = useState(false);
  const [errorCount, setErrorCount] = useState(0);

  const conditionToDisplaySendToCOC_Calculation =
    certificationStep?.processStep === 1 &&
    certificationStep?.attachments?.length > 0 &&
    (certificationAllData?.certificationStatus ===
      CertificationStatusENUM.PENDING ||
      certificationAllData?.certificationStatus ===
        CertificationStatusENUM.REFUSED_BY_COC) &&
    user?.data.userRole !== "COC" &&
    user?.data.userRole !== "VAP";

  const conditionToDisplaySendToCOC_Verification =
    certificationStep?.processStep === 2 &&
    certificationStep?.attachments?.length > 0 &&
    (certificationAllData?.certificationStatus ===
      CertificationStatusENUM.PENDING ||
      certificationAllData?.certificationStatus ===
        CertificationStatusENUM.REFUSED_BY_COC) &&
    user?.data.userRole !== "COC";

  const conditionToDisableSendToCOC = !(
    (certificationStep?.isSentToCOC &&
      certificationAllData?.certificationStatus ===
        CertificationStatusENUM.REFUSED_BY_COC) ||
    !certificationStep?.isSentToCOC
  );

  const conditionToDisableValidation =
    certificationStep?.isValidated ||
    certificationAllData?.certificationStatus ===
      CertificationStatusENUM.REFUSED_BY_COC ||
    !certificationStep?.isSentToCOC;

  const conditionToEnableValidateButton =
    certificationStep?.attachments.length > 0 &&
    !certificationStep?.isValidated;

  const handleBackToDashboard = () => {
    navigate("/dashboard");
  };

  useEffect(() => {
    if (
      sentToCocFiles?.certificationId !== null &&
      sentToCocFiles?.certificationId === certificationId
    ) {
      setSendCocFilesLoading(false);
      dispatch(certificationActions.getCertificationStepByCID(certificationId));
    }
  }, [sentToCocFiles, certificationId, dispatch]);

  useEffect(() => {
    if (certificationSteps.length > 0) {
      const footprintCalculationStep = certificationSteps.filter(
        (certif) => certif.processStep === currentStep
      );

      if (footprintCalculationStep.length > 0) {
        setCertificationStep(footprintCalculationStep[0]);
      } else {
        setCertificationStep(certificationSteps[0]);
      }
    }
  }, [
    certificationSteps,
    currentStep,
    user?.data.userRole,
    certificationStep?.isSentToCOC,
  ]);

  const handleValidateStep = () => {
    setErrorCount(errorCount + 1);
    if (currentStep === 2) {
      if (
        totalCredit !== "" &&
        totalCredit !== null &&
        totalCredit !== undefined
      ) {
        setValidateLoading(true);
        dispatch(
          certificationActions.validateStep({
            stepId: certificationStep.attachments[0].certificationStepId,
            certificationId: certificationStep.attachments[0].certificationId,
            step: certificationStep.processStep,
            carbonCo2Value: totalCredit,
          })
        );
      } else setShowError(true);
    } else {
      setValidateLoading(true);
      dispatch(
        certificationActions.validateStep({
          stepId: certificationStep.attachments[0].certificationStepId,
          certificationId: certificationStep.attachments[0].certificationId,
          step: certificationStep.processStep,
        })
      );
    }
  };

  // const handleValidateStep = () => {
  //   dispatch(
  //     certificationActions.validateStep({
  //       stepId: certificationStep.attachments[0].certificationStepId,
  //       certificationId: certificationStep.attachments[0].certificationId,
  //       step: certificationStep.processStep,
  //     })
  //   );
  // }

  const handleValidateUploads = () => {
    if (currentStep === 2) {
      if (
        totalCredit !== "" &&
        totalCredit !== null &&
        totalCredit !== undefined
      ) {
        setSendCocFilesLoading(true);
        dispatch(
          certificationActions.sendToCOCFiles({
            stepId: certificationStep?.id,
            certificationId,
            step: currentStep,
            carbonCo2Value: totalCredit,
          })
        );
      } else setShowError(true);
    } else {
      setSendCocFilesLoading(true);
      dispatch(
        certificationActions.sendToCOCFiles({
          stepId: certificationStep?.id,
          certificationId,
          step: currentStep,
        })
      );
    }
  };

  // const handleValidateUploads = () => {
  //   dispatch(
  //     certificationActions.sendToCOCFiles({
  //       stepId: certificationStep?.id,
  //       certificationId,
  //       step: currentStep,
  //     })
  //   );
  // };

  const handleRejectStep = () => {
    setRejectLoading(true);
    dispatch(
      certificationActions.rejectStep({
        stepId: certificationStep?.id,
        certificationId,
        step: currentStep,
      })
    );
  };

  useEffect(() => {
    if (conditionToDisableValidation) {
      setRejectLoading(false);
    }
    if (!conditionToEnableValidateButton) {
      setValidateLoading(false);
    }
  }, [conditionToDisableValidation, conditionToEnableValidateButton]);

  return (
    <>
      <div className="flex justify-center w-full my-14">
        {(currentStep === 1
          ? user?.data.userRole === "COC" || user?.data.userRole === "VAP"
          : user?.data.userRole === "COC") && (
          <div className={`flex justify-between mb-3`}>
            <div className="mr-2">
              <Button
                disabled={conditionToDisableValidation}
                onClick={handleRejectStep}
                text={`Reject`}
                backgroundColor={`coc-blue`}
                hoverBgColor={`bg-coc-blue-600`}
                loading={rejectLoading}
              />
            </div>
            <Button
              disabled={!conditionToEnableValidateButton}
              onClick={handleValidateStep}
              text={`Validate`}
              backgroundColor={`coc-green`}
              hoverBgColor={`bg-[#329940]`}
              loading={validateLoading}
            />
          </div>
        )}
        {(currentStep === 1
          ? conditionToDisplaySendToCOC_Calculation
          : conditionToDisplaySendToCOC_Verification) &&
          !conditionToDisableSendToCOC && (
            <Button
              //disabled={conditionToDisableSendToCOC}
              className={`right-4 px-9 mb-9 py-3 bg-coc-blue text-white capitalize flex text-sm items-center hover:bg-[#2c3198] rounded-lg`}
              onClick={handleValidateUploads}
              text={buttonText}
              backgroundColor={`coc-blue`}
              hoverBgColor={`bg-[#2c3198]`}
              loading={sendCocFilesLoading}
            />
          )}
        {(currentStep === 1
          ? conditionToDisplaySendToCOC_Calculation
          : conditionToDisplaySendToCOC_Verification) &&
          conditionToDisableSendToCOC && (
            <Button
              className={`right-4 px-9 mb-9 py-3 bg-coc-blue text-white capitalize flex text-sm items-center hover:bg-[#2c3198] rounded-lg`}
              onClick={handleBackToDashboard}
              text={"Back to Dashboard"}
              backgroundColor={`coc-blue`}
              hoverBgColor={`bg-[#2c3198]`}
            />
          )}
      </div>
      {showValidationError && (
        <div className={`px-11 py-3 flex text-sm items-center`}>
          <Error
            key={errorCount}
            text={`Please add at least one file to Validate.`}
          />
        </div>
      )}
    </>
  );
}
