import { useDispatch, useSelector } from "react-redux";
import DeleteFile from "./DeleteFile";
import DownloadFile from "./DownloadFile";
import { useEffect, useRef, useState } from "react";
import { certificationActions } from "../../actions/certification.action";
import { CertificationStatusENUM } from "../../utils/enum";
import Lottie from "lottie-react";
import checkedAnimation from "./upload.json";
import disableddAnimation from "./disabled.json";
import Error from "../Alert/Error";
import loading from "./upload-files-loader.json";

export default function UploadCertification({
  filesUploaded,
  certificationId,
  currentStep,
}) {
  const user = JSON.parse(localStorage.getItem("user"));
  const fileInputRef2 = useRef(null);
  const [files, setFiles] = useState(filesUploaded);
  const [certificationStep, setCertificationStep] = useState();
  const [showExtensionError, setShowExtensionError] = useState(false);
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [certificateUploaded, setCertificateUploaded] = useState(false);
  const dispatch = useDispatch();
  const isFileUploaded = useSelector((state) => state.uploadCertification.file);
  const isFileRemoved = useSelector((state) => state.removeFile.removeSuccess);
  const validateStep = useSelector((state) => state.validateStep.data);
  const certificationSteps = useSelector(
    (state) => state.certificationStep.arrayCertification
  );
  const certificationAllData = useSelector(
    (state) => state.certificationStep.allData
  );
  const rejectStep = useSelector((state) => state.rejectStep.data);
  const sentToCocFiles = useSelector((state) => state.sendCocFiles.data);

  useEffect(() => {
    if (
      isFileRemoved ||
      isFileUploaded ||
      validateStep ||
      rejectStep ||
      sentToCocFiles
    ) {
      dispatch(certificationActions.getCertificationStepByCID(certificationId));
      if (
        isFileUploaded?.length > 0 &&
        isFileUploaded[0].certificationId === certificationId
      ) {
        setIsLoadingUpload(false);
        dispatch({ type: "CLEAR_REMOVE_FILE" });
        //setCertificateUploaded(true);
      }
      if (isFileRemoved) {
        setCertificateUploaded(false);
      }
    }
  }, [
    isFileRemoved,
    dispatch,
    certificationId,
    isFileUploaded,
    validateStep,
    rejectStep,
    sentToCocFiles,
  ]);

  useEffect(() => {
    if (certificationSteps.length > 0) {
      const footprintVerificationStep = certificationSteps.filter(
        (certif) => certif.processStep === currentStep
      );
      if (footprintVerificationStep.length > 0) {
        setCertificationStep(footprintVerificationStep[0]);
        setFiles(footprintVerificationStep[0].attachments);
      } else {
        setCertificationStep(certificationSteps[0]);
      }
    }
  }, [certificationSteps, currentStep]);

  const handleButtonClick = () => {
    fileInputRef2.current.click();
  };
  const handleFileInputChange = (event) => {
    setCertificateUploaded(true);
    setIsLoadingUpload(true);
    setErrorCount(errorCount + 1);
    let uploadData = new FormData();
    const file = event.target.files[0];
    const fileName = file?.name;
    const fileExtension = fileName?.split(".").pop();

    if (fileExtension === "pdf") {
      uploadData.append("data[0].File", file);
      setFiles((prevFiles) => [...prevFiles, file]);
      uploadData.append("data[0].ProcessStep", 4);
      uploadData.append("data[0].CertificationId", certificationId);
      dispatch(certificationActions.uploadCertification(uploadData));
    } else {
      setShowExtensionError(true);
    }
  };

  return (
    <>
      <div className="border-2 px-4 border-solid pb-4">
        {isLoadingUpload && (
          <div className="upload-overlay">
            <Lottie
              animationData={loading}
              style={{ width: "250px", height: "250px" }}
            />
          </div>
        )}
        <button
          className="mx-auto text-white capitalize flex text-sm items-center]"
          onClick={handleButtonClick}
        >
          {user?.data.userRole !== "COC" ||
          (certificationStep?.processStep !== 0 &&
            certificationStep?.processStep === currentStep &&
            certificationStep?.attachments?.length > 0) ||
          certificateUploaded === true ? (
            <Lottie
              /*loop={false}*/ style={{ width: "100px", height: "100px" }}
              animationData={disableddAnimation}
            />
          ) : (
            <Lottie
              /*loop={false}*/ style={{ width: "100px", height: "100px" }}
              animationData={checkedAnimation}
            />
          )}
        </button>
        <input
          type="file"
          ref={fileInputRef2}
          style={{ display: "none" }}
          onChange={handleFileInputChange}
          accept=".pdf"
          className={user?.data.userRole !== "COC" ? `cursor-not-allowed` : ""}
          disabled={
            user?.data.userRole !== "COC" ||
            (certificationStep?.processStep !== 0 &&
              certificationStep?.processStep === currentStep &&
              certificationStep?.attachments?.length > 0) ||
            certificateUploaded === true
          }
        />
        {showExtensionError && (
          <Error
            index={errorCount}
            text={`Only PDF file is accepted. Please upload only PDF file.`}
          />
        )}
        <ul className="divide-y-2 divide-gray-300">
          {files?.length > 0 &&
            files?.map((file, index) => (
              <li key={index} className="py-2 flex justify-between">
                <DownloadFile file={file} />
                {((certificationStep?.processStep === currentStep &&
                  !certificationStep?.isValidated &&
                  !certificationStep?.isSentToCOC) ||
                  (certificationStep?.isSentToCOC &&
                    certificationAllData?.certificationStatus ===
                      CertificationStatusENUM.REFUSED_BY_COC)) &&
                  user?.data.userRole === "COC" && <DeleteFile file={file} />}
              </li>
            ))}
        </ul>
      </div>
    </>
  );
}
