export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',


    INVITE_REQUEST: 'USERS_INVITE_REQUEST',
    INVITE_SUCCESS: 'USERS_INVITE_SUCCESS',
    INVITE_FAILURE: 'USERS_INVITE_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    RESET_LINK_REQUEST: 'USERS_RESET_LINK_REQUEST',
    RESET_LINK_SUCCESS: 'USERS_RESET_LINK_SUCCESS',
    RESET_LINK_FAILURE: 'USERS_RESET_LINK_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    GET_CERTIFICATION_LIST_REQUEST: 'GET_CERTIFICATION_LIST_REQUEST',
    GET_CERTIFICATION_LIST_SUCCESS: 'GET_CERTIFICATION_LIST_SUCCESS',
    GET_CERTIFICATION_LIST_FAILURE: 'GET_CERTIFICATION_LIST_FAILURE',

    GET_CERTIFICATION_STEPS_LIST_REQUEST: 'GET_CERTIFICATION_STEPS_LIST_REQUEST',
    GET_CERTIFICATION_STEPS_LIST_SUCCESS: 'GET_CERTIFICATION_STEPS_LIST_SUCCESS',
    GET_CERTIFICATION_STEPS_LIST_FAILURE: 'GET_CERTIFICATION_STEPS_LIST_FAILURE',

    GET_DOWNLOAD_CERTIFICATION_REQUEST: 'GET_DOWNLOAD_CERTIFICATION_REQUEST',
    GET_DOWNLOAD_CERTIFICATION_SUCCESS: 'GET_DOWNLOAD_CERTIFICATION_SUCCESS',
    GET_DOWNLOAD_CERTIFICATION_FAILURE: 'GET_DOWNLOAD_CERTIFICATION_FAILURE',

    GET_VALIDATE_STEP_REQUEST: 'GET_VALIDATE_STEP_REQUEST',
    GET_VALIDATE_STEP_SUCCESS: 'GET_VALIDATE_STEP_SUCCESS',
    GET_VALIDATE_STEP_FAILURE: 'GET_VALIDATE_STEP_FAILURE',

    GET_UPLOAD_CERT_REQUEST: 'GET_UPLOAD_CERT_REQUEST',
    GET_UPLOAD_CERT_SUCCESS: 'GET_UPLOAD_CERT_SUCCESS',
    GET_UPLOAD_CERT_FAILURE: 'GET_UPLOAD_CERT_FAILURE',
    GET_UPLOAD_CERT_CLEAR: 'GET_UPLOAD_CERT_CLEAR',

    GET_REMOVE_FILE_REQUEST: 'GET_REMOVE_FILE_REQUEST',
    GET_REMOVE_FILE_SUCCESS: 'GET_REMOVE_FILE_SUCCESS',
    GET_REMOVE_FILE_FAILURE: 'GET_REMOVE_FILE_FAILURE',

    ADD_CCR_SUCCESS: 'ADD_CCR_SUCCESS',
    ADD_CCR_FAILURE: 'ADD_CCR_FAILURE',

    GET_CCR_SUCCESS: 'GET_CCR_SUCCESS',
    GET_CCR_FAILURE: 'GET_CCR_FAILURE',

    POST_VALIDATE_CNC_REQUEST: 'POST_VALIDATE_CNC_REQUEST',
    POST_VALIDATE_CNC_SUCCESS: 'POST_VALIDATE_CNC_SUCCESS',
    POST_VALIDATE_CNC_FAILURE: 'POST_VALIDATE_CNC_FAILURE',

    UPDATE_CCR_SUCCESS: 'UPDATE_CCR_SUCCESS',
    UPDATE_CCR_FAILURE: 'UPDATE_CCR_FAILURE',

    GET_VALIDATE_CCR_STEP_SUCCESS: 'GET_VALIDATE_CCR_STEP_SUCCESS',
    GET_VALIDATE_CCR_STEP_FAILURE: 'GET_VALIDATE_CCR_STEP_FAILURE',

    GET_REJECT_STEP_SUCCESS: 'GET_REJECT_STEP_SUCCESS',
    GET_REJECT_STEP_FAILURE: 'GET_REJECT_STEP_FAILURE',

    POST_SEND_COC_FILES_SUCCESS: 'POST_SEND_COC_FILES_SUCCESS',
    POST_SEND_COC_FILES_FAILURE: 'POST_SEND_COC_FILES_FAILURE',

    GET_CERTIFICATION_INFO_SUCCESS: 'GET_CERTIFICATION_INFO_SUCCESS',

    REJECT_PROCESS_CLEAR: 'REJECT_PROCESS_CLEAR',
    
    SEND_STEP_TO_COC_PROCESS_CLEAR: 'SEND_STEP_TO_COC_PROCESS_CLEAR',

    GET_REJECT_RETIREMENT_SUCCESS: 'GET_REJECT_RETIREMENT_SUCCESS',
    GET_REJECT_RETIREMENT_FAILURE: 'GET_REJECT_RETIREMENT_FAILURE',

    DELETE_CCR_UPDATED: 'DELETE_CCR_UPDATED',

    CLEAR_CERTIFICATION_LIST: 'CLEAR_CERTIFICATION_LIST',

    ASSIGN_VVB_SUCCESS: 'ASSIGN_VVB_SUCCESS',
    ASSIGN_VVB_FAILURE: 'ASSIGN_VVB_FAILURE',

    ACCEPT_NOMINATION_VVB_SUCCESS: 'ACCEPT_NOMINATION_VVB_SUCCESS',
    ACCEPT_NOMINATION_VVB_FAILURE: 'ACCEPT_NOMINATION_VVB_FAILURE',

    REFUSE_NOMINATION_VVB_SUCCESS: 'REFUSE_NOMINATION_VVB_SUCCESS',
    REFUSE_NOMINATION_VVB_FAILURE: 'REFUSE_NOMINATION_VVB_FAILURE',

    REMOVE_USER_SUCCESS: 'REMOVE_USER_SUCCESS',
    REMOVE_USER_FAILURE: 'REMOVE_USER_FAILURE',

    CREATE_PARTNER_SUCCESS: 'CREATE_PARTNER_SUCCESS',
    CREATE_PARTNER_FAILURE: 'CREATE_PARTNER_FAILURE',

    UPDATE_CERTIFICATION_SUCCESS: 'UPDATE_CERTIFICATION_SUCCESS',
    UPDATE_CERTIFICATION_FAILURE: 'UPDATE_CERTIFICATION_FAILURE',

    UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',

    GET_USER_SUCCESS: 'GET_USER_SUCCESS',
    GET_USER_FAILURE: 'GET_USER_FAILURE',

    CLEAR_REMOVE_FILE: 'CLEAR_REMOVE_FILE'
};
