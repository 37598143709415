import authHeader from "../utils/auth-header";

export const vvbService = {
  assignVVBToCertificate,
  acceptNomination,
  refuseNomination,
};

async function assignVVBToCertificate(certificationId, idUser) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/certificate/nominatevvb?certificationId=${certificationId}&vvbUserId=${idUser}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function acceptNomination(certificationId, reject) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/certificate/validateorrejectbyvvbuser?certificationId=${certificationId}&reject=${reject}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

async function refuseNomination(certificationId, idUser) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ certificationId, idUser }),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/certificate/refuseNomination`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
