export const invalidPasswordMessage = "Your password does not meet security requirements. It must contain at least 8 characters including at least 1 uppercase, 1 lowercase, 1 number, 1 special character.";
export const passwordsNotMatchMessage = "The passwords you entered are not identical.";
export const fieldRequiredMessage = (fieldName) => {return `${fieldName} is required.`}
export const invalidWebsiteURLMessage = "Invalid website.";
export const invalidPhoneNumberMessage = "Invalid phone number. Must begins with 00 and should not contain letters.";
export const invalidLogoFormatMessage = "Please put a jpeg, gif, png file.";
export const invalidMaxLengthDescription = "The number of characters required is 200 maximum";
export const invalidSizeFile = "The file size should not exceed 512 kB.";
export const maxUploadFileSize = 524288;
export const phoneNumberPattern = /^00\d+/
export const passwordRequirements = "Password must contain at least 8 characters including at least 1 uppercase, 1 lowercase, 1 number, 1 special character."
export const numberPattern = /d+/