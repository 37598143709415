export const steps = [
    {
        id: 0,
        name: "COC application",
        url: "#"
    },
    {
        id: 1,
        name: "Carbon footprint calculation",
        url: "footprint-calculation"
    },
    {
        id: 2,
        name: "Carbon footprint verification",
        url: "footprint-verification"
    },
    {
        id: 3,
        name: "Carbon Credits Retirement",
        url: "carbon-retirement"
    },
    {
        id: 4,
        name: "COC Certification",
        url: "cnc-certification"
    }
]