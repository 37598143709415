import authHeader from "../utils/auth-header";
export const certificationService = {
  getCertificationList,
  getCertificationByUserID,
  getAllCertificationStepByCID,
  downloadCertification,
  validateStep,
  uploadCertification,
  removeFile,
  addCarbonCreditRetirement,
  getCarbonCreditRetirementById,
  validateCNCApplication,
  updateCarbonCreditRetirement,
  validateCCRStep,
  rejectStep,
  sendStepToCOC,
  validateLastStep,
  rejectRetirementStep,
};

function getCertificationList() {
  const requestOptions = {
    headers: { ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/certificate/allcertificates`,
    requestOptions
  )
    .then(handleResponse)
    .then((certifications) => {
      return certifications;
    });
}

function getCertificationByUserID(userId) {
  const requestOptions = {
    headers: { ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/certificate/certificationbyuserid?id=${userId}`,
    requestOptions
  )
    .then(handleResponse)
    .then((certifications) => {
      return certifications;
    });
}

function getAllCertificationStepByCID(certificationId) {
  const requestOptions = {
    headers: { ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/certificate/allstepbycertificationid?certificationId=${certificationId}`,
    requestOptions
  )
    .then(handleResponse)
    .then((certifications) => {
      return certifications;
    });
}

function downloadCertification(fileId) {
  const requestOptions = {
    headers: { ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/downloadbyid?attachmentId=${fileId}`,
    requestOptions,
    {
      method: "GET",
    }
  )
    .then(async (response) => {
      const fileExtension = response.headers.get("X-File-Extension");
      const fileName = response.headers.get("X-File-Name");
      const contentType = response.headers.get("Content-Type");
      const arrayBuffer = await response.arrayBuffer();
      return {
        fileExtension,
        fileName,
        contentType,
        arrayBuffer,
      };
    })
    .then((data) => {
      const file = new File([data.arrayBuffer], `${data.fileName}`, {
        type: data.contentType,
      });
      const fileUrl = URL.createObjectURL(file);

      // Use the URL to create a link and trigger a download
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = `${data.fileName}${data.fileExtension}`; // Set the file name with extension
      link.click();

      // Clean up the blob URL
      URL.revokeObjectURL(fileUrl);
    });
}

function validateStep({
  stepId,
  certificationId,
  step,
  carbonCo2Value = null,
  IdCertification = null,
}) {
  const urlStep = `${process.env.REACT_APP_API_URL}/processusstep/validatebystepid?stepId=${stepId}&certificationId=${certificationId}&step=${step}`;
  const urlFinalStep = `${process.env.REACT_APP_API_URL}/processusstep/validatebystepid?stepId=${stepId}&certificationId=${certificationId}&step=${step}&carbonCo2Value=${carbonCo2Value}`;
  const urlTopFinalStep = `${process.env.REACT_APP_API_URL}/processusstep/validatebystepid?stepId=${stepId}&certificationId=${certificationId}&step=${step}&IdCertification=${IdCertification}`;
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
  };
  const url =
    carbonCo2Value !== null
      ? urlFinalStep
      : IdCertification !== null
      ? urlTopFinalStep
      : urlStep;
  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function validateLastStep({ stepId, certificationId, step, totalCredit }) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/processusstep/validatecertification?stepId=${stepId}&certificationId=${certificationId}&step=${step}&totalCredit=${totalCredit}`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function rejectStep({ stepId, certificationId, step }) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/processusstep/rejectbystepid?stepId=${stepId}&certificatId=${certificationId}&step=${step}`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function rejectRetirementStep({ stepIds, certificationId }) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(stepIds)
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/creditretirements/rejectretirementbyid?certificatId=${certificationId}`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function validateCCRStep({ stepIds, certificationId }) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type":"application/json" },
    body: JSON.stringify(stepIds)
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/creditretirements/validateretirementbyid?certificationId=${certificationId}`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function uploadCertification(uploadData, currentStep=1) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: uploadData,
  };

  
  const url = 'upload-verification-muliple-file';
  return fetch(`${process.env.REACT_APP_API_URL}/${url}`, requestOptions).then(
    handleResponse
  );
}

function removeFile(attachmentId) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader() },
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/remove?attachmentId=${attachmentId}`,
    requestOptions
  ).then(handleResponse);
}

function addCarbonCreditRetirement(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
    },
    body: data,
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/creditretirements/create-and-send-retirement-with-upload-list`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function getCarbonCreditRetirementById(ccrId) {
  const requestOptions = {
    headers: { ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/creditretirements/getretirementbyid?id=${ccrId}`,
    requestOptions
  )
    .then(handleResponse)
    .then((ccr) => {
      return ccr;
    });
}

function updateCarbonCreditRetirement(data, id) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader() },
    body: data,
  };
  const url = `${process.env.REACT_APP_API_URL}/creditretirements/update-retirement-by-certificationid?certificationId=${id}`;

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function validateCNCApplication(certificationId) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/certificate/validatebycoc?id=${certificationId}`,
    requestOptions
  )
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function sendStepToCOC({ stepId, certificationId, step, carbonCo2Value }) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
  };
  const url = carbonCo2Value
    ? `${process.env.REACT_APP_API_URL}/processusstep/sendsteptococ?stepId=${stepId}&certificationId=${certificationId}&step=${step}&carbonCo2Value=${carbonCo2Value}`
    : `${process.env.REACT_APP_API_URL}/processusstep/sendsteptococ?stepId=${stepId}&certificationId=${certificationId}&step=${step}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((result) => {
      return result;
    });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      // if (response.status === 401) {
      //     // auto logout if 401 response returned from api
      //     return {'error': response.message}
      //     // logout();
      //     // location.reload(true);
      // }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
