import { React } from "react";
import RadioInput from "../../../components/forms/RadioInput";
import Error from "../../../components/Alert/Error";
import { fieldRequiredMessage } from "../../../errors/errors";

const FourthStep = ({
  formData,
  setFormData,
  errors,
  errorCount,
  register,
  setValue,
}) => {
  const handleChangeInput = (filedName, value) => {
    setFormData({ ...formData, [filedName]: value });
    setValue(filedName, value);
  };

  return (
    <div className="w-11/12 mt-6 mb-9 ml-32">
      <p className="">
        Does the company need support to access voluntary carbon markets, to
        purchase and retire carbon credits to offset transaction(s) or
        portfolio(s) carbon footprint?{" "}
      </p>

      <div className="flex mt-3 flexible">
        <div className="flex mb-3">
          <RadioInput
            onChange={(e) =>
              handleChangeInput(
                "needSupportToAccessVoluntaryCarbonMarkets",
                true
              )
            }
            register={{
              ...register("needSupportToAccessVoluntaryCarbonMarkets", {
                required: true,
              }),
            }}
            checked={formData.needSupportToAccessVoluntaryCarbonMarkets}
            value={true}
            name="needSupportToAccessVoluntaryCarbonMarkets"
          />
          <label className="capitalize text-sm" htmlFor="">
            Yes
          </label>
        </div>

        <div className="flex ml-4 mb-3">
          <RadioInput
            onChange={(e) =>
              handleChangeInput(
                "needSupportToAccessVoluntaryCarbonMarkets",
                false
              )
            }
            register={{
              ...register("needSupportToAccessVoluntaryCarbonMarkets", {
                required: true,
              }),
            }}
            checked={
              formData.needSupportToAccessVoluntaryCarbonMarkets === false
            }
            value={false}
            name="needSupportToAccessVoluntaryCarbonMarkets"
          />
          <label className="capitalize text-sm" htmlFor="">
            No
          </label>
        </div>
      </div>
      {errors.needSupportToAccessVoluntaryCarbonMarkets &&
        errors.needSupportToAccessVoluntaryCarbonMarkets.type ===
          "required" && (
          <Error key={errorCount} text={fieldRequiredMessage("This field")} />
        )}
    </div>
  );
};

export default FourthStep;
