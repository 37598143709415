import { useDispatch, useSelector } from "react-redux";
import DeleteFile from "./DeleteFile";
import DownloadFile from "./DownloadFile";
import { useEffect, useRef, useState } from "react";
import { certificationActions } from "../../actions/certification.action";
import Lottie from "lottie-react";
import checkedAnimation from "./upload.json";
import disableddAnimation from "./disabled.json";
import loading from "./upload-files-loader.json";
import { Tooltip } from "@material-tailwind/react";
import AlertModal from "../Modals/AlertModal";

export default function UploadFiles({
  filesUploaded,
  certificationId,
  currentStep,
  title,
  roles,
}) {
  const user = JSON.parse(localStorage.getItem("user"));
  const fileInputRef = useRef(null);
  const [files, setFiles] = useState(filesUploaded);
  const [certificationStep, setCertificationStep] = useState();
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [isMaxUploadError, setIsMaxUploadError] = useState(false);
  const dispatch = useDispatch();
  const isFileUploaded = useSelector((state) => state.uploadCertification.file);
  const uploadError = useSelector((state) => state.uploadCertification.error);
  const isFileRemoved = useSelector((state) => state.removeFile.removeSuccess);
  const validateStep = useSelector((state) => state.validateStep.data);
  const certificationSteps = useSelector(
    (state) => state.certificationStep.arrayCertification
  );
  const rejectStep = useSelector((state) => state.rejectStep.data);
  const sentToCocFiles = useSelector((state) => state.sendCocFiles.data);
  const conditionDisableInputFile =
    (certificationStep?.processStep === currentStep &&
      certificationStep?.isValidated) ||
    (certificationStep?.processStep === currentStep &&
      certificationStep?.isSentToCOC);

  const applicationRolesValidationUploadDisabled_Calculation =
    roles === "COC"
      ? (user?.data.userRole !== "COC" && user?.data.userRole !== "VAP") ||
        (certificationStep?.processStep === currentStep &&
          certificationStep?.isValidated)
      : user?.data.userRole === "COC" ||
        user?.data.userRole === "VAP" ||
        conditionDisableInputFile;

  const applicationRolesValidationUploadDisabled_Verification =
    roles === "COC"
      ? user?.data.userRole !== "COC" ||
        (certificationStep?.processStep === currentStep &&
          certificationStep?.isValidated)
      : user?.data.userRole === "COC" || conditionDisableInputFile;

  const conditionDelete =
    certificationStep?.processStep === currentStep &&
    !certificationStep?.isSentToCOC;
  const conditionDeleteCOC =
    certificationStep?.processStep === currentStep &&
    !certificationStep?.isValidated;
  const conditionDownload_Calculation =
    roles === "COC"
      ? (user?.data.userRole === "COC" || user?.data.userRole === "VAP") &&
        conditionDeleteCOC
      : user?.data.userRole !== "COC" &&
        user?.data.userRole !== "VAP" &&
        conditionDelete;

  const conditionDownload_Verification =
    roles === "COC"
      ? user?.data.userRole === "COC" && conditionDeleteCOC
      : user?.data.userRole !== "COC" && conditionDelete;
  useEffect(() => {
    if (
      isFileRemoved ||
      isFileUploaded ||
      validateStep ||
      rejectStep ||
      sentToCocFiles
    ) {
      dispatch(certificationActions.getCertificationStepByCID(certificationId));
      if (isFileUploaded) {
        setIsLoadingUpload(false);
      }
      if (isFileRemoved) {
        window.location.reload(true);
      }
    }
  }, [
    isFileRemoved,
    dispatch,
    certificationId,
    isFileUploaded,
    validateStep,
    rejectStep,
    sentToCocFiles,
  ]);

  useEffect(() => {
    if (certificationSteps.length > 0) {
      const footprintVerificationStep = certificationSteps.filter(
        (certif) => certif.processStep === currentStep
      );
      if (footprintVerificationStep.length > 0) {
        setCertificationStep(footprintVerificationStep[0]);
        if (roles === "COC") {
          setFiles(
            footprintVerificationStep[0].attachments?.filter(
              (attachment) =>
                attachment.isUploadedByCocOrVvb === true &&
                attachment.certificationId === certificationId
            )
          );
        } else {
          setFiles(
            footprintVerificationStep[0].attachments?.filter(
              (attachment) =>
                attachment.isUploadedByCocOrVvb === false &&
                attachment.certificationId === certificationId
            )
          );
        }
      } else {
        setCertificationStep(certificationSteps[0]);
      }
    }
  }, [certificationSteps, currentStep, roles, isFileRemoved, certificationId]);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleFileInputChange = (event) => {
    const MAX_FILE_SIZE = 5120;
    setIsLoadingUpload(true);
    let uploadData = new FormData();
    if (event.target.files.length > 1) {
      const fileList = [...event.target.files];
      const newFiles = [];
      const uploadData = new FormData();
      let index = 0;
      for (const file of fileList) {
        if (file.size / 1024 > MAX_FILE_SIZE) {
          setShowModalError(true);
          setIsMaxUploadError(true);
          setIsLoadingUpload(false);
        } else {
          uploadData.append(`data[${index}].File`, file);
          uploadData.append(`data[${index}].ProcessStep`, currentStep);
          uploadData.append(`data[${index}].CertificationId`, certificationId);
          if (roles === "COC")
            uploadData.append(`data[${index}].IsUploadedByCocOrVvb`, true);

          newFiles.push(file);
          index = index + 1;
        }
      }

      setFiles((prevFiles) => [...prevFiles, ...newFiles]);

      dispatch(certificationActions.uploadCertification(uploadData));

      //setIsLoadingUpload(false);
    } else {
      const file = event.target.files[0];
      if (file.size / 1024 > MAX_FILE_SIZE) {
        setShowModalError(true);
        setIsMaxUploadError(true);
        setIsLoadingUpload(false);
      } else {
        uploadData.append("data[0].File", file);
        setFiles((prevFiles) => [...prevFiles, file]);
        uploadData.append(`data[0].ProcessStep`, currentStep);
        uploadData.append(`data[0].CertificationId`, certificationId);
        if (roles === "COC")
          uploadData.append(`data[0].IsUploadedByCocOrVvb`, true);
        dispatch(certificationActions.uploadCertification(uploadData));
      }
    }
  };

  useEffect(() => {
    if (uploadError) {
      setIsLoadingUpload(false);
      setShowModalError(true);
    }
  }, [uploadError]);

  return (
    <>
      <h2 className="mb-3">{title} FileBox</h2>
      <div className="border-2 px-4 border-solid pb-4 min-h-[200px]">
        <div className="float-right">5Mb. Max</div>

        {isLoadingUpload && (
          <div className="upload-overlay">
            <Lottie
              animationData={loading}
              style={{ width: "250px", height: "250px" }}
            />
          </div>
        )}
        <button
          className="mx-auto text-white capitalize flex text-sm items-center"
          onClick={handleButtonClick}
        >
          {(
            currentStep === 1
              ? applicationRolesValidationUploadDisabled_Calculation
              : applicationRolesValidationUploadDisabled_Verification
          ) ? (
            <Lottie
              /*loop={false}*/ style={{ width: "100px", height: "100px" }}
              animationData={disableddAnimation}
            />
          ) : (
            <Lottie
              /*loop={false}*/ style={{ width: "100px", height: "100px" }}
              animationData={checkedAnimation}
            />
          )}
        </button>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileInputChange}
          multiple
          className={user?.data.userRole === "COC" && `cursor-not-allowed`}
          disabled={
            currentStep === 1
              ? applicationRolesValidationUploadDisabled_Calculation
              : applicationRolesValidationUploadDisabled_Verification
          }
        />
        <ul className="divide-y-2 divide-gray-300">
          {files?.length > 0 &&
            files?.map((file, index) => {
              return (
                <li key={index} className="py-2 flex justify-between">
                  <DownloadFile file={file} />
                  {(currentStep === 1
                    ? conditionDownload_Calculation
                    : conditionDownload_Verification) && (
                    <DeleteFile file={file} />
                  )}
                </li>
              );
            })}
        </ul>
      </div>

      {/* <div>
        <UserActionButtons
          certificationId={certificationId}
          currentStep={currentStep}
          files={filesToValidate}
          roles={roles}
        />
      </div> */}
      <AlertModal
        showConfirmationModal={showModalError}
        setShowModal={setShowModalError}
        textContent={
          isMaxUploadError
            ? "Max file size is 5MB"
            : "An error occured during upload. Please retry in few minutes."
        }
      />
    </>
  );
}
