import { userConstants } from '../constants/user.constants.js';

export default function validationCCRStep(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_VALIDATE_CCR_STEP_SUCCESS:
            return {
                ...state,
                data: action.payload
            }
            
        case userConstants.GET_VALIDATE_STEP_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state
    }
}