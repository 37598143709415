import { userConstants } from '../constants/user.constants.js';

const initialState = {
    arrayCertification: []
  }
export default function certification(state = initialState, action) {
    switch (action.type) {
        case userConstants.GET_CERTIFICATION_LIST_REQUEST:
            return action.payload
        case userConstants.GET_CERTIFICATION_LIST_SUCCESS:
            return {
                ...state,
                arrayCertification: action.payload
            }
            
        case userConstants.GET_CERTIFICATION_LIST_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        case userConstants.CLEAR_CERTIFICATION_LIST:
            return {
                arrayCertification: []
            }
        default:
            return state
    }
}