import React, { useEffect, useState } from "react";
import RadioInput from "../../../components/forms/RadioInput";
import OtherService from "./OtherService";
import TimeCharteringService from "./TimeCharteringService";
import Modal from "react-modal";
import { initialFormData } from "../../../utils/constant";
import Button from "../../../components/forms/Buttons/Button";
import FlightService from "./FlightService";
import TimeFlightService from "./TimeFlightService";
import VoyageServiceAdmin from "./VoyageServiceAdmin";
import FlightServiceAdmin from "./FlightServiceAdmin";
import TimeFlightAdminService from "./TimeFlightAdminService";
import OtherServiceAdmin from "./OtherServiceAdmin";
import VoyageService from "./VoyageService";

const ServiceFirstStep = ({
  formData,
  setFormData,
  onChangeFormType,
  formTyped,
  errors,
  errorCount,
  register,
  setValue,
  reset,
  unregister,
  onChangeServiceType,
  serviceTyped,
  isAdmin = false,
}) => {
  const [formType, setSelectedOption] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedOption, setSelectedTempOption] = useState();
  const [serviceType, setServiceType] = useState();
  const [selectedServiceType, setSelectedServiceType] = useState();
  const [hideServiceTypes, setHideServiceTypes] = useState(false);

  const resetFormData = () => {
    setFormData({ ...initialFormData, certificationType: 3 });
  };

  const checkFormValuesModified = (formData, initialFormData) => {
    for (const [key, value] of Object.entries(formData)) {
      if (key !== "certificationType" && key !== "CocUserId") {
        if (
          value !== "1970-01-01" &&
          value !== "1970-01-01 to 1970-01-01" &&
          value !== "1970-01-01T00:00:00.000Z" &&
          value !== initialFormData[key]
        ) {
          return true; // Au moins une valeur a été modifiée
        }
      }
    }
    return false; // Aucune valeur n'a été modifiée
  };

  const initDates = (certificationType) => {
    if (certificationType === "Other") {
      if (formData?.shippingDate === null) {
        setFormData({ ...formData, shippingDate: "1970-01-01" });
      }
    }
    if (
      certificationType === "Shipping Voyage" ||
      certificationType === "Aviation Flight"
    ) {
      if (formData?.shippingDate === null || formData?.shippingDate === "") {
        setFormData({ ...formData, shippingDate: "1970-01-01" });
      }
    }

    if (
      certificationType === "Shipping Time Chart" ||
      certificationType === "Other" ||
      certificationType === "Aviation Time Chart"
    ) {
      if (formData?.contractTimePeriod === "") {
        setFormData({
          ...formData,
          contractTimePeriod: "1970-01-01 to 1970-01-01",
        });
      }
    }
  };

  const handleConfirmationResponse = (keepChanges) => {
    let selectedFormType = selectedOption;
    let serviceTypeSelected = selectedServiceType;
    if (!keepChanges) {
      //value true
      resetFormData();
      reset();
      if (serviceTypeSelected) {
        onChangeServiceType(serviceTypeSelected);
      } else if (selectedFormType) {
        onChangeFormType(selectedFormType);
        setSelectedOption(selectedFormType);
      }
    } else {
      // value false
      if (serviceTypeSelected) {
        serviceTypeSelected = serviceType;
        onChangeServiceType(serviceTypeSelected);
        setSelectedServiceType(serviceTypeSelected);
      } else if (selectedFormType) {
        selectedFormType = formType;
        onChangeFormType(selectedFormType);
        setSelectedOption(selectedFormType);
      }
    }
    selectedFormType = null;
    serviceTypeSelected = null;
    setShowConfirmationModal(false);
  };

  const handleFormTypeChange = (e) => {
    const isFormModified = checkFormValuesModified(formData, initialFormData);
    if (isFormModified) {
      setShowConfirmationModal(true);
      setSelectedTempOption(e.target.value);
    } else {
      setSelectedOption(e.target.value);
      onChangeFormType(e.target.value);
      reset();
    }
  };

  const handleServiceTypeChange = (e) => {
    const isFormModified = checkFormValuesModified(formData, initialFormData);
    if (isFormModified) {
      setShowConfirmationModal(true);
      setSelectedServiceType(e.target.value);
    } else {
      setServiceType(e.target.value);
      onChangeServiceType(e.target.value);
      reset();
    }
  };

  useEffect(() => {
    if (formTyped) {
      setSelectedOption(formTyped);
    }
    if (serviceTyped) {
      setServiceType(serviceTyped);
    }
  }, [formTyped, serviceTyped]);

  useEffect(() => {
    if (isAdmin) {
      setHideServiceTypes(true);
    }
  }, [isAdmin]);

  const secondChoice = (
    <>
      {serviceType === "Shipping" ? (
        <div className="w-full mt-8 ml-14">
          <div className="checkItems flex">
            <div
              className={`choice mb-4 flex ml-4 ${
                hideServiceTypes && "hidden"
              }`}
            >
              <RadioInput
                id="voyage"
                type="radio"
                value="Voyage"
                checked={formType === "Voyage"}
                onChange={handleFormTypeChange}
              />
              <label className="relative" htmlFor="voyage">
                Voyage(s)
              </label>
            </div>
            <div
              className={`choice mb-4 flex ml-4 ${
                hideServiceTypes && "hidden"
              }`}
            >
              <RadioInput
                id="time"
                value="Time"
                checked={formType === "Time"}
                onChange={handleFormTypeChange}
              />
              <label className="relative" htmlFor="time">
                Time Chartering
              </label>
            </div>
          </div>
          {formType === "Voyage" &&
            (isAdmin ? (
              <VoyageServiceAdmin
                formData={formData}
                setFormData={setFormData}
                errorCount={errorCount}
                errors={errors}
                register={register}
                setValue={setValue}
                reset={reset}
                unregister={unregister}
              />
            ) : (
              <VoyageService
                formData={formData}
                setFormData={setFormData}
                errorCount={errorCount}
                errors={errors}
                register={register}
                setValue={setValue}
                reset={reset}
                unregister={unregister}
                initDates={initDates}
              />
            ))}
          {formType === "Time" && (
            <TimeCharteringService
              formData={formData}
              setFormData={setFormData}
              errorCount={errorCount}
              errors={errors}
              register={register}
              setValue={setValue}
              reset={reset}
              unregister={unregister}
              initDates={initDates}
            />
          )}
        </div>
      ) : serviceType === "Aviation" ? (
        <div className="w-full mt-8 ml-14">
          <div className="checkItems flex">
            <div
              className={`choice mb-4 flex ml-4 ${
                hideServiceTypes && "hidden"
              }`}
            >
              <RadioInput
                id="flight"
                type="radio"
                value="Flight"
                checked={formType === "Flight"}
                onChange={handleFormTypeChange}
              />
              <label className="relative" htmlFor="flight">
                Flight(s)
              </label>
            </div>
            <div
              className={`choice mb-4 flex ml-4 ${
                hideServiceTypes && "hidden"
              }`}
            >
              <RadioInput
                id="timeFlight"
                value="TimeFlight"
                checked={formType === "TimeFlight"}
                onChange={handleFormTypeChange}
              />
              <label className="relative" htmlFor="timeFlight">
                Time Chartering
              </label>
            </div>
          </div>
          {formType === "Flight" &&
            (isAdmin ? (
              <FlightServiceAdmin
                formData={formData}
                setFormData={setFormData}
                errorCount={errorCount}
                errors={errors}
                register={register}
                setValue={setValue}
                reset={reset}
                unregister={unregister}
              />
            ) : (
              <FlightService
                formData={formData}
                setFormData={setFormData}
                errorCount={errorCount}
                errors={errors}
                register={register}
                setValue={setValue}
                reset={reset}
                unregister={unregister}
                initDates={initDates}
              />
            ))}
          {formType === "TimeFlight" &&
            (isAdmin ? (
              <TimeFlightAdminService
                formData={formData}
                setFormData={setFormData}
                errorCount={errorCount}
                errors={errors}
                register={register}
                setValue={setValue}
                reset={reset}
                unregister={unregister}
              />
            ) : (
              <TimeFlightService
                formData={formData}
                setFormData={setFormData}
                errorCount={errorCount}
                errors={errors}
                register={register}
                setValue={setValue}
                reset={reset}
                unregister={unregister}
                initDates={initDates}
              />
            ))}
        </div>
      ) : (
        serviceType === "Other" &&
        (isAdmin ? (
          <OtherServiceAdmin
            formData={formData}
            setFormData={setFormData}
            errorCount={errorCount}
            errors={errors}
            register={register}
            setValue={setValue}
            reset={reset}
            unregister={unregister}
          />
        ) : (
          <OtherService
            formData={formData}
            setFormData={setFormData}
            errorCount={errorCount}
            errors={errors}
            register={register}
            setValue={setValue}
            reset={reset}
            unregister={unregister}
            initDates={initDates}
          />
        ))
      )}
      <Modal
        isOpen={showConfirmationModal}
        onRequestClose={() => handleConfirmationResponse(false)}
        className="popup-modal" // Ajoutez la classe "my-modal" à votre modal
        overlayClassName="modall-overlay"
      >
        <h2 className="text-center">
          Are you sure you want to leave the form and lose filled data?
        </h2>
        <div className="flex justify-between py-4">
          <div>
            <Button
              onClick={() => handleConfirmationResponse(false)}
              text={`Yes`}
              backgroundColor={`coc-green`}
            />
          </div>

          <div className="ml-4">
            <Button
              onClick={() => handleConfirmationResponse(true)}
              text={`No`}
              backgroundColor={`coc-blue`}
            />
          </div>
        </div>
      </Modal>
    </>
  );

  const firstChoice = (
    <>
      <div className="w-full mt-8 ml-14">
        <div className="checkItems flex">
          <div className={`choice mb-4 flex ${hideServiceTypes && "hidden"}`}>
            <RadioInput
              id="shipping"
              value="Shipping"
              checked={serviceType === "Shipping"}
              onChange={handleServiceTypeChange}
            />
            <label className="relative" htmlFor="shipping">
              Shipping
            </label>
          </div>
          <div
            className={`choice mb-4 flex ml-4 ${hideServiceTypes && "hidden"}`}
          >
            <RadioInput
              id="aviation"
              type="radio"
              value="Aviation"
              checked={serviceType === "Aviation"}
              onChange={handleServiceTypeChange}
            />
            <label className="relative" htmlFor="aviation">
              Aviation
            </label>
          </div>
          <div
            className={`choice mb-4 flex ml-4 ${hideServiceTypes && "hidden"}`}
          >
            <RadioInput
              id="other"
              value="Other"
              checked={serviceType === "Other"}
              onChange={handleServiceTypeChange}
            />
            <label className="relative" htmlFor="other">
              Other
            </label>
          </div>
        </div>
        {secondChoice}
      </div>
    </>
  );

  return firstChoice;
};

export default ServiceFirstStep;
