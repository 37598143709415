import "./Partner.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { partnerActions } from "../../actions/partner.action";
import SearchBar from "../../components/forms/Searchbar";
import InvitationModal from "../../components/Modal";
import ReactTable from "../../components/Table/ReactTable";

export default function Partner() {
  const [currentPage] = useState(1);
  const [selectedFilter] = useState("");
  const [sorting] = useState({ field: "", order: "" });
  const ITEMS_PER_PAGE = 10;
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  const partners = useSelector((state) => state.partner.data);
  useEffect(() => {
    dispatch(partnerActions.getPartnerList());
  }, [dispatch]);

  const updatedPartners = partners;
  const dataToShow = useMemo(() => {
    let partnerData = updatedPartners;

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      partnerData.sort((a, b) => {
        const fieldX = a[sorting.field] || "";
        const fieldY = b[sorting.field] || "";
        return reversed * fieldX.localeCompare(fieldY);
      });
    }
    if (selectedFilter && selectedFilter.toLowerCase() !== "all") {
      partnerData = updatedPartners.filter(
        (partner) => partner.userType === selectedFilter
      );
    } else {
      partnerData = partners;
    }
    if (search) {
      partnerData = partnerData.filter(
        (partner) =>
          partner.companyName?.toLowerCase().includes(search.toLowerCase()) ||
          partner.webSite?.toLowerCase().includes(search.toLowerCase()) ||
          partner.expertiseDescription
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          partner.phoneNumber?.toLowerCase().includes(search.toLowerCase()) ||
          partner.email?.toLowerCase().includes(search.toLowerCase()) ||
          partner.firstName?.toLowerCase().includes(search.toLowerCase()) ||
          partner.lastName?.toLowerCase().includes(search.toLowerCase())
      );
    }
    //current page
    return partnerData.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    partners,
    currentPage,
    search,
    sorting,
    selectedFilter,
    ITEMS_PER_PAGE,
    updatedPartners,
  ]);

  const onSearch = (value) => {
    setSearch(value);
  };
  return (
    <>
      <div className="partners">
        <div className="container sm:mx-auto">
          <SearchBar onSearch={onSearch} />
        </div>
        <div className="flex justify-between px-9 my-6">
          <InvitationModal />
        </div>
      </div>
      <div className="px-5">
        <ReactTable certificationList={dataToShow} isPartner={true} />
      </div>
    </>
  );
}
