export function PreviousButton({ onClick, type, disabled }) {
  return (
    <>
      <button
        type={type}
        className={`bg-coc-blue w-[200px] py-3 hover:bg-[#2b2f8d] text-xs rounded-lg`}
        onClick={onClick}
        disabled={disabled}
      >
        <span className="capitalize">Previous step</span>
      </button>
    </>
  );
}
