import { useForm } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import ServiceFirstStep from "../../pages/DemandForm/Services/ServiceFirstStep";
import SubmitButton from "../forms/Buttons/SubmitButton";
import { useDispatch } from "react-redux";
import { adminActions } from "../../actions/admin.actions";
import { getCertificationTypeStringValue } from "../../utils/enum";
import FirstStepAdmin from "../../pages/DemandForm/CommonStep/FirstStepAdmin";
import { extractCertificationCodeFromURL, remove0 } from "../../utils/utils";

export default function UpdateCertifications({
  certificationsData,
  closeModal,
}) {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    reset,
    unregister,
    getValues,
  } = useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  const [formData, setFormData] = useState(certificationsData);
  const [errorCount, setErrorCount] = useState(0);
  const [serviceOption, setServiceOption] = useState();
  const [serviceOptionType, setServiceOptionType] = useState();
  const [loading, setLoading] = useState(false);
  const onChangeFormType = (value) => {
    setServiceOptionType(value);
  };
  const onChangeServiceType = (value) => {
    setServiceOption(value);
  };

  const onSubmit = (data) => {
    setLoading(true);
    data["object"] = formData.object;
    //force the type to be boolean
    if (data["preTransactionCertification"]) {
      data["preTransactionCertification"] = Boolean(
        data["preTransactionCertification"]
      );
    }
    if (data["postTransactionCertification"]) {
      data["preTransactionCertification"] =
        data["postTransactionCertification"] === "false" ? false : true;
    }
    if (data["cradleToGrave"]) {
      data["cradleToGate"] = data["cradleToGrave"] === "false" ? false : true;
    }
    if (data["cradleToGate"]) {
      data["cradleToGate"] = Boolean(data["cradleToGate"]);
    }

    if (data["estimatedFirstTransDate"]) {
      data["estimatedFirstTransDate"] = new Date(
        data["estimatedFirstTransDate"]
      ).toISOString();
    }
    if (
      data["estimatedFirstTransDate"] === "" ||
      data["estimatedFirstTransDate"] === null
    ) {
      data["estimatedFirstTransDate"] = new Date("1970-01-01").toISOString();
    }
    if (data["shippingDate"]) {
      data["shippingDate"] = new Date(data["shippingDate"]).toISOString();
    }
    if (
      data["contractTimePeriod"] === "" ||
      data["contractTimePeriod"] === null ||
      data["contractTimePeriod"] === undefined
    ) {
      data["contractTimePeriod"] = "1970-01-01 to 1970-01-01";
    }
    if (data["shippingDate"] === "" || data["shippingDate"] === null) {
      data["shippingDate"] = new Date("1970-01-01").toISOString();
    }
    if (data["serviceName"]) {
      data["object"] = data["serviceName"];
    }

    dispatch(adminActions.updateCertification(formData.id, data));
  };

  const setServiceOptionAndType = useCallback(() => {
    if (
      (certificationsData?.contractTimePeriod &&
        certificationsData?.vesselName) ||
      certificationsData?.ballastVoyageDescription
    ) {
      setServiceOption("Shipping");
      setServiceOptionType(
        certificationsData?.ballastVoyageDescription ? "Voyage" : "Time"
      );
    } else if (
      certificationsData?.airCraftType ||
      (certificationsData?.airCraftType &&
        certificationsData?.contractTimePeriod)
    ) {
      setServiceOption("Aviation");
      setServiceOptionType(
        certificationsData?.airCraftType &&
          certificationsData?.contractTimePeriod
          ? "TimeFlight"
          : "Flight"
      );
    } else {
      setServiceOption("Other");
      setServiceOptionType("");
    }
  }, [
    certificationsData?.contractTimePeriod,
    certificationsData?.vesselName,
    certificationsData?.ballastVoyageDescription,
    certificationsData?.airCraftType,
  ]);

  useEffect(() => {
    setServiceOptionAndType();
  }, [setServiceOptionAndType]);

  const stepToDisplay =
    certificationsData.certificationType === 3 ? (
      <ServiceFirstStep
        formData={certificationsData}
        setFormData={setFormData}
        register={register}
        setValue={setValue}
        formTyped={serviceOptionType}
        serviceTyped={serviceOption}
        errors={errors}
        errorCount={errorCount}
        unregister={unregister}
        onChangeFormType={onChangeFormType}
        onChangeServiceType={onChangeServiceType}
        reset={reset}
        isAdmin={true}
      />
    ) : (
      <FirstStepAdmin
        formData={certificationsData}
        setFormData={setFormData}
        register={register}
        setValue={setValue}
        errors={errors}
        errorCount={errorCount}
        unregister={unregister}
      />
    );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between">
          <span className="text-coc-blue font-semibold text-center text-lg">
            {certificationsData?.name ? certificationsData?.name : ""}
          </span>
          <span className="text-coc-blue font-semibold text-center text-lg">
            Type:{" "}
            {getCertificationTypeStringValue(
              certificationsData?.certificationType
            )}
          </span>
          {certificationsData?.certificationType === 3 &&
            serviceOption !== "Other" && (
              <span className="text-coc-blue font-semibold text-center text-lg">
                Service Type: {serviceOption} - {serviceOptionType}
              </span>
            )}
          {certificationsData?.certificationType === 3 &&
            serviceOption === "Other" && (
              <span className="text-coc-blue font-semibold text-center text-lg">
                Service Type: {serviceOption}
              </span>
            )}
        </div>

        {stepToDisplay}
        <div className="flex justify-end items-center gap-x-2 py-3 px-4 bg-gray-50 border-t">
          <button
            type="button"
            className="py-2.5 px-4 inline-flex justify-center bg-gray-400 items-center gap-2 rounded-md border font-medium text-white shadow-sm align-middle hover:bg-gray-300 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm"
            //data-hs-overlay="#hs-user-details"
            onClick={closeModal}
          >
            Cancel
          </button>
          <SubmitButton
            text="Update"
            className="py-2.5 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-medium bg-coc-green text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-coc-green focus:ring-offset-2 transition-all text-sm"
            onclick={() => setErrorCount(errorCount + 1)}
            loading={loading}
          />
        </div>
      </form>
    </>
  );
}
