import React from "react";
import { Navigate } from "react-router-dom";
function AdminRoute({ isAuthenticated, children }) {
  const user = JSON.parse(localStorage.getItem("user"));
  if (isAuthenticated && user?.data.userRole === "ADMIN") {
    return children;
  }
  return <Navigate to="/" replace />;
}
export default AdminRoute;
