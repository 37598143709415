import { userConstants } from '../constants/user.constants.js';

export default function getCreditRetirements(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_CCR_SUCCESS:
            return {
                ...state,
                data: action.payload
            }
            
        case userConstants.GET_CCR_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state
    }
}