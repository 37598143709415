import React from 'react';

const Confirmation = () => {
    return (
        <div className="container mx-auto p-4">
            <div className="max-w-md mx-auto">
                <h2 className="text-2xl font-bold text-gray-800 mb-4">Reset Link Sent</h2>
                <p className="text-lg text-gray-600">
                    An email containing the reset link has been sent to your email address.
                </p>
            </div>
        </div>
    );
};

export default Confirmation;
