import { Tooltip } from "@material-tailwind/react";

export default function InfoPopup({ text, className }) {
  return (
    <>
      <Tooltip
        content={text}
        className="px-4 py-4 max-w-xl bg-[#707070] leading-normal"
      >
        <img
          className="popup relative -right-4 -top-[30%]"
          src="/assets/icons/info.svg"
          alt=""
        />
      </Tooltip>
    </>
  );
}
