import React from "react";
import PdfViewer from "./PdfViewer";

export default function CocProtocol() {
  return (
    <div>
      <PdfViewer />
    </div>
  );
}
