import { userConstants } from '../constants/user.constants.js';
import { adminService } from '../services/admin.service.js';
import { alertActions } from './alert.actions.js';
export const adminActions = {
    updateUser,
    removeUser,
    createPartnerUser,
    updateCertification
};

function updateUser(userId,user) {
    return dispatch => {
        adminService.updateUser(userId,user)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success('User created successfully, your account must be validated before connexion, please check your email.'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function success(user) { return { type: userConstants.UPDATE_USER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.UPDATE_USER_FAILURE, error } }
}

function updateCertification(id,certification) {
    return dispatch => {
        adminService.updateCertification(id,certification)
            .then(
                certification => {
                    dispatch(success(certification));
                    dispatch(alertActions.success('User created successfully, your account must be validated before connexion, please check your email.'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function success(certification) { return { type: userConstants.UPDATE_CERTIFICATION_SUCCESS, certification } }
    function failure(error) { return { type: userConstants.UPDATE_CERTIFICATION_FAILURE, error } }
}

function createPartnerUser(user) {
    return dispatch => {
        adminService.createPartnerUser(user)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success('User created successfully, your account must be validated before connexion, please check your email.'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function success(user) { return { type: userConstants.CREATE_PARTNER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.CREATE_PARTNER_FAILURE, error } }
}

function removeUser(id) {
    return dispatch => {
        adminService.removeUser(id)
            .then(
                user => dispatch(success(user)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function success(user) { return { type: userConstants.REMOVE_USER_SUCCESS, user } }
    function failure(id, error) { return { type: userConstants.REMOVE_USER_FAILURE, id, error } }
}