import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import "./Header.css";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../actions";
import { userService } from "../../services/user.service";
import DefaultProfilePicture from "./DefaultProfilePicture";

export default function HeaderAdmin({ isAuth }) {
  const user = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const [showLogOutButton, setShowLogOutButton] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const isRouteActive = (route) => {
    return route === "/admin/certifications"
      ? location.pathname === route ||
          location.pathname === "/" ||
          location.pathname === "/dashboard"
      : location.pathname === route;
  };
  const [initials, setInitials] = useState();
  const [userDetail, setUserDetail] = useState(null);
  const userUpdated = useSelector((state) => state.admin.updated_user);

  const dropdownRef = useRef(null);

  const generateInitials = useCallback(() => {
    if (user?.data.firstName && user?.data.lastName) {
      setInitials(
        `${user?.data.firstName.charAt(0)}${user?.data.lastName.charAt(0)}`
      );
    } else setInitials(`${user?.data.email.charAt(0)}`);
  }, [user]);

  const handleShowLogoutButton = () => {
    setShowLogOutButton(true);
  };

  const handleLogOut = () => {
    dispatch(userActions.logout());
    navigate("/");
  };

  useEffect(() => {
    if (user?.data.id || (userUpdated && userUpdated.id === user?.data.id)) {
      userService
        .getById(user?.data.id)
        .then((response) => setUserDetail(response.data));
    }
  }, [user?.data.id, userUpdated]);

  useEffect(() => {
    if (!isAuth) {
      navigate("/");
    } else {
      generateInitials();
    }
  }, [isAuth, navigate, generateInitials]);

  const handleMouseEnter = () => {
    setShowLogOutButton(true);
  };

  const handleMouseLeave = () => {
    setShowLogOutButton(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowLogOutButton(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header className="z-40">
      <span className="logo">
        <Link to={isAuth ? "/dashboard" : "/"}>
          <img src="/assets/images/logo-white.png" alt="" />
        </Link>
      </span>
      <ul className="menu">
        <li>
          <NavLink
            className={({ isActive }) => (isActive ? "active" : "none")}
            to="/admin/users"
          >
            Users
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) =>
              isRouteActive("/admin/certifications") ? "active" : "none"
            }
            to={"/admin/certifications"}
          >
            Applications
          </NavLink>
        </li>
        <div className="connected flex items-center">
          <div className="user-connected">
            {userDetail?.companyLogo ? (
              <img
                src={`data:image/jpeg;base64,${userDetail?.companyLogo}`}
                alt="company logo"
                className="w-[33px] h-[33px] object-cover rounded-full"
              />
            ) : (
              <DefaultProfilePicture />
            )}
          </div>
          <div className="dropdown relative ml-2">
            <img
              className="cursor-pointer"
              src="/assets/icons/dropdown.svg"
              alt=""
              onClick={handleShowLogoutButton}
            />
            {showLogOutButton && (
              <div
                className="absolute right-0 mt-2 top-11 "
                ref={dropdownRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <button
                  className="block flex items-center px-4 py-2 text-gray-800 hover:bg-gray-100 bg-white rounded-md shadow-lg logoutButton"
                  onClick={handleLogOut}
                >
                  Logout
                  <img
                    className="ml-2 w-4 h-4"
                    src="/assets/icons/logout.svg"
                    alt=""
                  />
                </button>
              </div>
            )}
          </div>
        </div>
      </ul>
    </header>
  );
}
