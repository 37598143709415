export default function CheckboxInput(props) {
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const inputs = Array.from(e.target.form.elements).filter(
        (element) => element.tagName === "INPUT"
      );

      const currentIndex = inputs.indexOf(e.target);

      const nextIndex = (currentIndex + 1) % inputs.length;

      inputs[nextIndex].focus();
    }
  };

  return (
    <div className="choice mb-3" style={{ display:'flex' , alignItems:'center' }}>
      <input
        className="float-left text-[#3BB34B] focus:ring-[#3BB34B] focus:border-[#3BB34B] hover:cursor-pointer"
        id={props.id}
        name={props.name}
        type="checkbox"
        {...props.register}
        value={props.value}
        onChange={props.onChange}
        onKeyDown={handleKeyDown}
        checked={props.checked}
        tabIndex={props.tabIndex}
      />
      <label
        className="relative text-[#989898] font-medium left-[10px]"
        htmlFor={props.id}
      >
        {props.label}
      </label>
    </div>
  );
}
