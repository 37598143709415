import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { EmailInput } from "./forms/EmailInput";
import TextInput from "./forms/TextInput";
import BlueButtonWithIcon from "./forms/Buttons/BlueButtonWithIcon";
import { PlusSmallIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { userActions } from "../actions";
import CheckboxInput from "./forms/CheckboxInput";
import Error from "./Alert/Error";
import { useDispatch, useSelector } from "react-redux";
import { fieldRequiredMessage } from "../errors/errors";
import SubmitButton from "./forms/Buttons/SubmitButton";
import PhoneInput from "./forms/PhoneInput";

export default function InvitationModal() {
  let [isOpen, setIsOpen] = useState(false);
  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const dataInit = {
    cocUserId: user?.data.id,
    companyName: "",
    email: "",
    contactName: "",
    contactPhone: "",
    companyExpertise: [],
  };
  const [formData, setFormData] = useState(dataInit);
  const [errorCount, setErrorCount] = useState(0);

  const alert = useSelector((state) => state.alert);

  const onSubmitForm = async (data) => {
    setLoading(true);
    data.cocUserId = user?.data.id;
    data.companyExpertise = data.companyExpertise.map((val) => parseInt(val));
    dispatch(userActions.invite(data));
    reset();
    //setIsOpen(false);
  };

  const handleExpertiseChange = (e) => {
    const value = e.target.value;
    let updatedExpertise = [...formData.companyExpertise];
    if (updatedExpertise.includes(value)) {
      updatedExpertise = updatedExpertise.filter((item) => item !== value);
    } else {
      updatedExpertise.push(value);
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      companyExpertise: updatedExpertise,
    }));
  };

  function closeModal() {
    setIsOpen(false);
    reset();
  }

  function openModal() {
    setIsOpen(true);
    reset();
  }

  useEffect(() => {
    if (alert.message && alert.type === "alert-success") {
      setLoading(false);
      setIsOpen(false);
    }
  }, [alert]);

  return (
    <>
      <div>
        <BlueButtonWithIcon Event={openModal}>
          <PlusSmallIcon className="h-6 w-6 mr-2" /> Invite a company to join
          the list of partners
        </BlueButtonWithIcon>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 align-middle shadow-xl transition-all mx-auto">
                  <img
                    className="hover:cursor-pointer block ml-auto mb-9"
                    onClick={() => setIsOpen(false)}
                    src="/assets/icons/close.svg"
                    alt=""
                  />

                  <Dialog.Title
                    as="p"
                    className="text-sm font-normal leading-6 text-center text-[#232323] mb-9"
                  >
                    You may invite your Partner, or service provider to join COC
                    list of partners to offer their services as part of COC
                    offsetting process. An email will be automatically sent to
                    COC in order to contact your invited partner.
                  </Dialog.Title>
                  <form onSubmit={handleSubmit(onSubmitForm)} className="ml-3">
                    <h2 className="font-bold text-left text-[#3D4196] text-lg">
                      Contact details
                    </h2>

                    <div className="grid grid-cols-2">
                      <div className="w-3/4 mt-3">
                        <TextInput
                          type="text"
                          placeholder="Company Name"
                          label="Company Name"
                          name="companyName"
                          id="companyName"
                          register={{
                            ...register("companyName", {
                              required: true,
                              maxLength: 30,
                            }),
                          }}
                          onChange={(e) =>
                            setValue("companyName", e.target.value)
                          }
                          tabIndex={1}
                        />
                        {errors.companyName &&
                          errors.companyName.type === "required" && (
                            <Error
                              key={errorCount}
                              text={fieldRequiredMessage("Company Name")}
                            />
                          )}

                        <div className="mt-2">
                          <EmailInput
                            register={{
                              ...register("email", { required: true }),
                            }}
                            errors={
                              errors.Email &&
                              errors.Email.type === "required" && (
                                <Error
                                  key={errorCount}
                                  text={fieldRequiredMessage("Email Address")}
                                />
                              )
                            }
                            onChange={(e) => setValue("email", e.target.value)}
                            tabIndex={3}
                          />
                        </div>

                        <div className="font-bold text-left text-[#3D4196] text-lg my-4">
                          Company Expertise
                        </div>
                        <div className="checkItems">
                          <CheckboxInput
                            id="ghg"
                            name="ghg"
                            register={{
                              ...register("companyExpertise", {
                                required: true,
                              }),
                            }}
                            label="MRV (Carbon Footprint Calculation)"
                            value="1"
                            onChange={handleExpertiseChange}
                            tabIndex={5}
                          />

                          <CheckboxInput
                            id="assurance"
                            name="assurance"
                            register={{
                              ...register("companyExpertise", {
                                required: true,
                              }),
                            }}
                            label="VVB (Verification Body)"
                            value="3"
                            onChange={handleExpertiseChange}
                            tabIndex={6}
                          />

                          <CheckboxInput
                            id="accessProvider"
                            name="accessProvider"
                            register={{
                              ...register("companyExpertise", {
                                required: true,
                              }),
                            }}
                            label="Carbon Market Access Provider"
                            value="2"
                            onChange={handleExpertiseChange}
                            tabIndex={7}
                          />

                          <CheckboxInput
                            id="other"
                            name="other"
                            register={{
                              ...register("companyExpertise", {
                                required: true,
                              }),
                            }}
                            label="Other"
                            value="4"
                            onChange={handleExpertiseChange}
                            tabIndex={8}
                          />
                          {errors.CompanyExpertise && (
                            <Error
                              key={errorCount}
                              text={"Please select at least one option"}
                            />
                          )}
                        </div>

                        <div className="checkItems"></div>
                      </div>

                      <div className="w-3/4 mt-3">
                        <div>
                          <TextInput
                            type="text"
                            placeholder="Contact Name"
                            label="Contact Name"
                            name="contactName"
                            id="contactName"
                            register={{
                              ...register("contactName", {
                                required: false,
                                maxLength: 30,
                              }),
                            }}
                            onChange={(e) =>
                              setValue("contactName", e.target.value)
                            }
                            tabIndex={2}
                          />

                          <div className="mt-2">
                            <PhoneInput
                              ut
                              type="text"
                              placeholder="Phone Number"
                              label="Phone Number"
                              name="phoneNumber"
                              id="phoneNumber"
                              register={{
                                ...register("contactPhone", {
                                  required: false,
                                  maxLength: 30,
                                }),
                              }}
                              onChange={(e) =>
                                setValue("contactPhone", e.target.value)
                              }
                              tabIndex={4}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="min-w-full">
                      <div className="flex justify-end items-center gap-x-2 py-3 px-4 bg-gray-50 border-t">
                        <button
                          type="button"
                          className="py-2.5 px-4 inline-flex justify-center bg-gray-400 items-center gap-2 rounded-md border font-medium text-white shadow-sm align-middle hover:bg-gray-300 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm"
                          //data-hs-overlay="#hs-user-details"
                          onClick={() => setIsOpen(false)}
                        >
                          Cancel
                        </button>
                        <SubmitButton
                          className="py-2.5 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-medium bg-coc-green text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-coc-green focus:ring-offset-2 transition-all text-sm"
                          buttonText={"Send Invitation To COC"}
                          loading={loading}
                          onClick={() => setErrorCount(errorCount + 1)}
                          tabIndex={9}
                        />
                        {alert.message && alert.type === "alert-danger" && (
                          <Error key={errorCount} text={alert.message} />
                        )}
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
