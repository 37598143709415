import handleResponse from "../utils/handleresponse";
import authHeader from "../utils/auth-header";

export const partnerService = {
  getPartnerList,
};

function getPartnerList() {
  const requestOptions = {
    headers: { ...authHeader() },
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/users/partners/`,
    requestOptions
  )
    .then(handleResponse)
    .then((partners) => {
      return partners.data;
    });
}
