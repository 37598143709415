export function getItemBySubstring(substring) {
  const values = [];
  for (var i = 0; i < localStorage.length; i++) {
    var key = localStorage.key(i);
    if (key.includes(substring)) {
      values.push(localStorage.getItem(key));
    }
  }
  return values;
}

export function formatDate(dateString) {
  const date = new Date(dateString);
  if (!isNaN(date)) {
    return date.toISOString().substring(0, 10);
  }
}

export function limitCharacter(element, limit = 10) {
  if (element?.length > limit) return `${element?.substring(0, 10)}...`;
  else return element;
}

export function convertToISODateTime(dateString) {
  const dateParts = dateString.split(" "); // Divisez la chaîne en date et heure

  const datePart = dateParts[0];
  const timePart = dateParts[1];

  const [day, month, year] = datePart.split("/");
  const [hours, minutes, seconds] = timePart.split(":");

  // Créez un objet Date en utilisant les parties de la date
  const isoDate = new Date(
    `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
  );
  return isoDate;
}
export function convertToISODate(string) {
  if (string !== null) {
    const dateString = string;
    const [day, month, year] = dateString?.split("/");

    // Créer une nouvelle date en utilisant le format ISO 8601 : "YYYY-MM-DDTHH:mm:ss.sssZ"
    const isoDateString = `${year}-${month}-${day}T00:00:00.000Z`;

    return new Date(isoDateString);
  } else {
    return new Date("1970-01-01");
  }
}
export function sortData(array, key, isReverse = false) {
  const sortedData = array?.sort((a, b) => {
    if (key === "estimatedFirstTransDate" || key === "issuanceDate") {
      const dateA = a[key] ? convertToISODate(a[key]) : new Date("1970-01-01");
      const dateB = b[key] ? convertToISODate(b[key]) : new Date("1970-01-01");

      if (dateA?.toLocaleDateString() === dateB?.toLocaleDateString()) {
        if (a.certificationCode < b.certificationCode) return -1;
        if (a.certificationCode > b.certificationCode) return 1;
        return 0;
      }

      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;
    } else if (key === "carbonFootPrint") {
      const x = a[key] !== null ? parseInt(a[key]) : 0;
      const y = b[key] !== null ? parseInt(b[key]) : 0;
      if (x === y) {
        if (a.certificationCode < b.certificationCode) return -1;
        if (a.certificationCode > b.certificationCode) return 1;
        return 0;
      }

      if (x < y) {
        return -1;
      }
      if (x > y || (x === 0 && y !== 0) || (y === 0 && x !== 0)) return 1;
    } else if (key === "dateOfLastAction") {
      const dateA = convertToISODateTime(a[key]);
      const dateB = convertToISODateTime(b[key]);
      if (dateA < dateB) {
        return 1;
      }
      if (dateA > dateB) {
        return -1;
      }
    } else {
      if (a[key] === b[key]) {
        if (a.certificationCode < b.certificationCode) return -1;
        if (a.certificationCode > b.certificationCode) return 1;
        return 0;
      }
      if (a[key] !== null && b[key] === null) return 1;
      else if (a[key] === null && b[key] !== null) return -1;
      else {
        if (a[key] < b[key]) return -1;
        if (a[key] > b[key]) return 1;
      }
    }

    return 0;
  });

  return isReverse ? sortedData.reverse() : sortedData;
}

export const sortDataByStatus = (arrayList, isReverse = false) => {
  const valueOrder = { 3: 0, 2: 1, 1: 2, 4: 3 };
  const sortedData = arrayList?.sort((a, b) => {
    const aValue = valueOrder[a.certificationStatus];
    const bValue = valueOrder[b.certificationStatus];

    // if (aValue === bValue) {
    //   if (a.certificationCode < b.certificationCode) return -1;
    //   if (a.certificationCode > b.certificationCode) return 1;
    //   return 0;
    // }

    return aValue - bValue;
  });

  return isReverse ? sortedData.reverse() : sortedData;
};

export const formatToMMDDYYYY = (inputDate) => {
  const date = new Date(inputDate);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month.toString().padStart(2, "0")}/${day
    .toString()
    .padStart(2, "0")}/${year}`;
};

export const standardList = [
  "American Carbon Registry",
  "Architecture for REDD+ Transactions",
  "Australian Emissions Reduction Fund",
  "Clean Development Mechanism",
  "Climate Action Reserve",
  "Gold Standard",
  "J-Credit Scheme",
  "UK Woodland Carbon Code",
  "Verified Carbon Standard (Verra)",
];

export const yearsList = () => {
  const years = [];
  for (let i = 2017; i <= new Date().getFullYear(); i++) {
    years.push(i);
  }
  return years;
};

const capitalizeText = (text) => {
  return text.replace(/\([^()]+\)/g, (match) => match.toLowerCase());
};

export const formatLabel = (label) => {
  const parts = label?.split(/(\([^()]+\))/);
  return parts?.map((part, index) =>
    part.startsWith("(") ? (
      <span key={index}>{part}</span>
    ) : (
      <span key={index} className="capitalize">
        {capitalizeText(part)}
      </span>
    )
  );
};

export function formatStringToTitle(string) {
  if (string === string.toUpperCase() || string === string.toLowerCase()) {
    string = string.toLowerCase();
  }

  // Replace uppercase letters not at the beginning of the string, following two or more consecutive uppercase letters,
  // or following '(' with spaces before capitalizing
  string = string.replace(
    /(?<![A-Z])(?<![A-Z]{2,})(?<!\()(?<![A-Z] ?\()(?<![A-Z] ?[A-Z])(?=[A-Z])/g,
    " "
  );

  // Capitalize the first letter of the string and each word
  string = string.replace(/\b\w/g, (l) => l.toUpperCase());

  // Handle uppercase letters enclosed in parentheses
  string = string.replace(/\([^)]+\)/g, (match) => match.toUpperCase());

  return string;
}

export function remove0(certificationCode) {
  return certificationCode.replace(/0/g, "");
}

export function base64toFile(base64, fileName, mimeType) {
  // Convertir la chaîne base64 en tableau de bytes
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // Créer un objet Blob
  const blob = new Blob([byteArray], { type: mimeType });

  // Créer un objet File
  const file = new File([blob], fileName, { type: mimeType });

  return file;
}

const mimeTypeMapping = {
  pdf: "application/pdf",
  txt: "text/plain",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  xls: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  png: "image/png",
  gif: "image/gif",
  css: "text/css",
  csv: "text/csv",
  odt: "application/vnd.oasis.opendocument.text",
  // Ajoutez d'autres extensions de fichier et types MIME au besoin
};

export function getMimeTypeFromFileName(fileName) {
  const extension = fileName.split(".").pop().toLowerCase();
  return mimeTypeMapping[extension] || "application/octet-stream"; // Type MIME par défaut pour les fichiers inconnus
}

export function extractCertificationCodeFromURL(url) {
  const emailParam = "email=";
  const index = url.indexOf(emailParam);

  if (index === -1) {
    return null;
  }

  const emailStartIndex = index + emailParam.length;
  const email = url.slice(emailStartIndex);

  return email;
}

export function getSumOf(array) {
  return array?.reduce((a, b) => a + b, 0);
}
