import { useDispatch } from "react-redux"
import { certificationActions } from "../../actions/certification.action";
import { Link } from "react-router-dom";

export default function DeleteFile({file}) {
    const dispatch = useDispatch();

    const handleRemoveFile = (attachementId) => {
		dispatch(certificationActions.removeFile(attachementId))
	}

    return (
        
        <Link to="#" className="block">
        <img className="w-6 h-6 mr-3" src="/assets/icons/icons8-close.svg" alt="upload" onClick={() => handleRemoveFile(file?.id)}/>    
        </Link>
    )
}