export const preTransactionInfo =
  "Carbon Offset Commodity certification’s issued before the transaction is completed. This may require carbon offset adjustment  after completion if significant material change in actual calculated carbon footprint as per COC certification protocol.";
export const cradleToGateInfo =
  "From extraction / production to place of delivery.";
export const cradleToGraveInfo =
  "From extraction / production to end-use (full life cycle).";
export const preTransactionInfoOtherService =
  "Carbon Offset Service certification issued before the voyage is completed. This may require carbon offset adjustment  after completion if significant material change in actual calculated carbon footprint as per COC certification protocol";
export const fieldTypeOfNumber = [
  "numberVoyagesToBeCertified",
  "numberOfTranscations",
  "estimatedOrActualActivity",
  "quantityOfFuelType",
];

export const fieldTypeOfDate = ["shippingDate", "estimatedFirstTransDate"];

export const initialFormData = {
  //CocUserId: user?.data.id,
  certificationType: 1,
  certificationStatus: 1,
  serviceName: "",
  estimatedCommodityVolumePerQuantity: "",
  destination: "",
  estimatedFirstTransDate: null,
  numberOfTranscations: "",
  origin: "",
  preTransactionCertification: null,
  postTransactionCertification: null,
  cradleToGate: null,
  cradleToGrave: null,
  transactionDescription: "",
  unit: "",
  //service
  shippingDate: null,
  isBallastVoyage: null,
  ballastVoyageDescription: "",
  vesselType: "",
  vesselName: "",
  typeOfFuel: "",
  fuelConsumed: "0",
  contractTimePeriod: "",
  estimatedOrActualActivity: "",
  serviceType: 1,
  freightDescription: "",
  arrival: "",
  airCraftType: "",
  //step 2 to 5 for every form
  ableToCalculateCF: null,
  hasCompanyContractedCalculation: null,
  nameOfServiceProvideCFC: "",
  contactAssuranceProvider: "",
  emailOfServiceProvider: "",
  requireServicesToCalculateCF: null,
  wantTobeIntroToVeritas: null,
  hasPreferenceForAnotherAssuranceProvider: null,
  anotherNameOfAssuranceProvider: "",
  anotherContactOfAssuranceProvider: "",
  anotherEmailOfAssuranceProvider: "",
  canCOCManagementContatctTheAssuranceProvider: null,
  needSupportToAccessVoluntaryCarbonMarkets: null,
};
