import React, { useEffect, useState } from "react";
import TextInput from "../../../components/forms/TextInput";
import TextArea from "../../../components/forms/TextArea";
import LabelInput from "../../../components/forms/Label";
import InfoPopup from "../../../components/create-certification/InfoPopup";
import { preTransactionInfoOtherService } from "../../../utils/constant";
import Error from "../../../components/Alert/Error";
import { fieldRequiredMessage } from "../../../errors/errors";
import TimePeriodInput from "../../../components/forms/TimePeriodInput";
import { formatDate } from "../../../utils/utils";
import DefaultRadioInput from "../../../components/forms/DefaultRadioInput";

function OtherServiceAdmin({
  formData,
  setFormData,
  errors,
  errorCount,
  register,
  setValue,
  reset,
  unregister,
}) {
  // useEffect(() => {
  //   reset();
  // }, [reset]);
  const [preTransactionRequired, setPreTransactionRequired] = useState(true);
  const [preTransactionChecked, setPreTransactionChecked] = useState();
  const [
    estimatedFirstTransDateRequired,
    setEstimatestimatedFirstTransDateRequired,
  ] = useState(true);
  const [contractPeriodRequired, setContractPeriodRequired] = useState(true);

  const handleChangeInput = (fieldName, value, fieldsToUnregister) => {
    setFormData({ ...formData, [fieldName]: value });
    setValue(fieldName, value);

    // Mettre à jour les champs à désenregistrer
    if (fieldsToUnregister) {
      // Désenregistrer les champs
      for (const field of fieldsToUnregister) {
        unregister(field);
      }
    }
    if (
      fieldName === "preTransactionCertification" ||
      fieldName === "postTransactionCertification"
    ) {
      if (fieldName === "postTransactionCertification") {
        setValue("postTransactionCertification", Boolean(value));
      }
      setPreTransactionChecked(value);
      setPreTransactionRequired(false);
      setFormData({
        ...formData,
        preTransactionCertification: Boolean(value),
      });
      setValue("preTransactionCertification", Boolean(value));
    }

    if (fieldName === "shippingDate") {
      if (value !== "") {
        setContractPeriodRequired(false);
      } else {
        setContractPeriodRequired(true);
      }
    }

    if (fieldName === "contractTimePeriod" && value !== "undefined") {
      setEstimatestimatedFirstTransDateRequired(false);
    }

    if (fieldName === "contractTimePeriod" && value === "undefined") {
      setEstimatestimatedFirstTransDateRequired(true);
    }
  };

  useEffect(() => {
    if (
      formData?.preTransactionCertification === true ||
      formData?.preTransactionCertification === false
    ) {
      setPreTransactionRequired(false);
      setPreTransactionChecked(formData?.preTransactionCertification);
    }
  }, [formData?.preTransactionCertification]);

  useEffect(() => {
    setValue("contractTimePeriod", formData?.contractTimePeriod || "");
    setValue("shippingDate", formData?.shippingDate || "");
    setValue(
      "preTransactionCertification",
      formData?.preTransactionCertification
    );
    setValue(
      "postTransactionCertification",
      formData?.postTransactionCertification
    );
    setValue("transactionDescription", formData?.transactionDescription || "");
    if (
      formData?.shippingDate &&
      formData?.shippingDate !== "1970-01-01T00:00:00Z" &&
      formData?.shippingDate !== ""
    ) {
      setContractPeriodRequired(false);
    }
    if (
      formData?.contractTimePeriod !== "1970-01-01 to 1970-01-01" &&
      formData?.contractTimePeriod !== "" &&
      formData?.contractTimePeriod !== "undefined"
    ) {
      setEstimatestimatedFirstTransDateRequired(false);
    }
  }, [formData, setValue]);

  return (
    <div className="w-full">
      <span className="text-coc-blue">
        Carbon offset Service certification can apply to any kind of service
        (e.g. logistics, events etc.)
      </span>
      <div className="col max-w-[390px] mt-4 pl-0">
        <TextArea
          onChange={(e) =>
            handleChangeInput("transactionDescription", e.target.value)
          }
          register={{
            ...register("transactionDescription", { required: true }),
          }}
          name="transactionDescription"
          defaultValue={formData?.transactionDescription}
          label="Service description"
          placeholder="Service description, additional information:"
        />
        {errors.transactionDescription &&
          errors.transactionDescription.type === "required" && (
            <Error
              key={errorCount}
              text={fieldRequiredMessage("Service Description")}
            />
          )}
      </div>
      <div className="flex mt-10">
        {(formData?.shippingDate !== "1970-01-01T00:00:00Z" ||
          (formData?.shippingDate === "1970-01-01T00:00:00Z" &&
            formData?.contractTimePeriod === "1970-01-01 to 1970-01-01")) && (
          <div className="col">
            <TextInput
              type="date"
              label="Date of service"
              defaultValue={
                formData?.shippingDate !== "1970-01-01T00:00:00Z"
                  ? formatDate(formData?.shippingDate)
                  : ""
              }
              placeholder="dd/mm/yy"
              onChange={(e) =>
                handleChangeInput("shippingDate", e.target.value)
              }
              register={{
                ...register("shippingDate", {
                  required: false,
                }),
              }}
              disabled={estimatedFirstTransDateRequired === false}
            />
            {errors.shippingDate && errors.shippingDate.type === "required" && (
              <Error
                key={errorCount}
                text={fieldRequiredMessage("Date of Service")}
              />
            )}
          </div>
        )}

        {formData?.contractTimePeriod !== "1970-01-01 to 1970-01-01" ||
          (formData?.contractTimePeriod === "1970-01-01 to 1970-01-01" &&
            formData?.shippingDate === "1970-01-01T00:00:00Z") ||
          (formData?.contractTimePeriod !== "undefined" && (
            <div className="col pr-0">
              <TimePeriodInput
                setTimePeriodValue={handleChangeInput}
                register={{
                  ...register("contractTimePeriod", {
                    required: false,
                  }),
                }}
                defaultValue={formData?.contractTimePeriod}
                fieldName="contractTimePeriod"
                label={`${
                  formData?.shippingDate === "1970-01-01T00:00:00Z" &&
                  formData?.contractTimePeriod === "1970-01-01 to 1970-01-01"
                    ? "Or"
                    : "Contract Time Period"
                }`}
                disabled={contractPeriodRequired === false}
              />
              {errors.contractTimePeriod &&
                errors.contractTimePeriod.type === "required" && (
                  <Error
                    key={errorCount}
                    text={fieldRequiredMessage("Date Period")}
                  />
                )}
            </div>
          ))}
      </div>

      <div className="flex flex-col mt-6">
        <div className="flex mb-3">
          <LabelInput label="Certification" />
        </div>
        <div className="flex mb-3 nospace">
          <DefaultRadioInput
            onChange={(e) =>
              handleChangeInput("preTransactionCertification", true)
            }
            register={{
              ...register("preTransactionCertification", {
                required: preTransactionRequired,
              }),
            }}
            defaultChecked={preTransactionChecked === true}
            value={true}
            name="preTransactionCertification"
            label="Pre-Transaction(s) Certification"
            id="pre"
            htmlFor="pre"
          />
          <InfoPopup text={preTransactionInfoOtherService} />
        </div>

        <div className="flex mb-3 nospace">
          <DefaultRadioInput
            onChange={(e) =>
              handleChangeInput("preTransactionCertification", false, [])
            }
            register={{
              ...register("postTransactionCertification", {
                required: preTransactionRequired,
              }),
            }}
            defaultChecked={preTransactionChecked === false}
            value={false}
            name="postTransactionCertification"
            label="Post-Transaction(s) Certification"
            id="post"
            htmlFor="post"
          />
        </div>
      </div>
      {errors.preTransactionCertification &&
        errors.preTransactionCertification.type === "required" &&
        errors.postTransactionCertification &&
        errors.postTransactionCertification.type === "required" && (
          <Error
            key={errorCount}
            text={fieldRequiredMessage("Transaction Certification")}
          />
        )}
    </div>
  );
}

export default OtherServiceAdmin;
