import React, { useEffect, useState } from "react";
import TextInput from "../../../components/forms/TextInput";
import RadioInput from "../../../components/forms/RadioInput";
import TextArea from "../../../components/forms/TextArea";
import LabelInput from "../../../components/forms/Label";
import InfoPopup from "../../../components/create-certification/InfoPopup";
import { preTransactionInfoOtherService } from "../../../utils/constant";
import Error from "../../../components/Alert/Error";
import { fieldRequiredMessage } from "../../../errors/errors";
import TimePeriodInput from "../../../components/forms/TimePeriodInput";
import { formatDate } from "../../../utils/utils";

function OtherService({
  formData,
  setFormData,
  errors,
  errorCount,
  register,
  setValue,
  reset,
  unregister,
  initDates,
}) {
  // useEffect(() => {
  //   reset();
  // }, [reset]);
  const [preTransactionRequired, setPreTransactionRequired] = useState(true);
  const [
    estimatedFirstTransDateRequired,
    setEstimatestimatedFirstTransDateRequired,
  ] = useState(true);
  const [contractPeriodRequired, setContractPeriodRequired] = useState(true);

  useEffect(() => {
    return () => {
      initDates("Other");
    };
  }, [initDates]);

  useEffect(() => {
    setValue("contractTimePeriod", formData?.contractTimePeriod || "");
    setValue("shippingDate", formData?.shippingDate || "");
    setValue("transactionDescription", formData?.transactionDescription || "");
    if (
      formData?.preTransactionCertification !== null ||
      formData?.postTransactionCertification !== null
    ) {
      setPreTransactionRequired(false);
    }
    if (formData?.shippingDate && formData?.shippingDate !== "1970-01-01") {
      setContractPeriodRequired(false);
    }
    if (
      formData?.contractTimePeriod !== "1970-01-01 to 1970-01-01" &&
      formData?.contractTimePeriod !== "" &&
      formData?.contractTimePeriod !== "undefined"
    ) {
      setEstimatestimatedFirstTransDateRequired(false);
    }
  }, [
    formData,
    setValue,
    contractPeriodRequired,
    estimatedFirstTransDateRequired,
  ]);

  const handleChangeInput = (fieldName, value, fieldsToUnregister) => {
    setFormData({ ...formData, [fieldName]: value });
    setValue(fieldName, value);

    // Mettre à jour les champs à désenregistrer
    if (fieldsToUnregister) {
      // Désenregistrer les champs
      for (const field of fieldsToUnregister) {
        unregister(field);
      }
    }
    if (
      fieldName === "preTransactionCertification" ||
      fieldName === "postTransactionCertification"
    ) {
      setPreTransactionRequired(false);
    }

    if (fieldName === "shippingDate") {
      setContractPeriodRequired(false);
    }

    if (fieldName === "contractTimePeriod" && value !== "undefined") {
      setEstimatestimatedFirstTransDateRequired(false);
    }

    if (fieldName === "contractTimePeriod" && value === "undefined") {
      setEstimatestimatedFirstTransDateRequired(true);
    }
  };

  return (
    <div className="w-full">
      <span className="text-coc-blue">
        Carbon offset Service certification can apply to any kind of service
        (e.g. logistics, events etc.)
      </span>
      <div className="col max-w-[390px] mt-4 pl-0">
        <TextArea
          onChange={(e) =>
            handleChangeInput("transactionDescription", e.target.value)
          }
          register={{
            ...register("transactionDescription", { required: true }),
          }}
          name="transactionDescription"
          defaultValue={formData?.transactionDescription}
          label="Service description"
          placeholder="Service description, additional information:"
        />
        {errors.transactionDescription &&
          errors.transactionDescription.type === "required" && (
            <Error
              key={errorCount}
              text={fieldRequiredMessage("Service Description")}
            />
          )}
      </div>
      <div className={`flex mt-10 `}>
        <div className={`col date pl-0`}>
          <TextInput
            type="date"
            label="Date of service"
            defaultValue={
              formData?.shippingDate && formatDate(formData?.shippingDate)
            }
            placeholder="dd/mm/yy"
            onChange={(e) => handleChangeInput("shippingDate", e.target.value)}
            register={{
              ...register("shippingDate", {
                required: false,
              }),
            }}
            disabled={estimatedFirstTransDateRequired === false}
          />
          {errors.shippingDate && errors.shippingDate.type === "required" && (
            <Error
              key={errorCount}
              text={fieldRequiredMessage("Date Of Service")}
            />
          )}
        </div>
        <div className="col pr-0 date relative -top-[6px]">
          <TimePeriodInput
            setTimePeriodValue={handleChangeInput}
            register={{
              ...register("contractTimePeriod", {
                required: false,
              }),
            }}
            defaultValue={formData?.contractTimePeriod}
            fieldName="contractTimePeriod"
            label="Or"
            disabled={contractPeriodRequired === false}
          />
          {errors.contractTimePeriod &&
            errors.contractTimePeriod.type === "required" && (
              <Error
                key={errorCount}
                text={fieldRequiredMessage("Date Period")}
              />
            )}
        </div>
      </div>

      <div className="flex flex-col mt-6">
        <div className="flex mb-3">
          <LabelInput label="Certification" />
        </div>
        <div className="flex mb-3">
          <RadioInput
            onChange={(e) =>
              handleChangeInput("preTransactionCertification", true)
            }
            register={{
              ...register("preTransactionCertification", {
                required: preTransactionRequired,
              }),
            }}
            checked={formData?.preTransactionCertification === true}
            value={true}
            name="preTransactionCertification"
            label="Pre-Transaction(s) Certification"
            id="pre"
            htmlFor="pre"
          />
          <InfoPopup text={preTransactionInfoOtherService} />
        </div>

        <div className="flex mb-3">
          <RadioInput
            onChange={(e) =>
              handleChangeInput("preTransactionCertification", false, [])
            }
            register={{
              ...register("postTransactionCertification", {
                required: preTransactionRequired,
              }),
            }}
            checked={formData?.preTransactionCertification === false}
            value={false}
            name="postTransactionCertification"
            label="Post-Transaction(s) Certification"
            id="post"
            htmlFor="post"
          />
        </div>
      </div>
      {(errors.preTransactionCertification &&
        errors.preTransactionCertification.type === "required") ||
        (errors.postTransactionCertification &&
          errors.postTransactionCertification.type === "required" && (
            <Error
              key={errorCount}
              text={fieldRequiredMessage("Transaction Certification")}
            />
          ))}
    </div>
  );
}

export default OtherService;
