import { certificationConstants } from '../constants/certification.constants';

export default function createCommodity(state = {}, action) {
    switch (action.type) {
        case certificationConstants.CREATE_COMMODITY:
            return {
                ...state,
                data: action.payload
            }
        case 'CLEAR_COMMODITY':
            return state
        case certificationConstants.CREATE_COMMODITY_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state
    }
}