export default function BlueButton({ children, ...props }) {
  return (
    <button
      onClick={props.Event}
      title={props.title}
      type="button"
      className="flex justify-center items-center bg-coc-blue w-auto px-3 py-3 hover:bg-coc-blue-hover focus:ring-1 focus:outline-none text-xs rounded-lg text-white"
    >
      {children} {props.text}
    </button>
  );
}
