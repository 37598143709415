import { React } from "react";
import { formatLabel } from "../../utils/utils";

const LabelInput = (props) => {
  const label = props.label;

  const className = `${
    props.className
      ? props.className
      : "bottom-[1px] relative text-sm font-semibold"
  }`;

  return (
    <label
      className={`${className} ${
        label === "Email-invisible" ? "invisible" : ""
      }`}
      title={label}
      htmlFor={props.for}
    >
      {formatLabel(label)}
      {props.children}
    </label>
  );
};

export default LabelInput;
