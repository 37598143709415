const creditRetirementFormDataReducer = (state = [], action) => {
  switch (action.type) {
    case "SET_RETIREMENT_DATA":
      return {
        ...state,
        formData: action.payload,
      };
    case "CLEAR_RETIREMENT_DATA":
      return {
        ...state,
        formData: [],
      };
    default:
      return state;
  }
};

export default creditRetirementFormDataReducer;
