import authHeader from "../utils/auth-header";

export const adminService = {
  updateUser,
  removeUser,
  createPartnerUser,
  updateCertification,
};

async function createPartnerUser(user) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: user,
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/users/register`,
    requestOptions
  );
  return handleResponse(response);
}

async function updateUser(userId, user) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader() },
    body: user,
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/users/${userId}`,
    requestOptions
  ).then(handleResponse);
}

async function removeUser(id) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader() },
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/users/${id}`,
    requestOptions
  ).then(handleResponse);
}

async function updateCertification(id, certification) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(certification),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/certificate/${id}`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
