import { forwardRef, useEffect, useRef, useState } from "react";
import LabelInput from "./Label";

export const PasswordInput = forwardRef((props, ref) => {
  const [showPassword, setShowPassword] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    if (ref) {
      ref.current = inputRef.current;
    }
  }, [ref, props.value]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <>
      <div className="input flex flex-col relative">
        <img
          onClick={togglePasswordVisibility}
          className="w-[20px] h-[20px] absolute right-[10px] top-[52%] hover:cursor-pointer"
          src={
            showPassword ? "/assets/icons/eye-off.svg" : "/assets/icons/eye.svg"
          }
          alt=""
        />

        <LabelInput label={props.label} />
        {/* <label className="text-[13px] text-[#232323] font-[600] capitalize" htmlFor={props.id}>{props.label}</label> */}
        {props.defaultValue ? (
          <>
            <input
              className="border-b-2 border-[#dedede] border-x-0 border-t-0 focus:ring-0 w-full focus:border-b-[#3BB34B] focus:outline-none focus:border-b-2 h-[50px] pr-14 pl-0"
              type={showPassword ? "text" : "password"}
              name={props.name}
              id={props.id}
              defaultValue={props.defaultValue}
              placeholder={
                props.placeholder ? props.placeholder : "Enter your password"
              }
              {...props.register}
              onChange={props.onChange}
              onKeyUp={props.onKeyUp}
              onKeyDown={handleKeyDown}
              ref={inputRef}
            />
          </>
        ) : (
          <>
            <input
              className="border-b-2 border-[#dedede] border-x-0 border-t-0 focus:ring-0 w-full focus:border-b-[#3BB34B] focus:outline-none focus:border-b-2 h-[50px] pr-14 pl-0"
              type={showPassword ? "text" : "password"}
              name={props.name}
              id={props.id}
              value={props.value}
              placeholder={
                props.placeholder ? props.placeholder : "Enter your password"
              }
              {...props.register}
              onChange={props.onChange}
              onKeyUp={props.onKeyUp}
              onKeyDown={handleKeyDown}
              ref={inputRef}
              disabled={props.disabled}
            />
          </>
        )}
      </div>
    </>
  );
});

export default PasswordInput;
