import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../actions/admin.actions";
import SuccessAlert from "../Alert/SuccessAlert";

const DeleteUserModal = ({ showConfirmationModal, userId, handleChange }) => {
  const dispatch = useDispatch();
  const userDeleted = useSelector((state) => state.admin.removed_user);

  const handleRemoveUser = (userId) => {
    dispatch(adminActions.removeUser(userId));
    handleChange(true);
  };

  return (
    <>
      <Modal
        isOpen={showConfirmationModal}
        className="popup-modal admin-users"
        overlayClassName="modall-overlay"
        shouldCloseOnOverlayClick={false}
      >
        <div className="p-4">
          <div className="flex">
            <span className="flex-shrink-0 inline-flex justify-center mr-4 items-center w-[46px] h-[46px] sm:w-[62px] sm:h-[62px] rounded-full border-4 border-red-50 bg-red-100 text-red-500 dark:bg-red-700 dark:border-red-600 dark:text-red-100">
              <svg
                className="w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
              </svg>
            </span>

            <div className="grow">
              <h3 className="mb-2 text-xl font-bold text-gray-800 dark:text-gray-200">
                Are you sure to delete this user?
              </h3>
              <p className="text-gray-500">
                The action can't redo and user will not be appear in search
                result!
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-end items-center">
          <button
            type="button"
            className="py-2.5 bg-gray-400 hover:bg-gray-300 px-4 inline-flex justify-center items-center text-white gap-2 rounded-md border font-medium shadow-sm align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm"
            onClick={() => handleChange(false)}
          >
            Cancel
          </button>
          <button
            className="py-2.5 px-4 ml-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-medium bg-red-600 text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
            onClick={() => handleRemoveUser(userId)}
          >
            Yes, delete this user
          </button>
        </div>
        {userDeleted && <SuccessAlert message={"User Deleted Successfully."} />}
      </Modal>
    </>
  );
};

export default DeleteUserModal;
