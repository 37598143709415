import React from "react";
import Lottie from "lottie-react";
import pending from "./pending.json";

const StepCompleted = () => {
  return (
    <div className="flex justify-center mx-auto mt-9 items-center">
      <div className="text-center">
        <p className="font-bold text-xl max-w-2/5 capitalize ml-32">
          Your application is going to be submitted to COC. Please wait few
          minutes.
        </p>
        <div className="mx-auto" style={{ width: "400px", height: "400px" }}>
          <Lottie loop={false} animationData={pending} />
        </div>
      </div>
    </div>
  );
};

export default StepCompleted;
