import Modal from "react-modal";
import Button from "../forms/Buttons/Button";

const CertificationFormModal = ({
  showConfirmationModal,
  handleConfirmationResponse,
  text,
}) => {
  const handleConfirmation = (keepChanges) => {
    handleConfirmationResponse(keepChanges);
  };

  return (
    <>
      <Modal
        isOpen={showConfirmationModal}
        onRequestClose={() => handleConfirmation(false)}
        className="popup-modal"
        overlayClassName="modall-overlay"
         shouldCloseOnOverlayClick={false}
      >
        <h2 className="text-center">
          {text ||
            "Are you sure you want to leave the form and lose filled data?"}
        </h2>
        <div className="flex justify-between mx-auto max-w-[300px] mt-4 w-[300px]">
          <Button
            onClick={() => handleConfirmationResponse(false)}
            text={`Yes`}
            backgroundColor={`coc-green`}
          />
          <Button
            onClick={() => handleConfirmationResponse(true)}
            text={`No`}
            backgroundColor={`coc-blue`}
          />
        </div>
      </Modal>
    </>
  );
};

export default CertificationFormModal;
