import { userConstants } from '../constants/user.constants.js';

const initialState = {
    message: ""
  }
export default function downloadCertification(state = initialState, action) {
    switch (action.type) {
        case userConstants.GET_DOWNLOAD_CERTIFICATION_REQUEST:
            return action.payload
        case userConstants.GET_DOWNLOAD_CERTIFICATION_SUCCESS:
            return {
                ...state,
                message: action.payload
            }
            
        case userConstants.GET_DOWNLOAD_CERTIFICATION_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state
    }
}