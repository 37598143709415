import { userConstants } from '../constants/user.constants.js';

const initialState = {
    data: null
  }
export default function rejectRetirementStep(state = initialState, action) {
    switch (action.type) {
        case userConstants.GET_REJECT_RETIREMENT_SUCCESS:
            return {
                ...state,
                data: action.payload
            }
            
        case userConstants.GET_REJECT_RETIREMENT_FAILURE:
            return {
                ...state,
                error: action.payload
            }
        case userConstants.REJECT_PROCESS_CLEAR:
            return state
        default:
            return state
    }
}