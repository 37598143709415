import React, { useState, useEffect, useMemo } from "react";
import CommodityForm from "./Commodity/CommodityForm";
import ServiceForm from "./Services/Services";
import ProductForm from "./Product/Product";
import RadioInput from "../../components/forms/RadioInput";
import { useDispatch, useSelector } from "react-redux";
import Lottie from "lottie-web";
import loading from "../Dashboard/loader-data.json";
import CertificationFormModal from "../../components/Modals/CertificationFormModal";

function CertificationForm() {
  const user = JSON.parse(localStorage.getItem("user"));
  const initialFormData = useMemo(() => {
    return {
      CocUserId: user?.data.id,
      certificationType: 1,
      certificationStatus: 1,
      serviceName: "",
      estimatedCommodityVolumePerQuantity: "",
      destination: "",
      estimatedFirstTransDate: null,
      numberOfTranscations: "",
      origin: "",
      preTransactionCertification: null,
      postTransactionCertification: null,
      cradleToGate: null,
      cradleToGrave: null,
      transactionDescription: "",
      //service
      shippingDate: null,
      isBallastVoyage: null,
      ballastVoyageDescription: "",
      vesselType: "",
      vesselName: "",
      typeOfFuel: "",
      fuelConsumed: "0",
      contractTimePeriod: "",
      estimatedOrActualActivity: "",
      serviceType: 1,
      freightDescription: "",
      arrival: "",
      airCraftType: "",
      //step 2 to 5 for every form
      ableToCalculateCF: null,
      hasCompanyContractedCalculation: null,
      nameOfServiceProvideCFC: "",
      contactAssuranceProvider: "",
      emailOfServiceProvider: "",
      requireServicesToCalculateCF: null,
      wantTobeIntroToVeritas: null,
      hasPreferenceForAnotherAssuranceProvider: null,
      anotherNameOfAssuranceProvider: "",
      anotherContactOfAssuranceProvider: "",
      anotherEmailOfAssuranceProvider: "",
      canCOCManagementContatctTheAssuranceProvider: null,
      needSupportToAccessVoluntaryCarbonMarkets: null,
    };
  }, [user?.data.id]);
  const [formType, setSelectedOption] = useState("Commodity");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [selectedOption, setSelectedTempOption] = useState();
  const addCertificate = useSelector((state) => state.createCommodity.data);
  const [loadingAddCertificate, setLoadingAddCertificate] = useState(false);
  const dispatch = useDispatch();

  const resetFormData = () => {
    setFormData(initialFormData);
  };

  const checkFormValuesModified = (formData, initialFormData) => {
    for (const [key, value] of Object.entries(formData)) {
      if (key !== "certificationType") {
        if (
          value !== "1970-01-01" &&
          value !== "1970-01-01 to 1970-01-01" &&
          value !== "1970-01-01T00:00:00.000Z" &&
          value !== initialFormData[key]
        ) {
          return true; // Au moins une valeur a été modifiée
        }
      }
    }
    return false; // Aucune valeur n'a été modifiée
  };

  const handleConfirmationResponse = (keepChanges) => {
    let selectedFormType = selectedOption;
    if (!keepChanges) {
      resetFormData();
      selectedFormType = selectedOption;
    } else {
      selectedFormType = formType;
    }
    setSelectedOption(selectedFormType);
    const option =
      selectedFormType === "Commodity"
        ? 1
        : selectedFormType === "Service"
        ? 3
        : 2;
    setFormData((prev) => {
      return {
        ...prev,
        certificationType: option,
      };
    });
    setShowConfirmationModal(false);
  };

  const handleFormTypeChange = (e) => {
    const isFormModified = checkFormValuesModified(formData, initialFormData);
    if (isFormModified) {
      setShowConfirmationModal(true);
      setSelectedTempOption(e.target.value);
    } else {
      resetFormData();
      setSelectedOption(e.target.value);
      let selectedFormType = e.target.value;
      const option =
        selectedFormType === "Commodity"
          ? 1
          : selectedFormType === "Service"
          ? 3
          : 2;
      setFormData((prev) => {
        return {
          ...prev,
          certificationType: option,
        };
      });
    }
  };

  useEffect(() => {
    dispatch({ type: "CLEAR_RETIREMENT_DATA" });
  }, [dispatch]);

  useEffect(() => {
    if (addCertificate) {
      setLoadingAddCertificate(false);
    }
  }, [addCertificate]);

  useEffect(() => {
    const isFormModified = checkFormValuesModified(formData, initialFormData);
    if (isFormModified) {
      dispatch({ type: "SET_FORM_DATA", payload: formData });
    }
  }, [dispatch, formData, initialFormData]);

  return (
    <div>
      <div className={`mx-12 commodity-container mb-12`}>
        <div className="checkItems flex">
          <div className="choice mb-4 flex">
            <RadioInput
              id="applicant"
              name="name"
              value="Commodity"
              checked={formType === "Commodity"}
              onChange={handleFormTypeChange}
            />
            <label className="relative" htmlFor="applicant">
              Commodity
            </label>
          </div>
          <div className="choice mb-4 flex ml-4">
            <RadioInput
              id="product"
              name="name"
              value="Product"
              checked={formType === "Product"}
              onChange={handleFormTypeChange}
            />
            <label className="relative" htmlFor="product">
              Product
            </label>
          </div>
          <div className="choice mb-4 flex ml-4">
            <RadioInput
              id="service"
              name="name"
              type="radio"
              value="Service"
              checked={formType === "Service"}
              onChange={handleFormTypeChange}
            />
            <label className="relative" htmlFor="service">
              Service
            </label>
          </div>
        </div>
      </div>
      {formType === "Commodity" && (
        <CommodityForm
          certificationType={1}
          userId={user?.data.id}
          formData={formData}
          setFormData={setFormData}
          //setCreateLoading={setLoadingAddCertificate}
        />
      )}
      {formType === "Service" && (
        <ServiceForm
          certificationType={3}
          userId={user?.data.id}
          formData={formData}
          setFormData={setFormData}
        />
      )}
      {formType === "Product" && (
        <ProductForm
          certificationType={2}
          userId={user?.data.id}
          formData={formData}
          setFormData={setFormData}
        />
      )}
      <CertificationFormModal
        showConfirmationModal={showConfirmationModal}
        handleConfirmationResponse={handleConfirmationResponse}
      />
      {loadingAddCertificate && (
        <div className="upload-overlay">
          <Lottie
            animationData={loading}
            style={{ width: "250px", height: "250px" }}
          />
        </div>
      )}
    </div>
  );
}

export default CertificationForm;
