import { useDispatch, useSelector } from "react-redux";
import "./Header.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import CertificationFormModal from "../Modals/CertificationFormModal";
import Details from "../Modals/UserDetails";
import { userService } from "../../services/user.service";
import LogOutConfirmationModal from "../Modals/LogOutConfirmationModal";

function HeaderConnected({ isAuth }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const [showLogOutButton, setShowLogOutButton] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [routeSelected, setRouteSelected] = useState();
  const [initials, setInitials] = useState();
  const [showEditModal, setShowEditModal] = useState(false);
  const [userDetail, setUserDetail] = useState(null);
  const [showLogOutModal, setShowLogOutModal] = useState(false);
  const formDataChanged = useSelector(
    (state) => state.certificationFormDataReducer.formData
  );

  const userUpdated = useSelector((state) => state.admin.updated_user);

  const dropdownRef = useRef(null);

  const handleClickDetail = (userId) => {
    if (!userDetail) {
      userService
        .getById(userId)
        .then((response) => setUserDetail(response.data));
    }

    //setUserId(userId);
    setShowEditModal(true);
  };

  useEffect(() => {
    if (user?.data.id || (userUpdated && userUpdated.id === user?.data.id)) {
      userService
        .getById(user?.data.id)
        .then((response) => setUserDetail(response.data));
    }
  }, [user?.data.id, userUpdated]);

  const generateInitials = useCallback(() => {
    if (user?.data.firstName && user?.data.lastName) {
      setInitials(
        `${user?.data.firstName.charAt(0)}${user?.data.lastName.charAt(0)}`
      );
    } else setInitials(`${user?.data.email.charAt(0)}`);
  }, [user]);

  // Get the current location object using useLocation hook
  const location = useLocation();

  // Custom function to check if the current route is either "/" or "/dashboard"
  const isRouteActive = (route) => {
    return route === "/dashboard"
      ? location.pathname === route || location.pathname === "/"
      : location.pathname === route;
  };

  useEffect(() => {
    if (!isAuth) {
      navigate("/");
    } else {
      generateInitials();
    }
  }, [isAuth, navigate, generateInitials]);

  const handleLogOut = () => {
    setShowLogOutModal(true);
  };

  const handleConfirmationResponse = (keepChanges) => {
    if (!keepChanges) {
      dispatch({ type: "CLEAR_FORM_DATA" });
      navigate(routeSelected);
    }
    setShowConfirmationModal(false);
  };

  const onChangeRoute = (route) => {
    if (route !== "/certification" && formDataChanged) {
      setRouteSelected(route);
      setShowConfirmationModal(true);
    } else {
      navigate(route);
    }
  };

  const toggleDropdown = () => {
    if (showLogOutButton) {
      setShowLogOutButton(false);
    } else {
      setShowLogOutButton(true);
    }
  };

  const handleMouseEnter = () => {
    setShowLogOutButton(true);
  };

  const handleMouseLeave = () => {
    setShowLogOutButton(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowLogOutButton(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <header className="z-20">
        <span className="logo">
          <a href={isAuth ? "/dashboard" : "/"}>
            <img src="/assets/images/logo-white.png" alt="" />
          </a>
        </span>
        <ul className="menu">
          <li>
            {formDataChanged ? (
              <NavLink
                className={({ isActive }) =>
                  isRouteActive("/protocol") ? "active" : "none"
                }
                onClick={() => onChangeRoute("/protocol")}
              >
                COC Protocol
              </NavLink>
            ) : (
              <NavLink
                className={({ isActive }) =>
                  isRouteActive("/protocol") ? "active" : "none"
                }
                to="/protocol"
              >
                COC Protocol
              </NavLink>
            )}
          </li>
          {user?.data.userRole === "Client" && (
            <li>
              <NavLink
                className={({ isActive }) => (isActive ? "active" : "none")}
                to="/certification"
              >
                Application form
              </NavLink>
            </li>
          )}
          <li>
            {formDataChanged ? (
              <NavLink
                className={({ isActive }) =>
                  isRouteActive("/dashboard") ? "active" : "none"
                }
                onClick={() => onChangeRoute("/dashboard")}
              >
                Dashboard
              </NavLink>
            ) : (
              <NavLink
                className={({ isActive }) =>
                  isRouteActive("/dashboard") ? "active" : "none"
                }
                to="/dashboard"
              >
                Dashboard
              </NavLink>
            )}
          </li>
          <li>
            {formDataChanged ? (
              <NavLink
                className={({ isActive }) =>
                  isRouteActive("/partners") ? "active" : "none"
                }
                onClick={() => onChangeRoute("/partners")}
              >
                Partners
              </NavLink>
            ) : (
              <NavLink
                className={({ isActive }) =>
                  isRouteActive("/partners") ? "active" : "none"
                }
                to="/partners"
              >
                Partners
              </NavLink>
            )}
          </li>
          <div className="connected flex items-center">
            <div className="user-connected">
              {userDetail?.companyLogo ? (
                <img
                  src={`data:image/jpeg;base64,${userDetail?.companyLogo}`}
                  alt="company logo"
                  className="w-[33px] h-[33px] object-cover rounded-full"
                />
              ) : (
                initials
              )}
            </div>
            <div className="dropdown relative ml-2">
              <img
                className="cursor-pointer"
                src="/assets/icons/dropdown.svg"
                alt=""
                onClick={toggleDropdown}
              />
              {showLogOutButton && (
                <div
                  className="absolute right-0 mt-2 top-11 shadow-lg rounded-md "
                  ref={dropdownRef}
                  onMouseLeave={handleMouseLeave}
                  onMouseEnter={handleMouseEnter}
                >
                  <button
                    className="block flex items-center px-4 py-2 text-gray-800 hover:bg-[#DEDEDE] bg-[#FFFF] logoutButton"
                    onClick={() => handleClickDetail(user?.data.id)}
                  >
                    Profile
                    <img
                      className="ml-2 w-5 h-5"
                      src="/assets/icons/profile-circle.svg"
                      alt=""
                    />
                  </button>
                  <button
                    className="block flex items-center px-4 py-2 text-gray-800 hover:bg-[#DEDEDE] bg-[#FFFF] logoutButton"
                    onClick={handleLogOut}
                  >
                    Logout
                    <img
                      className="ml-2 w-4 h-4"
                      src="/assets/icons/logout.svg"
                      alt=""
                    />
                  </button>
                </div>
              )}
            </div>
          </div>
        </ul>
      </header>
      <CertificationFormModal
        showConfirmationModal={showConfirmationModal}
        handleConfirmationResponse={handleConfirmationResponse}
      />
      <Details
        user={userDetail}
        userId={user?.data.id}
        showConfirmationModal={showEditModal}
        setShowConfirmationModal={setShowEditModal}
      />
      <LogOutConfirmationModal
        showModal={showLogOutModal}
        handleChange={setShowLogOutModal}
      />
    </>
  );
}

export default HeaderConnected;
