import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { certificationActions } from "../../../actions/certification.action";
import UploadFiles from "../../../components/certification-process/UploadFiles";
import Lottie from "lottie-react";
import checkedAnimation from "../../DemandForm/checked.json";
import UserActionButtons from "../../../components/certification-process/UserActionButtons";
import Error from "../../../components/Alert/Error";
import TextInput from "../../../components/forms/TextInput";

export default function FootPrintVerification({ certificationId }) {
  const [files, setFiles] = useState([]);
  const certificationSteps = useSelector(
    (state) => state.certificationStep.arrayCertification
  );
  const certificationAllData = useSelector(
    (state) => state.certificationStep.allData
  );
  const [certificationStep, setCertificationStep] = useState();
  const [showError, setShowError] = useState(false);
  const [totalCredit, setTotalCredit] = useState();
  const dispatch = useDispatch();
  const isFileRemoved = useSelector((state) => state.removeFile.removeSuccess);
  const isFileUploaded = useSelector((state) => state.uploadCertification.file);
  const validateStep = useSelector((state) => state.validateStep.data);
  const rejectStep = useSelector((state) => state.rejectStep.data);
  const sentToCocFiles = useSelector((state) => state.sendCocFiles.data);
  const user = JSON.parse(localStorage.getItem("user"));
  const currentStep = 2;
  useEffect(() => {
    if (
      sentToCocFiles?.certificationId !== null &&
      sentToCocFiles?.certificationId === certificationId
    ) {
      dispatch(certificationActions.getCertificationStepByCID(certificationId));
    }
  }, [sentToCocFiles, certificationId, dispatch]);

  useEffect(() => {
    if (isFileRemoved || isFileUploaded || validateStep || rejectStep) {
      dispatch(certificationActions.getCertificationStepByCID(certificationId));
    }
  }, [
    isFileRemoved,
    dispatch,
    certificationId,
    isFileUploaded,
    validateStep,
    rejectStep,
  ]);

  useEffect(() => {
    if (certificationSteps.length > 0) {
      const footprintVerificationStep = certificationSteps.filter((certif) => {
        return certif.processStep === 2;
      });
      if (footprintVerificationStep.length > 0) {
        setCertificationStep(footprintVerificationStep[0]);
        setFiles(footprintVerificationStep[0].attachments);
      } else {
        setCertificationStep(certificationSteps[0]);
      }
    }
  }, [certificationSteps]);

  useEffect(() => {
    if (certificationSteps.length > 0) {
      const footprintCalculationStep = certificationSteps.filter(
        (certif) => certif.processStep === currentStep
      );
      if (footprintCalculationStep.length > 0) {
        setCertificationStep(footprintCalculationStep[0]);
      } else {
        setCertificationStep(certificationSteps[0]);
      }
    }
  }, [
    certificationSteps,
    currentStep,
    user?.data.userRole,
    certificationStep?.isSentToCOC,
  ]);

  const handleSetTransactionQuantity = (e) => {
    setTotalCredit(e.target.value);
    setShowError(false);
  };

  return (
    <div className="shadow-lg rounded-2xl mt-4">
      <h1 className="uppercase font-semibold text-lg pt-4 text-center">
        Carbon Footprint verification
      </h1>
      {
        <div className="w-2/6 mx-auto">
          {/*<TextInput title="Total Carbon" onChange={(e)=>setTotalCredit(e.target.value)}/> tCO2*/}
          <div className="flex">
            <TextInput
              isNumberType={true}
              placeholder="Transaction quantity"
              onChange={handleSetTransactionQuantity}
              className="pl-0 w-full bg-white mb-2 border-x-0 border-t-0 border-b-2 focus:outline-none focus:ring-0"
              type="text"
              name=""
              id=""
              defaultValue={
                certificationAllData?.totalCO2Footprint ||
                certificationAllData?.carbonCo2Value
              }
            />
            <span className="mt-4">T/CO2e</span>
          </div>

          {showError && <Error text="This field is required." />}
        </div>
      }

      <div className="flex items-start justify-between pb-4">
        <div className="w-1/3 mx-4 mt-4">
          <p>
            Take the next step to certification. Upload carbon footprint
            verification report or wait for VVB verification.
          </p>
        </div>
        <div className="mx-auto w-1/3 mt-4 relative">
          <UploadFiles
            title={`VVB/Applicant`}
            filesUploaded={files}
            certificationId={certificationId}
            currentStep={2}
            roles={"Applicant"}
          />
        </div>

        <div className="mx-3 w-1/3 mt-4 relative">
          <UploadFiles
            title={`COC`}
            filesUploaded={files}
            certificationId={certificationId}
            currentStep={2}
            roles={"COC"}
          />
        </div>

        <div className="flex flex-col items-center justify-center mr-4 w-1/3">
          <div
            className="flex flex-col items-center justify-center"
            hidden={
              !certificationStep?.isValidated ||
              certificationStep?.processStep !== 2
            }
          >
            <Lottie
              /*loop={1}*/ style={{ width: "150px", height: "150px" }}
              animationData={checkedAnimation}
            />
          </div>

          <div
            className="flex flex-col items-center justify-center"
            hidden={
              !(
                (certificationStep?.isSentToCOC &&
                  !certificationStep?.isValidated &&
                  user?.data?.userRole !== "COC") ||
                (user?.data.userRole === "COC" &&
                  !certificationStep?.isSentToCOC &&
                  !certificationStep?.isValidated)
              )
            }
          >
            {user?.data.userRole !== "COC" && (
              <>
                <div className="py-4">
                  <img
                    width={`150px`}
                    height={`150px`}
                    src="/assets/images/clock-pending-time.svg"
                    alt="pending"
                  />
                </div>

                <p className="text-[#f2af29] font-medium text-center">
                  Your carbon footprint verification has been communicated to
                  COC.
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      <div>
        <UserActionButtons
          certificationId={certificationId}
          currentStep={currentStep}
          setShowError={setShowError}
          totalCredit={totalCredit || certificationAllData?.carbonCo2Value}
          buttonText={`Send To COC`}
        />
      </div>
    </div>
  );
}
