export const CertificationType = {
    1: 'Commodity',
    2: 'Product',
    3: 'Service'
}

export const CertificationStatus = {
    1: 'Waiting For COC Approval',
    2: 'Refused',
    3: 'Pending',
    4: 'Completed'
}

export const CertificationStepStatus = {
    1: 'Carbon Footprint Calculation',
    2: 'Carbon Footprint Verification',
    3: 'Carbon Credits Retirement',
    4: 'COC Certification'
}

export const CertificationStatusENUM = {
    WAITING_FOR_COC_APPROUVAL : 1,
    REFUSED_BY_COC : 2,
    PENDING : 3,
    COMPLETED: 4
}

export const getCertificationTypeStringValue = (intValue) => {
    switch(intValue) {
        case 1:
            return CertificationType[1]
        case 2:
            return CertificationType[2]
        case 3:
            return CertificationType[3]
        default:
            return;
    }
}

export const getCertificationStepStatusStringValue = (intValue) => {
    switch(intValue) {
        case 1:
            return CertificationStepStatus[1]
        case 2:
            return CertificationStepStatus[2]
        case 3:
            return CertificationStepStatus[3]
        case 4:
            return CertificationStepStatus[4]
        default:
            return;
    }
}


export const getCertificationStatusStringValue = (intValue) => {
    switch(intValue) {
        case 1:
            return CertificationStatus[1]
        case 2:
            return CertificationStatus[2]
        case 3:
            return CertificationStatus[3]
        case 4:
            return CertificationStatus[4]
        default:
            return;
    }

}

export const routesNotConnected = [
    '/',
    '/create',
    '/service',
    '/client',
    '/step',
    '/modal',
    '/form',
    '/serviceform',
    '/productform',
    '/table'
]

export const getServiceTypeValue = (stringValue) => {
    switch(stringValue) {
        case 'Other':
            return 1
        case 'Voyage':
            return 2
        case 'Time':
            return 3
        case 'Flight':
            return 4
        case 'TimeFlight':
            return 5
        default:
            return;
    }

}