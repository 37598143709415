import { useCallback, useEffect, useRef, useState } from "react";
import LabelInput from "./Label";

export default function TextArea({
  cols = 30,
  rows = 2,
  maxRows = 8,
  ...props
}) {
  const textareaRef = useRef(null);
  const [naChecked, setNaChecked] = useState(false);

  const [inputValue, setInputValue] = useState(
    props.defaultValue || props.value || ""
  );

  useEffect(() => {
    if (props.defaultValue) {
      setInputValue(props.defaultValue);
    }
    if (props.value) {
      setInputValue(props.value);
    }
  }, [props.defaultValue, props.value]);

  const handleInput = useCallback(() => {
    const textarea = textareaRef.current;

    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;

    if (maxRows) {
      const lineHeight = parseInt(window.getComputedStyle(textarea).lineHeight);
      const maxHeight = lineHeight * maxRows;

      if (textarea.scrollHeight > maxHeight) {
        textarea.style.overflowY = "scroll";
        textarea.style.height = `${maxHeight}px`;
      } else {
        textarea.style.overflowY = "hidden";
      }
    }
  }, [maxRows]);

  useEffect(() => {
    handleInput();
  }, [handleInput]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
    if (props.onChange) {
      props.onChange(e);
    }
  };

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    const value = checked ? "N/A" : "";
    setNaChecked(checked);
    props.onChange({ target: { value } });
  };

  return (
    <div className="relative">
      <LabelInput label={props.label} />
      <textarea
        className={`mt-3 border-b-2 border-[#dedede] border-x-0 border-t-0 focus:ring-0 w-full focus:border-b-[#3BB34B] focus:outline-none focus:border-b-2 ${
          naChecked ? "pr-16" : ""
        }`}
        name={props.name}
        id={props.id}
        placeholder={props.placeholder}
        cols={cols}
        rows={rows}
        ref={textareaRef}
        onChange={handleChange}
        maxLength={props.maxLength}
        onInput={handleInput}
        autoComplete="nope"
        value={inputValue}
        tabIndex={props.tabIndex}
      />
      {props.allowNA && (
        <label
          className={`absolute top-1/2 right-3 transform -translate-y-1/2 ${
            naChecked ? "text-[#3BB34B]" : "text-gray-500"
          } cursor-pointer`}
        >
          <input
            type="checkbox"
            checked={naChecked}
            onChange={handleCheckboxChange}
            className="hidden"
          />
          N/A
        </label>
      )}
    </div>
  );
}
