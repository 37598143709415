import { useEffect, useState } from "react";

export default function DefaultRadioInput(props) {
  const [isChecked, setChecked] = useState(false);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange(e);
    }
  };

  useEffect(() => {
    setChecked(
      props.checked === true || props.defaultChecked === true ? true : false
    );
  }, [props.checked, props.defaultChecked]);

  return (
    <div className="choice mb-5 flex items-center">
      <input
        className="float-left text-[#3BB34B] focus:ring-[#3BB34B] focus:border-[#3BB34B] hover:cursor-pointer mr-2"
        id={props.id}
        name={props.name}
        type="radio"
        {...props.register}
        value={props.value}
        onChange={handleChange}
        checked={isChecked}
        onKeyDown={handleKeyDown}
        tabIndex={0}
      />
      <label
        className={
          isChecked
            ? "font-semibold relative text-sm focus:text-coc-blue"
            : "relative text-sm"
        }
        htmlFor={props.htmlFor}
      >
        {props.label}
      </label>
    </div>
  );
}
