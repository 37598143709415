import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userConstants } from "../../constants/user.constants";
import { NextButton } from "../../components/forms/Buttons/NextButton";

export default function CreateAccount() {
  const [choice, setChoice] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNext = () => {
    if (choice === "partner") navigate("/service");
    if (choice === "applicant") navigate("/client");
  };

  function request() {
    return { type: userConstants.REGISTER_REQUEST };
  }

  useEffect(() => {
    dispatch(request());
  }, [dispatch]);

  return (
    <div>
      <h1 className="font-bold text-3xl mt-9 text-center">Create an account</h1>

      <div className="choice shadow-2xl mx-8 px-9 py-9 mt-6 rounded-md">
        <h2 className="font-bold text-lg mb-9">Company Profile</h2>
        <div className="checkItems">
          <div className="choice mb-4">
            <input
              className="float-left text-coc-green focus:ring-0 hover:cursor-pointer"
              id="applicant"
              name="name"
              type="radio"
              onChange={() => setChoice("applicant")}
            />
            <label
              className="bottom-[3px] relative left-[10px] font-semibold"
              htmlFor="applicant"
            >
              Applicant for certification
            </label>
          </div>
          <div className="choice mb-4 flex">
            <input
              className="float-left text-coc-green focus:ring-0 hover:cursor-pointer"
              id="service"
              name="name"
              type="radio"
              onChange={() => setChoice("partner")}
            />
            <label
              className="bottom-[3px] relative left-[10px] font-semibold"
              htmlFor="service"
            >
              Partner - Service Provider <br />
              <span className="text-[#989898] font-normal">
                (MRV, Verification Body, Carbon Market Access Provider)
              </span>
            </label>
          </div>
        </div>

        <div className="text-right">
          <NextButton
            text={`next`}
            disabled={choice ? false : true}
            onClick={handleNext}
          />
        </div>
      </div>
    </div>
  );
}
